import React, { useEffect, useState } from 'react'
import '../App.scss'
import './Pages.scss'
import Layout, { Content } from 'antd/lib/layout/layout'
import SideBar from '../components/SideBar/SideBar'
import { Button, Table, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getClassTeamsRequest } from '../redux/trainer/trainer.actions'

const TeamClasses = ({ getClassTeamsRequest, teams, activeUserTrainer, statusUser, updatedTeamId }) => {

    const idClass = window.location.pathname.split("/")[3]

    useEffect(() => {
        getClassTeamsRequest(idClass)
    }, [idClass, activeUserTrainer, updatedTeamId, statusUser])

    // random color for avatars
    const randomColor = () => {
        let hex = Math.floor(Math.random() * 0xFFFFFF)
        let color = "#" + hex.toString(16)
        return color
    }

    // table
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Total",
            dataIndex: "total"
        },
        {
            title: "Players",
            dataIndex: "players"
        }
    ];

    const [dataState, setDataState] = useState(null)

    useEffect(() => {
        var data = []
        if (teams) {
            for (var i = 0; i < teams.teams.length; i++) {
                var color = randomColor()
                data.push({
                    "id": teams.teams[i].id,
                    "name": teams.teams[i].username,
                    "total": teams.teams[i].number_active + "/" + teams.teams[i].from_total,
                    "players": teams.teams[i].personas.map((p) => {
                        return <Button className={p.is_active ? "player_btn" : "player_btn_inactive"} icon={<Avatar style={{ backgroundColor: color }} size="small" icon={<UserOutlined />} />}><span className="avatar_name">{p.name}</span></Button>
                    })
                })
            }
        }
        setDataState(data)

    }, [activeUserTrainer, updatedTeamId, statusUser, teams])

    if (teams)
        return (
            <Layout>
                <SideBar />
                <Content>
                    <div className="general_container team_classes">
                        <div className="waiting_msg_div">
                            <label className="waiting_msg">Waiting for all the players to join...</label>
                        </div>
                        <h1 className="team_title">Teams</h1>
                        <Table columns={columns} dataSource={dataState} pagination={false} scroll={{ x: 'fit-content' }} />
                    </div>
                </Content>
            </Layout>

        )
    return null
}


const mapStateToProps = state => ({
    teams: state.trainer.teams,
    activeUserTrainer: state.trainer.activeUserTrainer,
    updatedTeamId: state.trainer.updatedTeamId,
    statusUser: state.trainer.statusUser
})

const mapDispatchToProps = dispatch => ({
    getClassTeamsRequest: payload => dispatch(getClassTeamsRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamClasses)