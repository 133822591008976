import React, { useEffect } from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import '../../App.scss'
import './FinalStage.scss'
import { connect } from 'react-redux'
import { getSharpeRatioCompareChartRequest } from '../../redux/trainer/trainer.actions'

const SharpeRatioCompare = ({ getSharpeRatioCompareChartRequest, responseGetSharpeRatioCompareChart }) => {

    const idGame = localStorage.getItem("game_id")
    useEffect(() => {
        getSharpeRatioCompareChartRequest(idGame)
    }, [idGame])

    var finalData = []
    if (responseGetSharpeRatioCompareChart && responseGetSharpeRatioCompareChart.datasets.length > 0)
        responseGetSharpeRatioCompareChart.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible)
                }
            )
            return finalData
        })

    var state
    if (responseGetSharpeRatioCompareChart)
        state = {
            labels: responseGetSharpeRatioCompareChart.labels,
            datasets: finalData
        }

    const options = {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                barPercentage: 0.4,
                ticks: {
                    fontSize: 8
                }
            }],
            xAxes: [{
                ticks: {
                    fontSize: 11
                }
            }]
        },
        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItems, data) {
                    return tooltipItems.xLabel.toFixed(2);
                }
            }
        }
    }

    return (
        <div className="general_container stage_container">
            <h4 className="subtitle-stage">Sharpe Ratio (Compare)</h4>
            <HorizontalBar data={state} options={options} />
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetSharpeRatioCompareChart: state.trainer.responseGetSharpeRatioCompareChart
})

const mapDispatchToProps = dispatch => ({
    getSharpeRatioCompareChartRequest: payload => dispatch(getSharpeRatioCompareChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SharpeRatioCompare)