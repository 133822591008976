import React from 'react'
import './Arrow.scss'

const Arrow = ({ dataPerformance }) => {
    return (
        <div className={dataPerformance >= 0 ? "arrow_box_green" : "arrow_box_red"}>
            {dataPerformance >= 0 ? "+" + dataPerformance + "%" : dataPerformance + "%"}
        </div>
    )
}

export default Arrow