import React, { useEffect } from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import '../../App.scss'
import './ScenarioStages.scss'
import { connect } from 'react-redux'
import { getPortfolioCompareChartRequest } from '../../redux/trainer/trainer.actions'

const PortfoliosCompare = ({ getPortfolioCompareChartRequest, responseGetPortfolioCompareChart, periodNumber }) => {

    const idGame = localStorage.getItem("game_id")

    useEffect(() => {
        if (periodNumber)
            getPortfolioCompareChartRequest({ sessionId: idGame, periodNumber: periodNumber })
    }, [periodNumber, idGame])

    var finalData = []
    if (responseGetPortfolioCompareChart && responseGetPortfolioCompareChart.datasets.length > 0)
        responseGetPortfolioCompareChart.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible)
                }
            )
            return finalData
        })

    var state
    if (responseGetPortfolioCompareChart)
        state = {
            labels: responseGetPortfolioCompareChart.labels,
            datasets: finalData
        }

    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 11,
                boxWidth: 20,
                boxHeight: 2
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontSize: 11,
                    callback: function (value) {
                        return value + "%"
                    }
                }
            }],
            yAxes: [{
                ticks: {
                    fontSize: 11
                }
            }]
        },
        tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
                label: function (tooltipItems, data) {
                    return tooltipItems.xLabel + "%";
                }
            }
        }
    }

    return (
        <div className="general_container stage_container">
            <h4 className="subtitle-stage">Portfolio Compare</h4>
            <HorizontalBar data={state} options={options} />
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetPortfolioCompareChart: state.trainer.responseGetPortfolioCompareChart
})

const mapDispatchToProps = dispatch => ({
    getPortfolioCompareChartRequest: payload => dispatch(getPortfolioCompareChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PortfoliosCompare)