import React from 'react'
import './LoginForm.scss'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import graph from '../../images/graph.png'
import { connect } from 'react-redux'
import { loginRequest } from '../../redux/account/account.actions'
import Spinner from '../Spinner/Spinner'
import CustomUsersModal from '../CustomUsersModal/CustomUsersModal'

const LoginForm = ({ loginRequest, error, isLoadingLogin, username, isOpen }) => {

    const onFinish = (values) => {
        loginRequest({
            username: values.username,
            password: values.password
        })
    };

    return (
        <div className="login_form_container">
            {
                username ? <CustomUsersModal visible={isOpen} /> : <></>
            }
            <div className="left_div">
                <img className="graph_icon" src={graph} alt="graph-icon"></img>
                <h1>Welcome back</h1>
            </div>
            <div className="form_container">
                <h1>Login</h1>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    {
                        error ? <label className="warning_message">{error}</label> : <label className="transparent_message">label</label>
                    }
                    <Form.Item className="button_style">
                        <Button disabled={isLoadingLogin ? true : false} type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                    <div className="spinner_div">
                        {
                            isLoadingLogin ? <Spinner /> : <></>
                        }
                    </div>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    error: state.account.error,
    isLoadingLogin: state.account.isLoadingLogin,
    username: state.account.username,
    isOpen: state.account.isOpen
})

const mapDispatchToProps = dispatch => ({
    loginRequest: payload => dispatch(loginRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
