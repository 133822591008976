import { takeLatest, put, all, call } from 'redux-saga/effects'
import apiService from '../../service/apiService'
import { GET_BENCHMARK_PORTFOLIO_FAILURE } from '../trainer/trainer.constants'
import { GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST, GET_SHARPE_RATIO_COMPUTE_CHART_SUCCESS, GET_TEAM_BENCHMARK_ALLOCATION_REQUEST, GET_TEAM_BENCHMARK_ALLOCATION_SUCCESS, GET_TEAM_CLASSES_REQUEST, GET_TEAM_CLASSES_SUCCESS, GET_TEAM_GAMES_REQUEST, GET_TEAM_GAMES_SUCCESS, SAVE_BENCHMARK_FAILURE, SAVE_BENCHMARK_REQUEST, SAVE_BENCHMARK_SUCCESS, SAVE_INDIVIDUAL_BENCHMARK_FAILURE, SAVE_INDIVIDUAL_BENCHMARK_REQUEST, SAVE_INDIVIDUAL_BENCHMARK_SUCCESS } from './team.constants'

// GET TEAM'S GAMES
export function* getTeamGames({ payload }) {
    const { paramList } = payload
    const { data: { count, next, previous, results } } = yield call(apiService.getTeamGames, paramList)
    yield put({
        type: GET_TEAM_GAMES_SUCCESS,
        payload: { count, next, previous, results }
    })
}

export function* getTeamGamesWatcher() {
    yield takeLatest(GET_TEAM_GAMES_REQUEST, getTeamGames)
}

// GET BENCHMARK
export function* getTeamBenchmarkAllocation({ payload }) {
    const sessionId = payload
    try {
        const { data } = yield call(apiService.getTeamBenchmarkAllocation, sessionId)
        yield put({
            type: GET_TEAM_BENCHMARK_ALLOCATION_SUCCESS,
            payload: data
        })
    } catch (err) {
        yield put({
            type: GET_BENCHMARK_PORTFOLIO_FAILURE,
            payload: err
        })
    }
}

export function* getTeamBenchmarkAllocationWatcher() {
    yield takeLatest(GET_TEAM_BENCHMARK_ALLOCATION_REQUEST, getTeamBenchmarkAllocation)
}

// SAVE INDIVIDUAL BENCHMARK
export function* saveIndividualBenchmark({ payload }) {
    const { sessionId, body } = payload
    try {
        const response = yield call(apiService.saveIndividualBenchmark, sessionId, body)
        if (response.status === 200)
            yield put({
                type: SAVE_INDIVIDUAL_BENCHMARK_SUCCESS,
                payload: "Individual benchmark was successfully saved!"
            })
    } catch (err) {
        var message = err.response.data.error
        yield put({
            type: SAVE_INDIVIDUAL_BENCHMARK_FAILURE,
            payload: message
        })
    }
}

export function* saveIndividualBenchmarkWatcher() {
    yield takeLatest(SAVE_INDIVIDUAL_BENCHMARK_REQUEST, saveIndividualBenchmark)
}

// SAVE BENCHMARK
export function* saveBenchmark({ payload }) {
    const { sessionId, periodNumber, body } = payload
    try {
        const response = yield call(apiService.saveBenchmark, sessionId, periodNumber, body)
        if (response.status === 200)
            yield put({
                type: SAVE_BENCHMARK_SUCCESS,
                payload: "Benchmark was successfully saved!"
            })
    } catch (err) {
        var message = err.response.data.error
        yield put({
            type: SAVE_BENCHMARK_FAILURE,
            payload: message
        })
    }
}

export function* saveBenchmarkWatcher() {
    yield takeLatest(SAVE_BENCHMARK_REQUEST, saveBenchmark)
}

// GET SHARPE RATIO COMPUTE CHART
export function* getSharpeRatioComputeChart({ payload }) {
    const sessionId = payload
    const { data } = yield call(apiService.getSharpeRatioComputeChart, sessionId)
    yield put({
        type: GET_SHARPE_RATIO_COMPUTE_CHART_SUCCESS,
        payload: { data }
    })
}

export function* getSharpeRatioComputeChartWatcher() {
    yield takeLatest(GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST, getSharpeRatioComputeChart)
}

// GET TEAM CLASSES
export function* getTeamClasses({ payload }) {
    const { data } = yield call(apiService.getTeamClasses, payload)
    yield put({
        type: GET_TEAM_CLASSES_SUCCESS,
        payload: data
    })
}

export function* getTeamClassesWatcher() {
    yield takeLatest(GET_TEAM_CLASSES_REQUEST, getTeamClasses)
}

export function* teamSaga() {
    yield all([call(getTeamGamesWatcher), call(getTeamBenchmarkAllocationWatcher), call(saveIndividualBenchmarkWatcher), call(saveBenchmarkWatcher), call(getSharpeRatioComputeChartWatcher), call(getTeamClassesWatcher)])
}