import { takeLatest, put, all, call } from 'redux-saga/effects'
import apiService from '../../service/apiService'
import { setUserStatus } from '../trainer/trainer.actions'
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST, GET_TEAM_SUCCESS, GET_TEAM_REQUEST, ADD_PERSON_SUCCESS, ADD_PERSON_FAILURE, ADD_PERSON_REQUEST, OPEN_MODAL } from './account.constants'

// LOGIN
export function* login({ payload }) {
    const { username, password } = payload
    try {
        const response = yield call(apiService.login, { username, password })
        if (response) {
            let usernameR = response.data.username
            let groups = response.data.groups
            let currentSession = response.data.current_session_id
            yield put({
                type: LOGIN_SUCCESS,
                payload: { "groups": groups, "username": usernameR, "currentSession": currentSession }
            })
            localStorage.setItem('access_token', response.data.access)
            localStorage.setItem('refresh_token', response.data.refresh)
            localStorage.setItem('role', groups[0])
            if (groups[0] === "Teams") {
                localStorage.setItem('team', response.data.username)
                setUserStatus()
                yield put({ type: OPEN_MODAL, payload: { isOpen: true } })
            } else if (groups[0] === "Trainers") {
                if (response.data.current_session_id)
                    window.location.assign(`/trainer/session/${response.data.current_session_id}`)
                else
                    window.location.assign(`/trainer/scenarios`)
            } else if (groups[0] === "Admin") {
                window.location.assign(`http://api.asset-mgmt.traderion.com/admin/`)
            }
        }
    } catch (err) {
        if (err.response.data.detail === 'No active account found with the given credentials' && err.response.status === 401)
            yield put({
                type: LOGIN_FAILURE,
                payload: 'Incorrect username or password, please try again!'
            })
        else
            yield put({
                type: LOGIN_FAILURE,
                payload: 'Something went wrong. Please try again later.'
            })
    }
}

export function* loginWatcher() {
    yield takeLatest(LOGIN_REQUEST, login)
}

// GET TEAM NAME AND PERSONS
export function* getTeam({ payload }) {
    const { data: { name, personas } } = yield call(apiService.getTeam, payload)
    yield put({
        type: GET_TEAM_SUCCESS,
        payload: { name, personas }
    })
}

export function* getTeamWatcher() {
    yield takeLatest(GET_TEAM_REQUEST, getTeam)
}

// ADD PERSON IN TEAM
export function* addPerson({ payload }) {
    const { name } = payload
    try {
        yield call(apiService.addPerson, { name })
        yield put({
            type: ADD_PERSON_SUCCESS,
            payload: { name }
        })

    } catch (err) {
        yield put({
            type: ADD_PERSON_FAILURE,
            payload: 'Error!!!'
        })
    }
}

export function* addPersonWatcher() {
    yield takeLatest(ADD_PERSON_REQUEST, addPerson)
}

export function* accountSaga() {
    yield all([call(loginWatcher), call(getTeamWatcher), call(addPersonWatcher)])
}
