import React, { useEffect, useState } from 'react'
import '../App.scss'
import './Pages.scss'
import SideBarGameTeam from '../components/SideBarGameTeam/SideBarGameTeam'
import Layout, { Content } from 'antd/lib/layout/layout'
import { Row, Col } from 'antd'
import InstrumentsGameTeam from '../components/InstrumentsGameTeam/InstrumentsGameTeam'
import { connect } from 'react-redux'
import { getTeamBenchmarkAllocationRequest } from '../redux/team/team.actions'
import TeamPortfolio from '../components/TeamPortfolio/TeamPortfolio'
import { getSessionRequest } from '../redux/trainer/trainer.actions'
import WelcomeScreen from '../components/stages/WelcomeScreen'
import Description from '../components/stages/Description'
import BenchmarkAndLeverage from '../components/stages/BenchmarkAndLeverage'
import MacroBackground from '../components/stages/MacroBackground'
import PortfoliosAllocation from '../components/stages/PortfoliosAllocation'
import PortfoliosCompare from '../components/stages/PortfoliosCompare'
import MacroReview from '../components/stages/MacroReview'
import InstrumentsReturns from '../components/stages/InstrumentsReturn'
import PortfoliosValues from '../components/stages/PortfoliosValues'
import PortfoliosPerformance from '../components/stages/PortfoliosPerformance'
import DailyValues from '../components/finalStages/DailyValues'
import DailyReturns from '../components/finalStages/DailyReturns'
import OverallReturn from '../components/finalStages/OverallReturn'
import AnnualPerformance from '../components/finalStages/AnnualPerformance'
import SharpeRatioCompute from '../components/finalStages/SharpeRatioCompute'
import SharpeRatioCompare from '../components/finalStages/SharpeRatioCompare'
import EndGame from '../components/finalStages/EndGame'
import DescriptionUserModal from '../components/DescriptionUserModal/DescriptionUserModal'

const ReportTeam = ({ getTeamBenchmarkAllocationRequest, responseGetBenchmark, globalCurrentPeriod, globalCurrentStage, getSessionRequest, getSessionResponse, activeMenuItemForTeam, progressBar }) => {

    const idGame = localStorage.getItem("game_id")

    // get session
    useEffect(() => {
        if (idGame)
            getSessionRequest(idGame)
    }, [idGame])

    var periodsScenario = []
    var instrumentsScenario = []
    var description = null
    if (getSessionResponse) {
        periodsScenario = getSessionResponse.scenario.periods
        instrumentsScenario = getSessionResponse.scenario.instrument_information
        description = getSessionResponse.scenario.description
    }

    // set current stage and current period
    const [currentPeriodAux, setCurrentPeriodAux] = useState(null)
    const [currentStageAux, setCurrentStageAux] = useState(null)

    useEffect(() => {
        if (responseGetBenchmark) {
            setCurrentStageAux(0)
            setCurrentPeriodAux(0)
            componentsSwitch(globalCurrentPeriod, globalCurrentStage)
        }
    }, [globalCurrentStage, globalCurrentPeriod, responseGetBenchmark])

    // change stage by user
    useEffect(() => {
        if (activeMenuItemForTeam) {
            if (activeMenuItemForTeam === "0") {
                setCurrentStageAux(0)
                setCurrentPeriodAux(0)
            } else if (activeMenuItemForTeam === "1") {
                setCurrentStageAux(1)
                setCurrentPeriodAux(0)
            } else if (activeMenuItemForTeam === "2") {
                setCurrentStageAux(2)
                setCurrentPeriodAux(0)
            } else if (activeMenuItemForTeam === "final_0") {
                setCurrentStageAux(0)
                setCurrentPeriodAux(-1)
            } else if (activeMenuItemForTeam === "final_1") {
                setCurrentStageAux(1)
                setCurrentPeriodAux(-1)
            } else if (activeMenuItemForTeam === "final_2") {
                setCurrentStageAux(2)
                setCurrentPeriodAux(-1)
            } else if (activeMenuItemForTeam === "final_3") {
                setCurrentStageAux(3)
                setCurrentPeriodAux(-1)
            } else if (activeMenuItemForTeam === "final_4") {
                setCurrentStageAux(4)
                setCurrentPeriodAux(-1)
            } else if (activeMenuItemForTeam === "final_5") {
                setCurrentStageAux(5)
                setCurrentPeriodAux(-1)
            } else {
                if (activeMenuItemForTeam.charAt(activeMenuItemForTeam.length - 2) > 0) {
                    setCurrentPeriodAux(parseInt(activeMenuItemForTeam.charAt(activeMenuItemForTeam.length - 2)))
                    setCurrentStageAux(parseInt(activeMenuItemForTeam.charAt(activeMenuItemForTeam.length - 1)))
                }
            }
        }
    }, [activeMenuItemForTeam])

    // get team allocation request
    useEffect(() => {
        getTeamBenchmarkAllocationRequest(idGame)
    }, [idGame, globalCurrentPeriod, globalCurrentStage, progressBar, getSessionResponse])

    // show elements/pages by period and stage
    const componentsSwitch = (currentPeriod, currentStage) => {
        if (currentPeriod === 0) {
            if (currentStage === 0)
                return <WelcomeScreen />
            else if (currentStage === 1) {
                return <Description description={description} />
            } else if (currentStage === 2) {
                return <BenchmarkAndLeverage instrumentInformation={instrumentsScenario} />
            }
        } else if (currentPeriod === -1) {
            switch (currentStage) {
                case 0:
                    return <DailyValues />
                case 1:
                    return <DailyReturns />
                case 2:
                    return <OverallReturn />
                case 3:
                    return <AnnualPerformance />
                case 4:
                    return <SharpeRatioCompute report={true} />
                case 5:
                    return <SharpeRatioCompare />
                case 6:
                    return <EndGame />
                default:
                    break;
            }
        } else if (currentPeriod > 0) {
            switch (currentStage) {
                case 0:
                    return <MacroBackground periodNumber={currentPeriod} periods={periodsScenario} />
                case 1:
                    return <PortfoliosAllocation periodNumber={currentPeriod} periods={periodsScenario} />
                case 2:
                    return <PortfoliosCompare periodNumber={currentPeriod} />
                case 3:
                    return <MacroReview periodNumber={currentPeriod} periods={periodsScenario} />
                case 4:
                    return <InstrumentsReturns periodNumber={currentPeriod} />
                case 5:
                    return <PortfoliosValues periodNumber={currentPeriod} />
                case 6:
                    return <PortfoliosPerformance periodNumber={currentPeriod} />
                default:
                    break;
            }
        }
    }

    // zoom description
    const [showModal, setShowModal] = useState(false)
    const [viewItem, setViewItem] = useState(null)

    if (responseGetBenchmark && (globalCurrentStage !== null) && (globalCurrentPeriod !== null))
        return (
            <Layout>
                <DescriptionUserModal showModal={showModal} setShowModal={setShowModal} content={viewItem} />
                <SideBarGameTeam periodMenu={globalCurrentPeriod} stageMenu={globalCurrentStage} />
                <Content>
                    <div className="general_container team_report">
                        <Row className="row_team_game">
                            {((globalCurrentPeriod === 0 && globalCurrentStage !== 0) || globalCurrentPeriod !== 0) &&
                                <Col span={12} xl={12} lg={24} md={24} sm={24} xs={24} className="first_col_style">
                                    <Row className="instruments_details">
                                        <InstrumentsGameTeam instruments={getSessionResponse} />
                                    </Row>
                                    <Row className="portfolio_table">
                                        <h5 className="subtitle">Portfolio</h5>
                                        {
                                            (responseGetBenchmark && getSessionResponse) &&
                                            <TeamPortfolio responseBenchmark={responseGetBenchmark} session={getSessionResponse} report={true}/>
                                        }
                                    </Row>
                                </Col>
                            }
                            <Col span={11} xl={11} lg={24} md={24} sm={24} xs={24} className="second_col_style"
                                onClick={() => {
                                    setShowModal(true)
                                    let comp = componentsSwitch(currentPeriodAux, currentStageAux)
                                    setViewItem(comp)
                                }}
                            >
                                {componentsSwitch(currentPeriodAux, currentStageAux)}
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        )
    return null
}

const mapStateToProps = state => ({
    responseGetBenchmark: state.team.responseGetBenchmark,
    getSessionResponse: state.trainer.getSessionResponse,
    activeMenuItemForTeam: state.team.activeMenuItemForTeam,
    globalCurrentPeriod: state.team.globalCurrentPeriod,
    globalCurrentStage: state.team.globalCurrentStage,
    progressBar: state.team.progressBar
})

const mapDispatchToProps = dispatch => ({
    getTeamBenchmarkAllocationRequest: payload => dispatch(getTeamBenchmarkAllocationRequest(payload)),
    getSessionRequest: payload => dispatch(getSessionRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportTeam)