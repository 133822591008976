import React from 'react'
import './DescriptionUserModal.scss'
import { Modal } from 'antd'

const DescriptionUserModal = ({ setShowModal, showModal, content }) => {

    const handleCancel = () => {
        setShowModal(false)
    }

    return (
        <Modal
            title=""
            visible={showModal}
            onCancel={handleCancel}
            footer={null}
            className="description_user_modal "
        >
            <div>
                {content}
            </div>
        </Modal>
    )
}

export default DescriptionUserModal