import React, { useState, useEffect } from 'react'

const PortfoliosAllocation = ({ periodNumber, periods }) => {
    const role = localStorage.getItem("role")
    const [description, setDescription] = useState(null)

    useEffect(() => {
        if (periods && periods.length > 0)
            periods.map((p, index) => {
                if (periodNumber === (index + 1)) {
                    setDescription(p.macroeconomic_background.general_description)
                }
                return p
            })
    }, [periodNumber, periods])

    return (
        <>
            {
                (role && role === "Teams") &&
                <div className="general_container stage_container">
                    <h4 className="subtitle-stage">Macro Background</h4>
                    {
                        description &&
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: description }}></div>
                        </div>

                    }
                </div>
            }
            {
                (role && role === "Trainers") &&
                <div className="general_container stage_container_no_content">
                    <h4 className="subtitle-stage-msg">Now teams set Portfolios'allocation!</h4>
                </div>
            }
        </>
    )
}

export default PortfoliosAllocation