import React, { useEffect, useState } from 'react'
import '../App.scss'
import './Pages.scss'
import Layout, { Content } from 'antd/lib/layout/layout'
import SideBarGame from '../components/SideBarGame/SideBarGame'
import { Table } from 'antd'
import { connect } from 'react-redux'
import { setSelectedMenuItem, getAllocationRequest, updateAllocationsTableRequest } from '../redux/trainer/trainer.actions'
import AllocationModal from '../components/AllocationModal/AllocationModal'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

const Allocations = ({ setSelectedMenuItem, selectedMenuItem, getAllocationRequest, allocationResponse, currentPeriod, updateAllocationTable, clickedElemHeader, updateAllocationsTableRequest }) => {

    const idGame = localStorage.getItem("game_id")
    // WEB SOCKETS
    var client
    useEffect(() => {
        client = new W3CWebSocket(`wss://api.asset-mgmt.traderion.com/ws/session/${idGame}?token=${localStorage.getItem('access_token')}`)
        var eventType = null
        var messageType = null

        client.onopen = () => {
            console.log('Game WebSocket Client Connected ');
        }

        client.onmessage = (message) => {
            eventType = JSON.parse(message.data).event_type
            messageType = JSON.parse(message.data).message.message_type
            if (eventType === "TEAM_TO_ALL" && messageType === "BENCHMARK_ADDED") {
                updateAllocationsTableRequest()
            }
        }

        client.onclose = function () {
            console.log('Game Connection Closed.');
        }
    }, [localStorage.getItem('access_token')])

    // close connection when change page
    useEffect(() => {
        if (clickedElemHeader && client)
            client.close()
    }, [clickedElemHeader])

    // get allocation
    useEffect(() => {
        if (idGame)
            getAllocationRequest(idGame)
    }, [updateAllocationTable, idGame])

    // table
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        }
    ]
    const [columnsState, setColumnsState] = useState(null)
    const [dataState, setDataState] = useState(null)
    useEffect(() => {
        var data = []
        var auxData = {}
        var auxElem = {}
        var auxElem2 = {}
        var auxElem3 = {}
        if (allocationResponse && allocationResponse.length > 0) {
            for (var m = 0; m < allocationResponse[0].instruments.length; m++) {
                columns.push({
                    "title": <div className="instr_head">{allocationResponse[0].instruments[m].instrument_name}</div>,
                    "dataIndex": "instrument" + m
                })
            }

            columns.push(
                {
                    title: 'Period',
                    children: [
                        {
                            title: 'Initial Value',
                            dataIndex: 'initialValue',
                            key: 'initialValue',
                        },
                        {
                            title: 'Return',
                            dataIndex: 'return',
                            key: 'return',
                        },
                        {
                            title: 'Final Value',
                            dataIndex: 'finalValue',
                            key: 'finalValue',
                        },
                    ],
                },
                {
                    title: "Return (current total)",
                    dataIndex: "total"
                },
                {
                    title: "Volatility",
                    dataIndex: "volatility"
                },
                {
                    title: "Sharpe Ratio",
                    dataIndex: "sharpeRatio"
                }
            )

            var element = {}
            for (var i = 0; i < allocationResponse.length; i++) {
                auxElem = {}
                auxElem2 = {}
                auxElem3 = {}

                if (allocationResponse[i].instruments.length > 0) {
                    for (var j = 0; j < allocationResponse[i].instruments.length; j++) {
                        if (parseInt(currentPeriod) === 0) {
                            element[`instrument${j}`] = "-" + " v " + allocationResponse[i].instruments[j].benchmark
                            auxElem3 = Object.assign(auxElem3, element)
                        }
                        for (var k = 0; k < allocationResponse[i].instruments[j].periods.length; k++) {
                            element = {}
                            if (parseInt(currentPeriod) === allocationResponse[i].instruments[j].periods[k].period_number) {
                                element[`instrument${j}`] = <div>
                                    <span className={
                                        (((allocationResponse[i].instruments[j].periods[k].weight === 0 && allocationResponse[i].instruments[j].benchmark !== 0) && (allocationResponse[i].instruments[j].periods[k].weight < allocationResponse[i].instruments[j].benchmark)) || ((allocationResponse[i].instruments[j].periods[k].weight !== 0) && (allocationResponse[i].instruments[j].periods[k].weight < allocationResponse[i].instruments[j].benchmark))) ? "red_class"
                                            :
                                        (((allocationResponse[i].instruments[j].periods[k].weight === 0 && allocationResponse[i].instruments[j].benchmark !== 0) && (allocationResponse[i].instruments[j].periods[k].weight >= allocationResponse[i].instruments[j].benchmark)) || ((allocationResponse[i].instruments[j].periods[k].weight !== 0) && (allocationResponse[i].instruments[j].periods[k].weight >= allocationResponse[i].instruments[j].benchmark))) ? "green_class"
                                            :
                                        "bold_text"}>
                                        {allocationResponse[i].instruments[j].periods[k].weight}</span>  v < span > {allocationResponse[i].instruments[j].benchmark}
                                    </span >
                                </div >
                                auxElem = Object.assign(auxElem, element)
                            } else {
                                element[`instrument${j}`] = "-" + " v " + allocationResponse[i].instruments[j].benchmark
                                auxElem2 = Object.assign(auxElem2, element)
                            }
                        }
                    }
                }

                var initialValueAux = {}
                var returnAux = {}
                var finalValueAux = {}
                var totalAux = {}
                var volatilityAux = {}
                var sharpeRatioAux = {}
                if (parseInt(currentPeriod) === 0) {
                    initialValueAux = {}
                    returnAux = {}
                    if (allocationResponse[i].initial_value)
                        initialValueAux = { "initialValue": allocationResponse[i].initial_value.benchmark }
                    if (allocationResponse[i].return)
                        returnAux = { "return": allocationResponse[i].return.benchmark }
                    if (allocationResponse[i].final_value)
                        finalValueAux = { "finalValue": allocationResponse[i].final_value.benchmark }
                    if (allocationResponse[i].current_total)
                        totalAux = { "total": allocationResponse[i].current_total.benchmark }
                    if (allocationResponse[i].volatility)
                        volatilityAux = { "volatility": allocationResponse[i].volatility.benchmark }
                    if (allocationResponse[i].sharpe_ratio)
                        sharpeRatioAux = { "sharpeRatio": allocationResponse[i].sharpe_ratio.benchmark }
                } else {
                    initialValueAux = {}
                    returnAux = {}
                    for (var c = 0; c < allocationResponse[i].instruments[0].periods.length; c++) {
                        if (allocationResponse[i].initial_value && parseInt(currentPeriod) === allocationResponse[i].initial_value.periods[c].period_number)
                            initialValueAux = { "initialValue": allocationResponse[i].initial_value.periods[c].weight }
                        if (allocationResponse[i].return && parseInt(currentPeriod) === allocationResponse[i].return.periods[c].period_number) {
                            if (allocationResponse[i].return.periods[c].weight !== 0) {
                                var arr = (allocationResponse[i].return.periods[c].weight).split(/%|v/)
                                var first = parseFloat(arr[0])
                                var second = parseFloat(arr[2])
                                returnAux = { "return": <div><span className={first < second ? "red_class" : first >= second ? "green_class" : "bold_text"}>{first}%</span> v <span>{second}%</span></div> }
                            } else {
                                returnAux = { "return": allocationResponse[i].return.periods[c].weight }
                            }
                        }
                        if (allocationResponse[i].final_value && parseInt(currentPeriod) === allocationResponse[i].final_value.periods[c].period_number)
                            finalValueAux = { "finalValue": allocationResponse[i].final_value.periods[c].weight }
                        if (allocationResponse[i].current_total && parseInt(currentPeriod) === allocationResponse[i].current_total.periods[c].period_number) {
                            if (allocationResponse[i].current_total.periods[c].weight !== 0) {
                                var arr = (allocationResponse[i].current_total.periods[c].weight).split(/%|v/)
                                var first = parseFloat(arr[0])
                                var second = parseFloat(arr[2])
                                totalAux = { "total": <div><span className={first < second ? "red_class" : first >= second ? "green_class" : "bold_text"}>{first}%</span> v <span>{second}%</span></div> }
                            } else {
                                totalAux = { "total": allocationResponse[i].current_total.periods[c].weight }
                            }
                        }
                        if (allocationResponse[i].volatility && parseInt(currentPeriod) === allocationResponse[i].volatility.periods[c].period_number) {
                            if (allocationResponse[i].volatility.periods[c].weight !== 0) {
                                var arr = (allocationResponse[i].volatility.periods[c].weight).split("v")
                                var first = parseFloat(arr[0])
                                var second = parseFloat(arr[1])
                                volatilityAux = { "volatility": <div><span className={first < second ? "red_class" : first >= second ? "green_class" : "bold_text"}>{first}</span> v <span>{second}</span></div> }
                            } else {
                                volatilityAux = { "volatility": allocationResponse[i].volatility.periods[c].weight }
                            }
                        }
                        if (allocationResponse[i].sharpe_ratio && parseInt(currentPeriod) === allocationResponse[i].sharpe_ratio.periods[c].period_number) {
                            if (allocationResponse[i].sharpe_ratio.periods[c].weight !== 0) {
                                var arr = (allocationResponse[i].sharpe_ratio.periods[c].weight).split(/%|v/)
                                var first = parseFloat(arr[0])
                                var second = parseFloat(arr[2])
                                sharpeRatioAux = { "sharpeRatio": <div><span className={first < second ? "red_class" : first >= second ? "green_class" : "bold_text"}>{first}%</span> v <span>{second}%</span></div> }
                            } else {
                                sharpeRatioAux = { "sharpeRatio": allocationResponse[i].sharpe_ratio.periods[c].weight }
                            }
                        }
                    }
                }

                auxData = {
                    "key": allocationResponse[i].team_id,
                    "name": allocationResponse[i].team_name,
                }

                let row
                if ((Object.keys(auxElem3).length === 0) === false) {
                    row = Object.assign(auxData, auxElem3)
                    row = Object.assign(row, initialValueAux)
                    row = Object.assign(row, returnAux)
                    row = Object.assign(row, finalValueAux)
                    row = Object.assign(row, totalAux)
                    row = Object.assign(row, volatilityAux)
                    row = Object.assign(row, sharpeRatioAux)
                } else if ((Object.keys(auxElem).length === 0) === false) {
                    row = Object.assign(auxData, auxElem)
                    row = Object.assign(row, initialValueAux)
                    row = Object.assign(row, returnAux)
                    row = Object.assign(row, finalValueAux)
                    row = Object.assign(row, totalAux)
                    row = Object.assign(row, volatilityAux)
                    row = Object.assign(row, sharpeRatioAux)
                } else if ((Object.keys(auxElem2).length === 0) === false) {
                    row = Object.assign(auxData, auxElem2)
                    row = Object.assign(row, initialValueAux)
                    row = Object.assign(row, returnAux)
                    row = Object.assign(row, finalValueAux)
                    row = Object.assign(row, totalAux)
                    row = Object.assign(row, volatilityAux)
                    row = Object.assign(row, sharpeRatioAux)
                }
                data.push(row)
            }
        }
        setDataState(data)
        setColumnsState(columns)
    }, [allocationResponse, currentPeriod])

    // on click row
    const [modalContent, setModalContent] = useState(null)
    const onClickRow = (record) => {
        var key = record.key
        if (allocationResponse && allocationResponse.length > 0) {
            for (var r = 0; r < allocationResponse.length; r++) {
                if (key === allocationResponse[r].team_id) {
                    setModalContent(allocationResponse[r])
                }
            }
        }
        setShowModal(true)
    }

    // modal
    const [showModal, setShowModal] = useState(false)

    return (
        <Layout>
            <AllocationModal showModal={showModal} setShowModal={setShowModal} modalContent={modalContent} />
            <SideBarGame selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem} />
            <Content>
                <div className="general_container allocations">
                    <Table className="allocations_table" columns={columnsState} dataSource={dataState} pagination={false} scroll={{ x: 'fit-content' }}
                        onRow={(r) => ({
                            onClick: () => onClickRow(r)
                        })} />
                </div>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    selectedMenuItem: state.trainer.selectedMenuItem,
    allocationResponse: state.trainer.allocationResponse,
    currentPeriod: state.trainer.currentPeriod,
    updateAllocationTable: state.trainer.updateAllocationTable,
    clickedElemHeader: state.trainer.clickedElemHeader
})

const mapDispatchToProps = dispatch => ({
    setSelectedMenuItem: payload => dispatch(setSelectedMenuItem(payload)),
    getAllocationRequest: payload => dispatch(getAllocationRequest(payload)),
    updateAllocationsTableRequest: payload => dispatch(updateAllocationsTableRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Allocations)
