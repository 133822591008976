import React, { useState } from 'react'
import './SearchBar.scss'
import { Button, Select } from 'antd'
import Search from 'antd/lib/input/Search'
import CreateSessionModal from '../CreateSessionModal/CreateSessionModal'

const SearchBar = ({ selectList, visible, onSearch, handleChange, profileList, onChangeProfile, periodList, onChangePeriod, noOfPeriodsList, onChangeNoOfPeriods }) => {

    // new session modal
    const [showModal, setShowModal] = useState(false)

    return (
        <div className="top_bar">
            <CreateSessionModal showModal={showModal} setShowModal={setShowModal} />
            <Search className="search_input" placeholder="Search" onSearch={onSearch} />
            <Select
                allowClear={true}
                placeholder="Profile"
                onChange={onChangeProfile}
                className="select_class"
            >
                {profileList}
            </Select>
            <Select
                allowClear={true}
                placeholder="Period"
                onChange={onChangePeriod}
                className="select_class"
            >
                {periodList}
            </Select>
            <Select
                allowClear={true}
                placeholder="No. of periods"
                onChange={onChangeNoOfPeriods}
                className="select_class"
            >
                {noOfPeriodsList}
            </Select>
            <Select
                className="select_multiple"
                mode="multiple"
                allowClear
                placeholder="Please select"
                onChange={handleChange}
            >
                {selectList}
            </Select>
            <Button className={visible ? "visible_btn" : "hide_btn"} visible={visible} onClick={() => setShowModal(true)}>New Session</Button>
        </div>

    )
}

export default SearchBar