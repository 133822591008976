import { LOGIN_REQUEST, GET_TEAM_REQUEST, ADD_PERSON_REQUEST, OPEN_MODAL, CLOSE_MODAL } from './account.constants'

export const loginRequest = (payload) => {
    return {
        type: LOGIN_REQUEST,
        payload
    }
}

export const getTeamRequest = (payload) => {
    return {
        type: GET_TEAM_REQUEST,
        payload
    }
}

export const addPersonRequest = (payload) => {
    return {
        type: ADD_PERSON_REQUEST,
        payload
    }
}

export const openModal = (payload) => {
    return {
        type: OPEN_MODAL,
        payload
    }
}

export const closeModal = (payload) => {
    return {
        type: CLOSE_MODAL,
        payload
    }
}