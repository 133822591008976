import axios from 'axios'

function isTokenExpiredError(errorResponse) {
    if (errorResponse && errorResponse.data.detail === 'Given token not valid for any token type' && errorResponse.status && errorResponse.status === 401)
        return true
    return false
}

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
    try {
        const { response: errorResponse } = error;
        const resetToken = localStorage.getItem('refresh_token')
        if (!resetToken) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem("role")
            if (localStorage.getItem("game_id")) {
                localStorage.removeItem("game_id")
            }
            if (localStorage.getItem("username")) {
                localStorage.removeItem("username")
            }
            if (localStorage.getItem("team")) {
                localStorage.removeItem("team")
            }
            window.location.assign(`/`)
            return Promise.reject(error);
        }
        const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
                errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
                resolve(axios(errorResponse.config));
            });
        });
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            const response = await axios({
                method: 'post',
                url: 'https://api.asset-mgmt.traderion.com/api/v1/login/refresh',
                data: {
                    refresh: resetToken
                }
            });

            if (!response.data) {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem("role")
                if (localStorage.getItem("game_id")) {
                    localStorage.removeItem("game_id")
                }
                if (localStorage.getItem("username")) {
                    localStorage.removeItem("username")
                }
                if (localStorage.getItem("team")) {
                    localStorage.removeItem("team")
                }
                window.location.assign(`/`)
                return Promise.reject(error);
            }
            const newToken = response.data.access;
            localStorage.setItem('access_token', newToken)
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(newToken);
        }
        return retryOriginalRequest;
    } catch (err) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem("role")
        if (localStorage.getItem("game_id")) {
            localStorage.removeItem("game_id")
        }
        if (localStorage.getItem("username")) {
            localStorage.removeItem("username")
        }
        if (localStorage.getItem("team")) {
            localStorage.removeItem("team")
        }
        window.location.assign(`/`)
        return Promise.reject(err);
    }
}

function onAccessTokenFetched(access_token) {
    subscribers.forEach(callback => callback(access_token));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

class ApiClient {
    constructor() {
        this.apiClient = axios.create({
            baseURL: 'https://api.asset-mgmt.traderion.com/api/v1'
        })

        this.apiClient.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('access_token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        this.apiClient.interceptors.response.use(
            function (response) {
                return response
            },
            function (error) {
                const errorResponse = error.response
                if (isTokenExpiredError(errorResponse)) {
                    return resetTokenAndReattemptRequest(error)
                }
                if (errorResponse && errorResponse.status && errorResponse.status === 404) {
                    window.location.assign(`/404`)
                }
                return Promise.reject(error)
            }
        )
        return this.apiClient
    }
}

export default new ApiClient()
