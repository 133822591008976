import { CREATE_SESSION_REQUEST, CURRENT_STAGE, GET_ALLOCATION_REQUEST, GET_ANNUAL_PERFORMANCE_CHART_REQUEST, GET_BENCHMARK_PORTFOLIO_REQUEST, GET_CLASS_TEAMS_REQUEST, GET_DAILY_RETURNS_CHART_REQUEST, GET_DAILY_VALUES_CHART_REQUEST, GET_INSTRUMENTS_RETURNS_CHART_REQUEST, GET_OVERALL_RETURNS_CHART_REQUEST, GET_PORTFOLIO_COMPARE_CHART_REQUEST, GET_PORTFOLIO_PERFORMANCE_CHART_REQUEST, GET_PORTFOLIO_VALUES_CHART_REQUEST, GET_SCENARIOS_BY_TYPE_REQUEST, GET_SCENARIOS_REQUEST, GET_SCENARIO_PREVIEW_REQUEST, GET_SESSION_REQUEST, GET_SHARPE_RATIO_COMPARE_CHART_REQUEST, GET_CLASSES_REQUEST, GET_TRAINER_GAMES_REQUEST, NEXT_STAGE, NEXT_STAGE_SESSION_REQUEST, PREV_STAGE, SELECT_MENU_ITEM, SET_CURRENT_ACTIVE_INSTRUMENT, SET_FAVOURITE_SCENARIO_REQUEST, START_SESSION_REQUEST, GET_TRAINER_CLASSES_REQUEST, PAUSE_SESSION_REQUEST, STOP_SESSION_REQUEST, SET_GAME_STATUS, UPDATE_ACTIVE_USER_TRAINER, GET_TEAMS_SESSION_REQUEST, UPDATE_ALLOCATIONS_TABLE, CLICKED_ELEMENT_FROM_HEADER, UPDATE_BENCHMARK_LEVERAGE, GET_PORTFOLIO_COMPARE_CHART_PREVIEW_REQUEST, GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_REQUEST, GET_PORTFOLIO_VALUES_CHART_PREVIEW_REQUEST, GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_REQUEST, SET_USER_STATUS, CHANGE_USER_STATUS, INITIAL_STATES_REPORT } from './trainer.constants'

export const getTrainerGamesRequest = (payload) => {
    return {
        type: GET_TRAINER_GAMES_REQUEST,
        payload
    }
}

export const getScenariosRequest = (payload) => {
    return {
        type: GET_SCENARIOS_REQUEST,
        payload
    }
}

export const getClassesRequest = (payload) => {
    return {
        type: GET_CLASSES_REQUEST,
        payload
    }
}

export const getTrainerClassesRequest = (payload) => {
    return {
        type: GET_TRAINER_CLASSES_REQUEST,
        payload
    }
}

export const getClassTeamsRequest = (payload) => {
    return {
        type: GET_CLASS_TEAMS_REQUEST,
        payload
    }
}

export const setFavouriteScenarioRequest = (payload) => {
    return {
        type: SET_FAVOURITE_SCENARIO_REQUEST,
        payload
    }
}

export const getScenariosByTypeRequest = (payload) => {
    return {
        type: GET_SCENARIOS_BY_TYPE_REQUEST,
        payload
    }
}

export const createSessionRequest = (payload) => {
    return {
        type: CREATE_SESSION_REQUEST,
        payload
    }
}

export const getScenarioPreviewRequest = (payload) => {
    return {
        type: GET_SCENARIO_PREVIEW_REQUEST,
        payload
    }
}

export const startSessionRequest = (payload) => {
    return {
        type: START_SESSION_REQUEST,
        payload
    }
}

export const getSessionRequest = (payload) => {
    return {
        type: GET_SESSION_REQUEST,
        payload
    }
}

export const getBenchmarkPortfolioRequest = (payload) => {
    return {
        type: GET_BENCHMARK_PORTFOLIO_REQUEST,
        payload
    }
}

export const nextStage = () => {
    return {
        type: NEXT_STAGE
    }
}

export const prevStage = () => {
    return {
        type: PREV_STAGE
    }
}

export const setSelectedMenuItem = (payload) => {
    return {
        type: SELECT_MENU_ITEM,
        payload
    }
}

export const currentStage = () => {
    return {
        type: CURRENT_STAGE
    }
}

export const setCurrentActiveInstrument = (payload) => {
    return {
        type: SET_CURRENT_ACTIVE_INSTRUMENT,
        payload
    }
}

export const getAllocationRequest = (payload) => {
    return {
        type: GET_ALLOCATION_REQUEST,
        payload
    }
}

export const nextStageSessionRequest = (payload) => {
    return {
        type: NEXT_STAGE_SESSION_REQUEST,
        payload
    }
}

export const getPortfolioCompareChartRequest = (payload) => {
    return {
        type: GET_PORTFOLIO_COMPARE_CHART_REQUEST,
        payload
    }
}

export const getInstrumentsReturnsChartRequest = (payload) => {
    return {
        type: GET_INSTRUMENTS_RETURNS_CHART_REQUEST,
        payload
    }
}

export const getPortfolioValuesChartRequest = (payload) => {
    return {
        type: GET_PORTFOLIO_VALUES_CHART_REQUEST,
        payload
    }
}

export const getPortfolioPerformanceChartRequest = (payload) => {
    return {
        type: GET_PORTFOLIO_PERFORMANCE_CHART_REQUEST,
        payload
    }
}

export const getDailyValuesChartRequest = (payload) => {
    return {
        type: GET_DAILY_VALUES_CHART_REQUEST,
        payload
    }
}

export const getDailyReturnsChartRequest = (payload) => {
    return {
        type: GET_DAILY_RETURNS_CHART_REQUEST,
        payload
    }
}

export const getOverallReturnsChartRequest = (payload) => {
    return {
        type: GET_OVERALL_RETURNS_CHART_REQUEST,
        payload
    }
}

export const getAnnualPerformanceChartRequest = (payload) => {
    return {
        type: GET_ANNUAL_PERFORMANCE_CHART_REQUEST,
        payload
    }
}

export const getSharpeRatioCompareChartRequest = (payload) => {
    return {
        type: GET_SHARPE_RATIO_COMPARE_CHART_REQUEST,
        payload
    }
}

export const pauseSessionRequest = (payload) => {
    return {
        type: PAUSE_SESSION_REQUEST,
        payload
    }
}

export const stopSessionRequest = (payload) => {
    return {
        type: STOP_SESSION_REQUEST,
        payload
    }
}

export const setGameStatus = (payload) => {
    return {
        type: SET_GAME_STATUS,
        payload
    }
}

export const updateActiveUserTrainer = (payload) => {
    return {
        type: UPDATE_ACTIVE_USER_TRAINER,
        payload
    }
}

export const getTeamsSessionRequest = (payload) => {
    return {
        type: GET_TEAMS_SESSION_REQUEST,
        payload
    }
}

export const updateAllocationsTableRequest = (payload) => {
    return {
        type: UPDATE_ALLOCATIONS_TABLE,
        payload
    }
}

export const updateBenchmarkLeverageRequest = (payload) => {
    return {
        type: UPDATE_BENCHMARK_LEVERAGE,
        payload
    }
}


export const clickedElementFromHeader = (payload) => {
    return {
        type: CLICKED_ELEMENT_FROM_HEADER,
        payload
    }
}

export const getPortfolioCompareChartPreviewRequest = (payload) => {
    return {
        type: GET_PORTFOLIO_COMPARE_CHART_PREVIEW_REQUEST,
        payload
    }
}

export const getInstrumentsReturnsChartPreviewRequest = (payload) => {
    return {
        type: GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_REQUEST,
        payload
    }
}

export const getPortfolioValuesChartPreviewRequest = (payload) => {
    return {
        type: GET_PORTFOLIO_VALUES_CHART_PREVIEW_REQUEST,
        payload
    }
}

export const getPortfolioPerformanceChartPreviewRequest = (payload) => {
    return {
        type: GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_REQUEST,
        payload
    }
}

export const setUserStatus = (payload) => {
    return {
        type: SET_USER_STATUS,
        payload
    }
}

export const changeUserStatus = (payload) => {
    return {
        type: CHANGE_USER_STATUS,
        payload
    }
}

export const getInitialStatesReport = (payload) => {
    return {
        type: INITIAL_STATES_REPORT,
        payload
    }
}