import React, { useEffect, useState } from 'react'
import './Header.scss'
import logo from '../../images/logo_blue.png'
import { Header } from 'antd/lib/layout/layout'
import Navbar from '../Navbar/Navbar'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { clickedElementFromHeader } from '../../redux/trainer/trainer.actions'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'

const HeaderComponent = ({ clickedElementFromHeader }) => {
    var role = localStorage.getItem("role")
    var gameId = localStorage.getItem("game_id")
    var username
    if (localStorage.getItem("username")) {
        username = localStorage.getItem("username")
    }

    var team
    if (localStorage.getItem("team")) {
        team = localStorage.getItem("team")
    }

    const [visible, setVisible] = useState(true)

    const location = useLocation()
    useEffect(() => {
        if (location.pathname.includes('sessions') || location.pathname.includes('classes') || location.pathname.includes('scenarios') || location.pathname.includes('report')) {
            setVisible(false)
        }
    }, [location])

    return (
        <>
            <Header className="header">
                <img className="logo" src={logo} alt="logo"></img>
                {
                    ((role === "Trainers") && (visible === true)) &&
                    <div className="web-navbar">
                        <Menu mode="horizontal">
                            <Menu.Item >
                                <Link to={`/trainer/session/${gameId}`} onClick={() => clickedElementFromHeader()}>Controls</Link>
                            </Menu.Item>
                            <Menu.Item >
                                <Link to={`/trainer/allocations`} onClick={() => clickedElementFromHeader()}>Allocations</Link>
                            </Menu.Item>
                            <Menu.Item >
                                <Link to={`/trainer/benchmark-portfolio`} onClick={() => clickedElementFromHeader()}>Benchmark Portfolio</Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                }
                {
                    role ?
                        <div className="user_style">
                            <UserOutlined />
                            <label>{role === "Trainers" ? "Trainer Account" : team + ": " + username}</label>
                        </div>
                        :
                        <></>
                }
                <Navbar />
            </Header>
            {((role === "Trainers") && (visible === true)) &&
                <div className="mobile-navbar">
                    <Menu mode="horizontal" className="list_menu">
                        <Menu.Item className="list_item">
                            <Link to={`/trainer/session/${gameId}`} onClick={() => clickedElementFromHeader()}>Controls</Link>
                        </Menu.Item>
                        <Menu.Item className="list_item">
                            <Link to={`/trainer/allocations`} onClick={() => clickedElementFromHeader()}>Allocations</Link>
                        </Menu.Item>
                        <Menu.Item className="list_item">
                            <Link to={`/trainer/benchmark-portfolio`} onClick={() => clickedElementFromHeader()}>Benchmark Portfolio</Link>
                        </Menu.Item>
                    </Menu>
                </div>
            }
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    clickedElementFromHeader: payload => dispatch(clickedElementFromHeader(payload))
})

export default connect(null, mapDispatchToProps)(HeaderComponent)