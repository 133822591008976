import { GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST, GET_TEAM_BENCHMARK_ALLOCATION_REQUEST, GET_TEAM_CLASSES_REQUEST, GET_TEAM_GAMES_REQUEST, SAVE_BENCHMARK_REQUEST, SAVE_INDIVIDUAL_BENCHMARK_REQUEST, SET_ACTIVE_MENU_FOR_TEAM, SET_GLOBAL_CURRENT_PERIOD, SET_GLOBAL_CURRENT_STAGE } from './team.constants'

export const getTeamGamesRequest = (payload) => {
    return {
        type: GET_TEAM_GAMES_REQUEST,
        payload
    }
}

export const getTeamBenchmarkAllocationRequest = (payload) => {
    return {
        type: GET_TEAM_BENCHMARK_ALLOCATION_REQUEST,
        payload
    }
}

export const saveIndividualBenchmarkRequest = (payload) => {
    return {
        type: SAVE_INDIVIDUAL_BENCHMARK_REQUEST,
        payload
    }
}

export const saveBenchmarkRequest = (payload) => {
    return {
        type: SAVE_BENCHMARK_REQUEST,
        payload
    }
}

export const setActiveMenuForTeam = (payload) => {
    return {
        type: SET_ACTIVE_MENU_FOR_TEAM,
        payload
    }
}

export const getSharpeRatioComputeChartRequest = (payload) => {
    return {
        type: GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST,
        payload
    }
}

export const getTeamClassesRequest = (payload) => {
    return {
        type: GET_TEAM_CLASSES_REQUEST,
        payload
    }
}

export const setGlobalCurrentPeriod = (payload) => {
    return {
        type: SET_GLOBAL_CURRENT_PERIOD,
        payload
    }
}

export const setGlobalCurrentStage = (payload) => {
    return {
        type: SET_GLOBAL_CURRENT_STAGE,
        payload
    }
}