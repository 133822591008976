import React, { useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import '../../App.scss'
import './ScenarioStages.scss'
import { connect } from 'react-redux'
import { getPortfolioPerformanceChartRequest } from '../../redux/trainer/trainer.actions'

const PortfoliosPerformance = ({ getPortfolioPerformanceChartRequest, responseGetPortfolioPerformanceChart, periodNumber }) => {

    const idGame = localStorage.getItem("game_id")

    useEffect(() => {
        if (periodNumber)
            getPortfolioPerformanceChartRequest({ sessionId: idGame, periodNumber: periodNumber })
    }, [periodNumber, idGame])

    var finalData = []
    if (responseGetPortfolioPerformanceChart && responseGetPortfolioPerformanceChart.datasets.length > 0)
        responseGetPortfolioPerformanceChart.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible)
                }
            )
            return finalData
        })

    var state
    if (responseGetPortfolioPerformanceChart)
        state = {
            labels: responseGetPortfolioPerformanceChart.labels,
            datasets: finalData
        }

    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 11,
                boxWidth: 20,
                boxHeight: 2
            }
        },
        scales: {
            yAxes: [{
                fontSize: 11,
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        return value + "%"
                    }
                }
            }],
            xAxes: [{
                ticks: {
                    fontSize: 11
                }
            }]
        }
    }

    return (
        <div className="general_container stage_container">
            <h4 className="subtitle-stage">Portfolio: Performance</h4>
            <Bar data={state} options={options} />
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetPortfolioPerformanceChart: state.trainer.responseGetPortfolioPerformanceChart
})

const mapDispatchToProps = dispatch => ({
    getPortfolioPerformanceChartRequest: payload => dispatch(getPortfolioPerformanceChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PortfoliosPerformance)