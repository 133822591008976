import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import '../../App.scss'
import './ScenarioStages.scss'
import { connect } from 'react-redux'
import { getPortfolioValuesChartRequest } from '../../redux/trainer/trainer.actions'

const PortfoliosValues = ({ getPortfolioValuesChartRequest, responseGetPortfolioValuesChart, periodNumber }) => {

    const idGame = localStorage.getItem("game_id")

    useEffect(() => {
        if (periodNumber)
            getPortfolioValuesChartRequest({ sessionId: idGame, periodNumber: periodNumber })
    }, [periodNumber, idGame])

    var finalData = []
    if (responseGetPortfolioValuesChart && responseGetPortfolioValuesChart.datasets.length > 0)
        responseGetPortfolioValuesChart.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible),
                    lineTension: 0,
                }
            )
            return finalData
        })

    var state
    if (responseGetPortfolioValuesChart)
        state = {
            labels: responseGetPortfolioValuesChart.labels,
            datasets: finalData
        }

    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 11,
                boxWidth: 20,
                boxHeight: 2
            }
        },
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    fontSize: 11,
                }
            }],
            xAxes: [{
                autoSkip: false,
                ticks: {
                    callback: function (tick, index, ticks) {
                        if (tick % 10 === 0)
                            return tick.toLocaleString();

                        return null;
                    },
                    fontSize: 11,
                }
            }]


        }
    }

    return (
        <div className="general_container stage_container">
            <h4 className="subtitle-stage">Portfolio: Values</h4>
            <Line
                data={state}
                options={options}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetPortfolioValuesChart: state.trainer.responseGetPortfolioValuesChart
})

const mapDispatchToProps = dispatch => ({
    getPortfolioValuesChartRequest: payload => dispatch(getPortfolioValuesChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PortfoliosValues)