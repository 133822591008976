import React, { useEffect, useRef } from 'react'
import './Navbar.scss'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { connect } from 'react-redux'
import { getTrainerClassesRequest, changeUserStatus } from '../../redux/trainer/trainer.actions'
import { getTeamClassesRequest } from '../../redux/team/team.actions'

const Navbar = ({ getTrainerClassesRequest, trainerClasses, getTeamClassesRequest, teamClasses, changeUserStatus }) => {
    const role = localStorage.getItem("role")

    // get classes by role
    useEffect(() => {
        if (role === "Trainers")
            getTrainerClassesRequest()
        else if (role === "Teams")
            getTeamClassesRequest()
    }, [])

    function check() {
        if (document.getElementById("check-element").checked === true)
            document.getElementById("check-element").checked = false
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    check()
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const navRef = useRef(null)
    useOutsideAlerter(navRef)
    // logout
    const logout = () => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("role")
        if (localStorage.getItem("game_id")) {
            localStorage.removeItem("game_id")
        }
        if (localStorage.getItem("username")) {
            localStorage.removeItem("username")
        }
        if (localStorage.getItem("team")) {
            localStorage.removeItem("team")
        }
        changeUserStatus()
        window.location.assign(`/`)
    }

    return (
        <>
            <div ref={navRef} className="mobile_navbar">
                <nav role='navigation'>
                    <div id="menuToggle">
                        <input id="check-element" type="checkbox" />
                        <span></span>
                        <span></span>
                        <span></span>

                        {
                            role === "Trainers" &&
                            <>
                                <Menu id="menu">
                                    <Link to={`/trainer/scenarios`} onClick={() => check()} className="nav-text">Scenarios</Link>
                                    <Link to={`/trainer/sessions`} onClick={() => check()} className="nav-text">Sessions</Link>
                                    <SubMenu title="Classes" className="submenu_class">
                                        {
                                            trainerClasses &&
                                            trainerClasses.map((c) => {
                                                return <Menu.Item key={"classes" + c.id}>
                                                    <Link to={`/trainer/classes/${c.id}`} className="nav-text" onClick={() => check()}>
                                                        {c.name}
                                                    </Link>
                                                </Menu.Item>
                                            })
                                        }
                                    </SubMenu>
                                    <Link className="logout_item" onClick={() => logout()}>Logout</Link>
                                </Menu>
                            </>
                        }

                        {
                            role === "Teams" &&
                            <>
                                <Menu id="menu">
                                    <Link to={`/team/sessions`} onClick={() => check()} className="nav-text">Sessions</Link>
                                    <SubMenu title="Classes" className="submenu_class">
                                        {
                                            teamClasses &&
                                            teamClasses.map((c) => {
                                                return <Menu.Item key={"classes" + c.id}>
                                                    <Link to={`/team/classes/${c.id}`} className="nav-text" onClick={() => check()}>
                                                        {c.name}
                                                    </Link>
                                                </Menu.Item>
                                            })
                                        }
                                    </SubMenu>
                                    <Link className="logout_item" onClick={() => logout()}>Logout</Link>
                                </Menu>
                            </>
                        }
                    </div>
                </nav>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    trainerClasses: state.trainer.trainerClasses,
    teamClasses: state.team.teamClasses
})

const mapDispatchToProps = dispatch => ({
    getTrainerClassesRequest: payload => dispatch(getTrainerClassesRequest(payload)),
    getTeamClassesRequest: payload => dispatch(getTeamClassesRequest(payload)),
    changeUserStatus: payload => dispatch(changeUserStatus(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)