import React, { useEffect, useState } from 'react'
import './SideBar.scss'
import Sider from 'antd/lib/layout/Sider'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { BarsOutlined, TeamOutlined, UserOutlined, ProfileOutlined, LogoutOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getTrainerClassesRequest, changeUserStatus } from '../../redux/trainer/trainer.actions'
import { getTeamClassesRequest } from '../../redux/team/team.actions'

const SideBar = ({ getTrainerClassesRequest, trainerClasses, getTeamClassesRequest, teamClasses, changeUserStatus }) => {

    const role = localStorage.getItem("role")
    const username = localStorage.getItem('username')

    // set menu state
    const location = useLocation()
    const arr = location.pathname.split("/")
    var keyVal
    if (arr.length === 3) {
        keyVal = arr[2]
    } else {
        keyVal = arr[2] + arr[3]
    }

    const [selectedMenuItem, setSelectedMenuItem] = useState(keyVal)

    // get classes by role
    useEffect(() => {
        if (role === "Trainers")
            getTrainerClassesRequest()
        else if (role === "Teams")
            getTeamClassesRequest()
    }, [])

    // logout
    const logout = () => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("role")
        if (localStorage.getItem("game_id")) {
            localStorage.removeItem("game_id")
        }
        if (localStorage.getItem("username")) {
            localStorage.removeItem("username")
        }
        if (localStorage.getItem("team")) {
            localStorage.removeItem("team")
        }
        changeUserStatus()
        window.location.assign(`/`)
    }

    return (
        <>
            <Sider className="dashboard_sidebar">
                <Menu selectedKeys={selectedMenuItem} mode="inline" onClick={(e) => setSelectedMenuItem(e.key)}>
                    {
                        role === "Trainers" &&
                        <>
                            <Menu.Item key="scenarios" icon={<BarsOutlined />}>
                                <Link to={`/trainer/scenarios`} className="nav-text">Scenarios</Link>
                            </Menu.Item>
                            <Menu.Item key="sessions" icon={<ProfileOutlined />}>
                                <Link to={`/trainer/sessions`} className="nav-text">Sessions</Link>
                            </Menu.Item>
                            <SubMenu title="Classes" icon={<TeamOutlined />}>
                                {
                                    trainerClasses &&
                                    trainerClasses.map((c) => {
                                        return <Menu.Item key={"classes" + c.id}>
                                            <Link to={`/trainer/classes/${c.id}`} className="nav-text">
                                                {c.name}
                                            </Link>
                                        </Menu.Item>
                                    })
                                }
                            </SubMenu>
                        </>
                    }
                    {
                        role === "Teams" &&
                        <>
                            <Menu.Item key="sessions" icon={<ProfileOutlined />}>
                                <Link to={`/team/sessions`} className="nav-text">Sessions</Link>
                            </Menu.Item>
                            <SubMenu title="Classes" icon={<TeamOutlined />}>
                                {
                                    teamClasses &&
                                    teamClasses.map((c) => {
                                        return <Menu.Item key={"classes" + c.id}>
                                            <Link to={`/team/classes/${c.id}`} className="nav-text">
                                                {c.name}
                                            </Link>
                                        </Menu.Item>
                                    })
                                }
                            </SubMenu>
                        </>
                    }

                </Menu>
                <Menu mode="inline" className="user_account">
                    {
                        username ?
                            <Menu.Item className="username_acc" icon={<UserOutlined />}>
                                <span className="name">{username}</span>
                            </Menu.Item>
                            :
                            <></>
                    }
                    <Menu.Item icon={<LogoutOutlined />} onClick={() => logout()}>
                        Logout
                    </Menu.Item>
                </Menu>
            </Sider>
        </>
    )
}

const mapStateToProps = state => ({
    trainerClasses: state.trainer.trainerClasses,
    teamClasses: state.team.teamClasses
})

const mapDispatchToProps = dispatch => ({
    getTrainerClassesRequest: payload => dispatch(getTrainerClassesRequest(payload)),
    getTeamClassesRequest: payload => dispatch(getTeamClassesRequest(payload)),
    changeUserStatus: payload => dispatch(changeUserStatus(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(SideBar)