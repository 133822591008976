import { combineReducers } from 'redux'
import { accountReducer } from './account/account.reducer'
import { trainerReducer } from './trainer/trainer.reducer'
import { teamReducer } from './team/team.reducer'

const rootReducer = combineReducers({
    account: accountReducer,
    trainer: trainerReducer,
    team: teamReducer
})

export default rootReducer