import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGIN_REQUEST, GET_TEAM_REQUEST, GET_TEAM_SUCCESS, ADD_PERSON_REQUEST, ADD_PERSON_SUCCESS, ADD_PERSON_FAILURE, OPEN_MODAL, CLOSE_MODAL } from './account.constants'

const INITIAL_STATE = {
    isLoading: false,
    error: null,
    username: null,
    groups: [],
    currentSession: null,
    name: null,
    personas: [],
    errorAddPerson: null,
    isOpen: false,
    isLoadingLogin: false
}

export const accountReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoadingLogin: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoadingLogin: false,
                username: action.payload.username,
                groups: action.payload.groups,
                currentSession: action.payload.currentSession
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoadingLogin: false
            }
        case GET_TEAM_REQUEST:
            return {
                ...state
            }
        case GET_TEAM_SUCCESS:
            return {
                ...state,
                name: action.payload.name,
                personas: action.payload.personas
            }
        case ADD_PERSON_REQUEST:
            return {
                ...state,
            }
        case ADD_PERSON_SUCCESS:
            return {
                ...state,
                personas: state.personas.concat({ "name": action.payload.name })
            }
        case ADD_PERSON_FAILURE:
            return {
                ...state,
                errorAddPerson: action.payload,
            }
        case OPEN_MODAL:
            return {
                ...state,
                isOpen: action.payload.isOpen
            }
        case CLOSE_MODAL:
            return {
                ...state,
                isOpen: false
            }
        default:
            return state
    }
}