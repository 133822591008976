import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import '../../App.scss'
import './FinalStage.scss'
import { connect } from 'react-redux'
import { getDailyReturnsChartRequest } from '../../redux/trainer/trainer.actions'

const DailyReturns = ({ getDailyReturnsChartRequest, responseGetDailyReturnsChart }) => {

    const idGame = localStorage.getItem("game_id")
    useEffect(() => {
        getDailyReturnsChartRequest(idGame)
    }, [idGame])

    var finalData = []
    if (responseGetDailyReturnsChart && responseGetDailyReturnsChart.datasets.length > 0)
        responseGetDailyReturnsChart.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible),
                    lineTension: 0,
                }
            )
            return finalData
        })

    var state
    if (responseGetDailyReturnsChart)
        state = {
            labels: responseGetDailyReturnsChart.labels,
            datasets: finalData
        }

    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    fontSize: 11,
                    callback: function (value) {
                        return value + "%"
                    }
                }
            }],
            xAxes: [{
                ticks: {
                    callback: function (tick, index, ticks) {
                        if (tick % 10 === 0)
                            return tick.toLocaleString();

                        return null;
                    },
                    fontSize: 11,
                }
            }]
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 11,
                boxWidth: 20,
                boxHeight: 2
            }
        },
        elements: {
            point: {
                radius: 0
            }
        }
    }

    return (
        <div className="general_container final_stage">
            <h4 className="subtitle-stage">Daily Returns</h4>
            <Line
                data={state}
                options={options}
            />
        </div>
    )
}


const mapStateToProps = state => ({
    responseGetDailyReturnsChart: state.trainer.responseGetDailyReturnsChart
})

const mapDispatchToProps = dispatch => ({
    getDailyReturnsChartRequest: payload => dispatch(getDailyReturnsChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(DailyReturns)