import { GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST, GET_SHARPE_RATIO_COMPUTE_CHART_SUCCESS, GET_TEAM_BENCHMARK_ALLOCATION_FAILURE, GET_TEAM_BENCHMARK_ALLOCATION_REQUEST, GET_TEAM_BENCHMARK_ALLOCATION_SUCCESS, GET_TEAM_CLASSES_REQUEST, GET_TEAM_CLASSES_SUCCESS, GET_TEAM_GAMES_REQUEST, GET_TEAM_GAMES_SUCCESS, SAVE_BENCHMARK_FAILURE, SAVE_BENCHMARK_REQUEST, SAVE_BENCHMARK_SUCCESS, SAVE_INDIVIDUAL_BENCHMARK_FAILURE, SAVE_INDIVIDUAL_BENCHMARK_REQUEST, SAVE_INDIVIDUAL_BENCHMARK_SUCCESS, SET_ACTIVE_MENU_FOR_TEAM, SET_GLOBAL_CURRENT_PERIOD, SET_GLOBAL_CURRENT_STAGE } from './team.constants'

const INITIAL_STATE = {
    isLoading: false,
    count: 0,
    results: [],
    pageSize: 10,
    numberOfPages: null,
    response: [],
    playError: null,
    responseGetBenchmark: null,
    progressBar: 0,
    messageBenchmark: null,
    messageSaveIndividualBenchmark: null,
    messageSaveBenchmark: null,
    activeMenuItemForTeam: null,
    responseGetSharpeRatioComputeChart: null,
    sharpeRatio: null,
    teamClasses: [],
    globalCurrentPeriod: null,
    globalCurrentStage: null,
    isLoadingSubmit: false
}

export const teamReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_TEAM_GAMES_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_TEAM_GAMES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                count: action.payload.count,
                results: action.payload.results,
                numberOfPages: Math.ceil(action.payload.count / state.pageSize)
            }
        case GET_TEAM_BENCHMARK_ALLOCATION_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_TEAM_BENCHMARK_ALLOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                responseGetBenchmark: action.payload,
                globalCurrentPeriod: action.payload.current_period,
                globalCurrentStage: action.payload.current_stage,
                progressBar: action.payload.progress_bar
            }
        case GET_TEAM_BENCHMARK_ALLOCATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                messageBenchmark: action.payload
            }
        case SAVE_INDIVIDUAL_BENCHMARK_REQUEST:
            return {
                ...state,
                isLoadingSubmit: true,
                messageSaveIndividualBenchmark: null
            }
        case SAVE_INDIVIDUAL_BENCHMARK_SUCCESS:
            return {
                ...state,
                isLoadingSubmit: false,
                messageSaveIndividualBenchmark: action.payload
            }
        case SAVE_INDIVIDUAL_BENCHMARK_FAILURE:
            return {
                ...state,
                isLoadingSubmit: false,
                messageSaveIndividualBenchmark: action.payload
            }
        case SAVE_BENCHMARK_REQUEST:
            return {
                ...state,
                isLoadingSubmit: true,
                messageSaveBenchmark: null
            }
        case SAVE_BENCHMARK_SUCCESS:
            return {
                ...state,
                isLoadingSubmit: false,
                messageSaveBenchmark: action.payload
            }
        case SAVE_BENCHMARK_FAILURE:
            return {
                ...state,
                isLoadingSubmit: false,
                messageSaveBenchmark: action.payload
            }
        case SET_ACTIVE_MENU_FOR_TEAM:
            return {
                ...state,
                activeMenuItemForTeam: action.payload
            }
        case GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_SHARPE_RATIO_COMPUTE_CHART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                responseGetSharpeRatioComputeChart: action.payload.data,
                sharpeRatio: action.payload.data.sharpe_ratio
            }
        case GET_TEAM_CLASSES_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case GET_TEAM_CLASSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                teamClasses: action.payload
            }
        case SET_GLOBAL_CURRENT_PERIOD:
            return {
                ...state,
                globalCurrentPeriod: action.payload,
                messageSaveBenchmark: "",
                messageSaveIndividualBenchmark: ""
            }
        case SET_GLOBAL_CURRENT_STAGE:
            return {
                ...state,
                globalCurrentStage: action.payload,
                messageSaveBenchmark: "",
                messageSaveIndividualBenchmark: ""
            }
        default:
            return state
    }
}