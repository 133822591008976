import apiClient from './apiClient'

class apiService {
    login(body) {
        return apiClient.post(`/login`, body)
    }

    getTeam() {
        return apiClient.get(`/team`)
    }

    addPerson(body) {
        return apiClient.post(`/team`, body)
    }

    getTeamGames(paramList) {
        return apiClient.get(`/team/sessions`, { params: paramList })
    }

    getScenarios(paramList) {
        return apiClient.get(`/scenario`, { params: paramList })
    }

    getTrainerGames(paramList) {
        return apiClient.get(`/trainer/sessions`, { params: paramList })
    }

    getClasses() {
        return apiClient.get(`/class`)
    }

    getTrainerClasses() {
        return apiClient.get(`/trainer/classes`)
    }

    getTeamClasses() {
        return apiClient.get(`/team/classes`)
    }

    getClassTeams(classId) {
        return apiClient.get(`/class/teams?class_id=${classId}`)
    }

    setFavouriteScenario(scenarioId) {
        return apiClient.put(`/trainer/favourite?scenario_id=${scenarioId}`)
    }

    getScenariosByType(profile) {
        return apiClient.get(`scenario/filter?profile=${profile}`)
    }

    createSession(body) {
        return apiClient.post(`/game`, body)
    }

    getScenarioPreview(scenarioId) {
        return apiClient.get(`/session/preview?scenario_id=${scenarioId}`)
    }

    startSession(sessionId) {
        return apiClient.put(`/session/start?session_id=${sessionId}`)
    }

    getSession(sessionId) {
        return apiClient.get(`/session?session_id=${sessionId}`)
    }

    getBenchmarkPortfolio(sessionId) {
        return apiClient.get(`/session/benchmark-portfolio?session_id=${sessionId}`)
    }

    getAllocation(sessionId) {
        return apiClient.get(`/session/benchmark-allocation?session_id=${sessionId}`)
    }

    getTeamBenchmarkAllocation(sessionId) {
        return apiClient.get(`/team/benchmark-allocation?session_id=${sessionId}`)
    }

    saveIndividualBenchmark(sessionId, body) {
        return apiClient.post(`/session/save-individual-benchmark?session_id=${sessionId}`, body)
    }

    saveBenchmark(sessionId, periodNumber, body) {
        return apiClient.post(`/session/save-benchmark?session_id=${sessionId}&period_number=${periodNumber}`, body)
    }

    nextStageSession(body) {
        return apiClient.put(`/session/next`, body)
    }

    getPortfolioCompareChart(sessionId, periodNumber) {
        return apiClient.get(`/session/charts/portfolio-compare?session_id=${sessionId}&period_number=${periodNumber}`)
    }

    getInstrumentsReturnsChart(sessionId, periodNumber) {
        return apiClient.get(`/session/charts/macro-review?session_id=${sessionId}&period_number=${periodNumber}`)
    }

    getPortfolioValuesChart(sessionId, periodNumber) {
        return apiClient.get(`/session/charts/portfolio-values?session_id=${sessionId}&period_number=${periodNumber}`)
    }

    getPortfolioPerformanceChart(sessionId, periodNumber) {
        return apiClient.get(`/session/charts/portfolio-performance?session_id=${sessionId}&period_number=${periodNumber}`)
    }

    getDailyValuesChart(sessionId) {
        return apiClient.get(`/session/charts/daily-values?session_id=${sessionId}`)
    }

    getDailyReturnsChart(sessionId) {
        return apiClient.get(`/session/charts/daily-returns?session_id=${sessionId}`)
    }

    getOverallReturnsChart(sessionId) {
        return apiClient.get(`/session/charts/overall-returns?session_id=${sessionId}`)
    }

    getAnnualPerformanceChart(sessionId) {
        return apiClient.get(`/session/charts/annual-performance?session_id=${sessionId}`)
    }

    getSharpeRatioComputeChart(sessionId) {
        return apiClient.get(`/session/charts/sharpe-ratio-compute?session_id=${sessionId}`)
    }

    getSharpeRatioCompareChart(sessionId) {
        return apiClient.get(`/session/charts/sharpe-ratio-compare?session_id=${sessionId}`)
    }

    pauseSession(body) {
        return apiClient.put(`/session/pause`, body)
    }

    stopSession(body) {
        return apiClient.put(`/session/stop`, body)
    }

    getTeamsSession(sessionId, periodNumber) {
        return apiClient.get(`/session/teams?session_id=${sessionId}&period_number=${periodNumber}`)
    }

    getPortfolioCompareChartPreview(scenarioId) {
        return apiClient.get(`/session/preview/charts/portfolio-compare?scenario_id=${scenarioId}`)
    }

    getInstrumentsReturnsChartPreview(scenarioId) {
        return apiClient.get(`/session/preview/charts/macro-review?scenario_id=${scenarioId}`)
    }

    getPortfolioValuesChartPreview(scenarioId) {
        return apiClient.get(`/session/preview/charts/portfolio-values?scenario_id=${scenarioId}`)
    }

    getPortfolioPerformanceChartPreview(scenarioId) {
        return apiClient.get(`/session/preview/charts/portfolio-performance?scenario_id=${scenarioId}`)
    }
}

export default new apiService()