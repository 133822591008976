export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST"
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS"
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE"

export const ADD_PERSON_REQUEST = "ADD_PERSON_REQUEST"
export const ADD_PERSON_SUCCESS = "ADD_PERSON_SUCCESS"
export const ADD_PERSON_FAILURE = "ADD_PERSON_FAILURE"

export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"