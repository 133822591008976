import React, { useEffect, useState } from 'react'
import '../App.scss'
import './Pages.scss'
import { Button, Row, Col, Table, Checkbox } from 'antd'
import { connect } from 'react-redux'
import { getSessionRequest, nextStage, prevStage, setSelectedMenuItem, currentStage, nextStageSessionRequest, startSessionRequest, pauseSessionRequest, stopSessionRequest, getTeamsSessionRequest, updateBenchmarkLeverageRequest, updateAllocationsTableRequest, getTrainerGamesRequest } from '../redux/trainer/trainer.actions'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import Layout, { Content } from 'antd/lib/layout/layout'
import SideBarGame from '../components/SideBarGame/SideBarGame'
import MacroBackground from '../components/stages/MacroBackground'
import MacroReview from '../components/stages/MacroReview'
import BenchmarkAndLeverage from '../components/stages/BenchmarkAndLeverage'
import Description from '../components/stages/Description'
import InstrumentsReturns from '../components/stages/InstrumentsReturn'
import PortfoliosPerformance from '../components/stages/PortfoliosPerformance'
import PortfoliosAllocation from '../components/stages/PortfoliosAllocation'
import PortfoliosValues from '../components/stages/PortfoliosValues'
import PortfoliosCompare from '../components/stages/PortfoliosCompare'
import WelcomeScreen from '../components/stages/WelcomeScreen'
import StageModal from '../components/StageModal/StageModal'
import Final from '../components/stages/Final'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import DailyValues from '../components/finalStages/DailyValues'
import DailyReturns from '../components/finalStages/DailyReturns'
import OverallReturn from '../components/finalStages/OverallReturn'
import AnnualPerformance from '../components/finalStages/AnnualPerformance'
import SharpeRatioCompare from '../components/finalStages/SharpeRatioCompare'
import SharpeRatioCompute from '../components/finalStages/SharpeRatioCompute'
import { useStopwatch } from 'react-timer-hook'

const TrainerGame = ({ getSessionRequest, getSessionResponse, gameStatus, playingTime, nextStage, prevStage, selectedMenuItem, currentStage, nextMenuItem, setSelectedMenuItem, nextMenuStage, currentMenuStage, nextStageSessionRequest, startSessionRequest, statusStartSession, pauseSessionRequest, stopSessionRequest, getTeamsSessionRequest, responseGetTeamsSession, currentPeriod, clickedElemHeader, updateBenchmarkLeverageRequest, updateBenchmarkLeverage, statusUser, updateAllocationsTableRequest, updateAllocationTable, changeStatusUser, getTrainerGamesRequest, pageSize }) => {

    const idGame = localStorage.getItem("game_id")

    // get session
    useEffect(() => {
        getSessionRequest(idGame)
    }, [idGame])

    // WEB SOCKETS
    var client
    useEffect(() => {
        client = new W3CWebSocket(`wss://api.asset-mgmt.traderion.com/ws/session/${idGame}?token=${localStorage.getItem('access_token')}`)
        var eventType
        var messageType

        client.onopen = () => {
            console.log('Game WebSocket Client Connected ');
        }

        client.onmessage = (message) => {
            eventType = JSON.parse(message.data).event_type
            messageType = JSON.parse(message.data).message.message_type
            if (eventType === "TEAM_TO_ALL" && messageType === "BENCHMARK_LEVERAGE_CHANGE") {
                updateBenchmarkLeverageRequest()
            } else if (eventType === "TEAM_TO_ALL" && messageType === "BENCHMARK_ADDED") {
                updateAllocationsTableRequest()
            }
        }

        client.onclose = function () {
            console.log('Game Connection Closed.');
        }
    }, [localStorage.getItem('access_token')])

    // close connection when change page
    useEffect(() => {
        if (clickedElemHeader && client)
            client.close()
    }, [clickedElemHeader])

    const period = ["Macro:Background", "Portfolios: Allocations", "Portfolios: Compare", "Macro: Review", "Instruments: Returns", "Portfolios: Values", "Portfolios: Performance"]
    var noOfPeriods = null
    var periodsScenario = []
    var instrumentsScenario = []
    var description = null
    if (getSessionResponse) {
        periodsScenario = getSessionResponse.scenario.periods
        instrumentsScenario = getSessionResponse.scenario.instrument_information
        noOfPeriods = getSessionResponse.scenario.periods.length
        description = getSessionResponse.scenario.description
    }

    // show elements/pages by item key
    const componentsSwitch = (key) => {
        if (key === '0') {
            return <WelcomeScreen />
        } else if (key === '1') {
            return <Description description={description} />
        } else if (key === '2') {
            return <BenchmarkAndLeverage instrumentInformation={instrumentsScenario} />
        } else if (key === 'final_0') {
            return <DailyValues />
        } else if (key === 'final_1') {
            return <DailyReturns />
        } else if (key === 'final_2') {
            return <OverallReturn />
        } else if (key === 'final_3') {
            return <AnnualPerformance />
        } else if (key === 'final_4') {
            return <SharpeRatioCompute />
        } else if (key === 'final_5') {
            return <SharpeRatioCompare />
        } else {
            for (var i = 1; i <= noOfPeriods; i++) {
                for (var j = 0; j < period.length; j++) {
                    switch (key) {
                        case `0`:
                            return <WelcomeScreen />
                        case `period_${i}${j}`:
                            switch (j) {
                                case 0:
                                    return <MacroBackground periodNumber={i} periods={periodsScenario} />
                                case 1:
                                    return <PortfoliosAllocation periodNumber={i} />
                                case 2:
                                    return <PortfoliosCompare periodNumber={i} />
                                case 3:
                                    return <MacroReview periodNumber={i} periods={periodsScenario} />
                                case 4:
                                    return <InstrumentsReturns periodNumber={i} />
                                case 5:
                                    return <PortfoliosValues periodNumber={i} />
                                case 6:
                                    return <PortfoliosPerformance periodNumber={i} />
                                default:
                                    break;
                            }
                            break;
                        case `empty`:
                            return <Final />
                        default:
                            break;
                    }
                }
            }
        }
    }

    // view stage modal
    const [showModal, setShowModal] = useState(false)
    const [viewItem, setViewItem] = useState(selectedMenuItem)

    // next play
    const nextPlaySession = () => {
        var stage
        var period
        if (selectedMenuItem === "0" || selectedMenuItem === "1" || selectedMenuItem === "2") {
            stage = parseInt(selectedMenuItem)
            period = 0
        } else if (selectedMenuItem === "final_0" || selectedMenuItem === "final_1" || selectedMenuItem === "final_2" || selectedMenuItem === "final_3" || selectedMenuItem === "final_4" || selectedMenuItem === "final_5") {
            stage = parseInt(selectedMenuItem.charAt(selectedMenuItem.length - 1))
            period = -1
        } else {
            stage = parseInt(selectedMenuItem.charAt(selectedMenuItem.length - 1))
            period = parseInt(selectedMenuItem.charAt(selectedMenuItem.length - 2))
        }

        const body = {
            "session_id": parseInt(idGame),
            "current_period": period,
            "current_stage": stage
        }

        nextStageSessionRequest(body)
    }

    // start game
    const startGame = () => {
        start() //start contor
        startSessionRequest(idGame)
        var paramList = {
            "pageNumber": 1,
            "pageSize": pageSize
        }
        getTrainerGamesRequest({ paramList: paramList })
    }

    // pause game
    const pauseGame = () => {
        var body = {
            "session_id": parseInt(idGame)
        }
        pause() //stop contor
        pauseSessionRequest(body)
        var paramList = {
            "pageNumber": 1,
            "pageSize": pageSize
        }
        getTrainerGamesRequest({ paramList: paramList })
    }

    // end game
    const endGame = () => {
        var body = {
            "session_id": parseInt(idGame)
        }
        pause() //stop contor
        stopSessionRequest(body)
        var paramList = {
            "pageNumber": 1,
            "pageSize": pageSize
        }
        getTrainerGamesRequest({ paramList: paramList })
        setTimeout(() => {
            window.location.assign(`/trainer/sessions`)
            if (localStorage.getItem("game_id")) {
                localStorage.removeItem("game_id")
            }
        }, 1000)
    }

    const {
        seconds,
        minutes,
        hours,
        start,
        pause,
        reset
    } = useStopwatch({ autoStart: false })

    const stopwatchOffset = new Date()
    useEffect(() => {
        if (playingTime !== null) {
            var offset = stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + playingTime)
            var autoStartOption
            if (gameStatus) {
                if (gameStatus === "ONGOING") {
                    autoStartOption = true
                } else {
                    autoStartOption = false
                }
            }
            reset(offset, autoStartOption)
        }
    }, [playingTime, getSessionResponse])

    // teams table
    const columns = [
        {
            title: "",
            dataIndex: "btn_status"
        },
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Active",
            dataIndex: "active"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        {
            title: "Leverage",
            dataIndex: "leverage"
        },
        {
            title: "Benchmark",
            dataIndex: "benchmark"
        }
    ]

    useEffect(() => {
        if (currentPeriod) {
            var currPeriod = parseInt(currentPeriod)
            getTeamsSessionRequest({ sessionId: idGame, periodNumber: currPeriod })
        }
    }, [currentPeriod, updateBenchmarkLeverage, statusUser, updateAllocationTable, changeStatusUser])

    const [dataState, setDataState] = useState(null)
    const [totalTeams, setTotalTeams] = useState(null)
    const [activeTeams, setActiveTeams] = useState(null)
    useEffect(() => {
        var data = []
        if (responseGetTeamsSession) {
            setTotalTeams(responseGetTeamsSession.nr_teams)
            setActiveTeams(responseGetTeamsSession.active_teams)
            if (responseGetTeamsSession.content && responseGetTeamsSession.content.length > 0) {
                for (var i = 0; i < responseGetTeamsSession.content.length; i++) {
                    data.push({
                        "btn_status": responseGetTeamsSession.content[i].status === "Offline" ? <span className="offline_dot"></span> : <span className="playing_dot"></span>,
                        "name": responseGetTeamsSession.content[i].name,
                        "active": responseGetTeamsSession.content[i].active_personas + "/" + responseGetTeamsSession.content[i].team_size,
                        "status": <div className="playing_status">{responseGetTeamsSession.content[i].status}</div>,
                        "leverage": responseGetTeamsSession.content[i].leverage,
                        "benchmark": <Checkbox checked={responseGetTeamsSession.content[i].benchmark} />
                    })
                }
            }
        }

        setDataState(data)
    }, [responseGetTeamsSession])

    return (
        <Layout>
            <StageModal showModal={showModal} setShowModal={setShowModal} componentsSwitch={componentsSwitch(viewItem)} />
            <SideBarGame selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem} />
            <Content>
                <div className="general_container game_container">
                    <Row>
                        <Col span={12} xxl={12} xl={15} lg={24} md={24} sm={24} xs={24} className="first_col_style">
                            <Row className="row_class">
                                <h5 className="subtitle_game">Game</h5>
                                <Row className="title_row">
                                    <Col className="title" span={2} sm={4} xs={5}>Status</Col>
                                    <Col className="title" span={2} sm={3} xs={5}>Time</Col>
                                    <Col className="title" offset={12} span={8} sm={{ span: 12, offset: 5 }} xs={{ span: 13, offset: 1 }}>Actions</Col>
                                </Row>
                                <Row>
                                    <Col className="session_status" span={2} sm={4} xs={5}>{((statusStartSession && statusStartSession === 200 && gameStatus && gameStatus === "ONGOING")) ? "Playing" : ((gameStatus && gameStatus === "SCHEDULED") || (gameStatus && gameStatus === "PAUSED")) ? "Paused" : (gameStatus && gameStatus === "FINISHED") ? "Completed" : "Playing"}</Col>
                                    <Col span={2} sm={3} xs={5}>
                                        <div>
                                            <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                                        </div>
                                    </Col>
                                    <Col offset={12} span={8} sm={{ span: 12, offset: 5 }} xs={{ span: 13, offset: 1 }}>
                                        {
                                            ((statusStartSession && statusStartSession === 200 && gameStatus && gameStatus === "ONGOING")) ?
                                                <Button disabled={selectedMenuItem === "empty" ? true : false} className={selectedMenuItem === "empty" ? "first_btn_disabled" : "first_btn_pause"} onClick={() => pauseGame()}>Pause</Button>
                                                :
                                                ((gameStatus && gameStatus === "SCHEDULED") || (gameStatus && gameStatus === "PAUSED")) ?
                                                    <Button className="first_btn_start" onClick={() => startGame()}>Start</Button>
                                                    :
                                                    <Button disabled={selectedMenuItem === "empty" ? true : false} className={selectedMenuItem === "empty" ? "first_btn_disabled" : "first_btn_pause"} onClick={() => pauseGame()}>Pause</Button>

                                        }
                                        {
                                            <Button className="end_btn" onClick={() => endGame()}>
                                                {selectedMenuItem === "empty" ? "End" : "End early"}
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Row>

                            <Row className="row_class_game">
                                <Col className="col_game_stage" span={12} sm={12} xs={24}>
                                    <h5 className="subtitle_game">Currently playing</h5>
                                    <h5 className="game_stage">{currentMenuStage}</h5>
                                    <div className="page_class" onClick={() => {
                                        setShowModal(true)
                                        setViewItem(selectedMenuItem)
                                    }
                                    }>
                                        {componentsSwitch(selectedMenuItem)}
                                    </div>
                                </Col>
                                <Col className="col_game_stage" span={12} sm={12} xs={24}>
                                    <h5 className="subtitle_game">Play next</h5>
                                    <h5 className="game_stage">{nextMenuStage}</h5>
                                    <div className={selectedMenuItem === "empty" ? "hide_class" : "page_class"} onClick={() => {
                                        setShowModal(true)
                                        setViewItem(nextMenuItem)
                                    }
                                    }>
                                        {componentsSwitch(nextMenuItem)}
                                    </div>
                                </Col>
                                <Row className="bottom_row">
                                    <Button disabled={selectedMenuItem === "0" ? true : false} className="btn" onClick={() => prevStage()} icon={<ArrowLeftOutlined />}></Button>
                                    <Button className="btn" onClick={() => currentStage()}>Current</Button>
                                    <Button disabled={selectedMenuItem === "empty" ? true : false} className="btn" onClick={() => nextStage()} icon={<ArrowRightOutlined />}></Button>
                                    <Button disabled={selectedMenuItem === "empty" ? true : false} className={selectedMenuItem === "empty" ? "disabled_btn" : "play_btn"} onClick={() => nextPlaySession()}>Play next</Button>
                                </Row>
                            </Row>
                        </Col>
                        <Col span={11} xxl={11} xl={8} lg={24} md={24} sm={24} xs={24} className="second_col_style">
                            {
                                (responseGetTeamsSession && dataState) ?
                                    <>
                                        {
                                            (activeTeams && totalTeams) ?
                                                <h2 className="subtitle_game">Teams ({activeTeams}/{totalTeams})</h2>
                                                :
                                                <></>
                                        }
                                        <Table className="teams_table" columns={columns} dataSource={dataState} pagination={false} scroll={{ x: 'fit-content' }} />
                                    </>
                                    :
                                    <></>
                            }
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout >
    )
}

const mapStateToProps = state => ({
    getSessionResponse: state.trainer.getSessionResponse,
    playingTime: state.trainer.playingTime,
    selectedMenuItem: state.trainer.selectedMenuItem,
    nextMenuItem: state.trainer.nextMenuItem,
    currentMenuStage: state.trainer.currentMenuStage,
    nextMenuStage: state.trainer.nextMenuStage,
    statusStartSession: state.trainer.statusStartSession,
    gameStatus: state.trainer.gameStatus,
    responseGetTeamsSession: state.trainer.responseGetTeamsSession,
    currentPeriod: state.trainer.currentPeriod,
    clickedElemHeader: state.trainer.clickedElemHeader,
    updateBenchmarkLeverage: state.trainer.updateBenchmarkLeverage,
    statusUser: state.trainer.statusUser,
    updateAllocationTable: state.trainer.updateAllocationTable,
    changeStatusUser: state.trainer.changeStatusUser,
    pageSize: state.trainer.pageSize
})

const mapDispatchToProps = dispatch => ({
    getSessionRequest: payload => dispatch(getSessionRequest(payload)),
    nextStage: () => dispatch(nextStage()),
    prevStage: () => dispatch(prevStage()),
    setSelectedMenuItem: payload => dispatch(setSelectedMenuItem(payload)),
    currentStage: () => dispatch(currentStage()),
    nextStageSessionRequest: payload => dispatch(nextStageSessionRequest(payload)),
    startSessionRequest: payload => dispatch(startSessionRequest(payload)),
    pauseSessionRequest: payload => dispatch(pauseSessionRequest(payload)),
    stopSessionRequest: payload => dispatch(stopSessionRequest(payload)),
    getTeamsSessionRequest: payload => dispatch(getTeamsSessionRequest(payload)),
    updateBenchmarkLeverageRequest: payload => dispatch(updateBenchmarkLeverageRequest(payload)),
    updateAllocationsTableRequest: payload => dispatch(updateAllocationsTableRequest(payload)),
    getTrainerGamesRequest: payload => dispatch(getTrainerGamesRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TrainerGame)
