import React, { useEffect, useState } from 'react'
import '../App.scss'
import './Pages.scss'
import { connect } from 'react-redux'
import { getTrainerGamesRequest } from '../redux/trainer/trainer.actions'
import { Table, Button, Pagination } from 'antd'
import Layout, { Content } from 'antd/lib/layout/layout'
import SideBar from '../components/SideBar/SideBar'
import SearchBar from '../components/SearchBar/SearchBar'
import { Option } from 'antd/lib/mentions'
import { LoginOutlined, FileTextOutlined } from '@ant-design/icons'
import Spinner from '../components/Spinner/Spinner'
import axios from 'axios'

const TrainerSessions = ({ getTrainerGamesRequest, count, results, pageSize, numberOfPages, isLoading }) => {

    // get data for table +  filters states
    const [currentPage, setCurrentPage] = useState(1)
    const [query, setQuery] = useState(null)
    const [periodType, setPeriodType] = useState(null)
    const [profile, setProfile] = useState(null)
    const [nrPeriods, setNrPeriods] = useState(null)
    const [idList, setIdList] = useState(null)
    const [change, setChange] = useState(false)

    var paramList = {
        "pageNumber": currentPage,
        "pageSize": pageSize,
        "query": query,
        "profile": profile,
        "period_type": periodType,
        "nr_periods": nrPeriods,
    }

    const [paramState, setParamState] = useState(paramList)
    const [instrState, setInstrState] = useState({})
    var finalInstrumentsList = {}
    var finalParamList = {}

    useEffect(() => {
        getTrainerGamesRequest({ paramList: paramState })
    }, [currentPage, query, periodType, nrPeriods, profile, change])

    // redirect to game
    const startGame = (id) => {
        localStorage.setItem("game_id", id)
        window.location.assign(`/trainer/session/${id}`)
    }

    // get report
    const getReport = (id) => {
        localStorage.setItem("game_id", id)
        window.location.assign(`/trainer/report/${id}`)
    }

    // get pdf report
    const getPdfReport = async (id) => {
        let token = localStorage.getItem('access_token')
        try {
            await axios
                .get(`https://api.asset-mgmt.traderion.com/api/v1/session/report/${id}`, {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const file = new Blob([response.data], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);
                    const pdfWindow = window.open();
                    pdfWindow.location.href = fileURL;
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            return { error };
        }
    }

    // table columns and data
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Profile",
            dataIndex: "profile",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Leverage",
            dataIndex: "leverage"
        },
        {
            title: "#",
            dataIndex: "#"
        },
        {
            title: "Period",
            dataIndex: "period"
        },
        {
            title: "Instruments",
            dataIndex: "instruments"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        {
            title: "Date",
            dataIndex: "date"
        },
        {
            title: "Time",
            dataIndex: "time",
        },
        {
            title: "Class",
            dataIndex: "class",
            sorter: (a, b) => a.class.localeCompare(b.class),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: '',
            key: 'key',
            dataIndex: 'key',
            render: (text, record) => (
                <Button className={record.status.props.children === "Completed" ? "completed_btn" : "join_btn"} onClick={() => { record.status.props.children === "Completed" ? getReport(record.key) : startGame(record.key) }} icon={record.status.props.children !== "Completed" ? <LoginOutlined /> : <></>}>
                    {
                        (record.status.props.children === "Playing" || record.status.props.children === "Scheduled" || record.status.props.children === "Paused") ? "Join"
                            :
                            (record.status.props.children === "Completed") ? "Report"
                                :
                                ""
                    }
                </Button>
            ),
        },
        {
            title: '',
            key: 'report',
            dataIndex: 'report',
            render: (text, record) => (
                (record.status.props.children === "Completed") &&
                <Button className="completed_btn icon_btn" icon={<FileTextOutlined />} onClick={() => getPdfReport(record.key)}></Button>
            ),
        },
    ];

    var data = []
    var profiles = []
    var periodTypes = []
    var noOfPeriods = []
    var instrumentsList = []

    if (results && results.content && results.content.length > 0) {
        profiles = results.distinct_profiles
        periodTypes = results.distinct_period_types
        noOfPeriods = results.distinct_nr_periods
        instrumentsList = results.distinct_instruments
        data = []
        for (var i = 0; i < results.content.length; i++) {
            var currentPeriod = results.content[i].current_period + "/" + results.content[i].number_of_periods

            var time
            if (results.content[i].playing_time !== null) {
                time = new Date(results.content[i].playing_time * 1000).toISOString().substr(11, 8)
            } else {
                time = 0
            }

            data.push({
                "key": results.content[i].id,
                "name": results.content[i].scenario.scenario_name,
                "profile": results.content[i].scenario.profile,
                "leverage": results.content[i].max_leverage,
                "#": currentPeriod,
                "period": results.content[i].scenario.period_type,
                "instruments": results.content[i].scenario.instruments.map(i => { return <Button className="instrument_btn">{i.name}</Button> }),
                "status": results.content[i].state === "ONGOING" ? <span className="playing_status">Playing</span> : results.content[i].state === "SCHEDULED" ? <span className="scheduled_status">Scheduled</span> : results.content[i].state === "PAUSED" ? <span className="paused_status">Paused</span> : results.content[i].state === "FINISHED" ? <span className="complete_status">Completed</span> : "",
                "date": results.content[i].creation_date,
                "time": time,
                "class": results.content[i].team_class.name,
            })
        }
    }

    // on change page
    const onChangePage = (page) => {
        paramList["pageNumber"] = page
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setCurrentPage(page)
    }

    // search
    const onSearch = (value) => {
        paramList["query"] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setQuery(value)
        setCurrentPage(1)
    }

    //profile select
    const profileList = []
    profiles.map((p) => {
        profileList.push(<Option key={p}>{p}</Option>)
        return profileList
    })

    const onChangeProfile = (value) => {
        paramList["profile"] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setProfile(value)
        setCurrentPage(1)
    }

    // period select
    const periodList = []
    periodTypes.map((p) => {
        periodList.push(<Option key={p}>{p}</Option>)
        return periodList
    })

    const onChangePeriod = (value) => {
        paramList["period_type"] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setPeriodType(value)
        setCurrentPage(1)
    }

    // number of periods select
    const noOfPeriodsList = []
    noOfPeriods.map((p) => {
        noOfPeriodsList.push(<Option key={p}>{p}</Option>)
        return noOfPeriodsList
    })

    const onChangeNoOfPeriods = (value) => {
        paramList['nr_periods'] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setNrPeriods(value)
        setCurrentPage(1)
    }

    // instruments select
    const selectList = []
    instrumentsList.map((i) => {
        selectList.push(<Option key={i.id}>{i.name}</Option>)
        return selectList
    })

    const handleChange = (value) => {
        setIdList(value)
    }

    useEffect(() => {
        if (idList) {
            idList.map((i, index) => {
                finalInstrumentsList["instrument_" + (index + 1)] = parseInt(i)
                return finalInstrumentsList
            })
            paramList["pageNumber"] = 1
            finalParamList = Object.assign(paramList, finalInstrumentsList)
            setInstrState(finalInstrumentsList)
            setParamState(finalParamList)
            setCurrentPage(1)
            setChange(!change)
        }
    }, [idList])

    if (results)
        return (
            <Layout>
                <SideBar />
                <Content>
                    <div className={"general_container trainer_team_sessions"}>
                        <SearchBar selectList={selectList} visible={true} onSearch={onSearch} handleChange={handleChange} profileList={profileList} onChangeProfile={onChangeProfile} periodList={periodList} onChangePeriod={onChangePeriod} noOfPeriodsList={noOfPeriodsList} onChangeNoOfPeriods={onChangeNoOfPeriods} />
                        <Table columns={columns} dataSource={data} pagination={false} locale={{ emptyText: (isLoading ? <Spinner /> : <span>There are no sessions matching your current search criteria. Please consider different keywords.</span>) }} scroll={{ x: 'fit-content' }} />
                        {
                            numberOfPages > 1 &&
                            <Pagination
                                defaultPageSize={pageSize}
                                total={count}
                                onChange={(page) => onChangePage(page)}
                                showSizeChanger={false}
                            />
                        }
                    </div>
                </Content>
            </Layout>
        )
    return null
}

const mapStateToProps = state => ({
    count: state.trainer.count,
    results: state.trainer.results,
    pageSize: state.trainer.pageSize,
    numberOfPages: state.trainer.numberOfPages,
    isOpenGameModal: state.trainer.isOpenGameModal,
    isLoading: state.trainer.isLoading
})

const mapDispatchToProps = dispatch => ({
    getTrainerGamesRequest: payload => dispatch(getTrainerGamesRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TrainerSessions)
