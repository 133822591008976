import React, { useEffect, useState } from 'react'
import '../App.scss'
import './Pages.scss'
import Layout, { Content } from 'antd/lib/layout/layout'
import SideBarGame from '../components/SideBarGame/SideBarGame'
import { Table } from 'antd'
import { connect } from 'react-redux'
import { getBenchmarkPortfolioRequest, setSelectedMenuItem, updateBenchmarkLeverageRequest } from '../redux/trainer/trainer.actions'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

const BenchmarkPortfolio = ({ getBenchmarkPortfolioRequest, contentBenchmarkPortfolio, benchmarkTypePortfolioTable, maxLeveragePortfolioTable, setSelectedMenuItem, selectedMenuItem, clickedElemHeader, updateBenchmarkLeverageRequest, updateBenchmarkLeverage }) => {
    const idGame = localStorage.getItem("game_id")

    // WEB SOCKETS
    var client
    useEffect(() => {
        client = new W3CWebSocket(`wss://api.asset-mgmt.traderion.com/ws/session/${idGame}?token=${localStorage.getItem('access_token')}`)
        var eventType = null
        var messageType = null

        client.onopen = () => {
            console.log('Game WebSocket Client Connected ');
        }

        client.onmessage = (message) => {
            eventType = JSON.parse(message.data).event_type
            messageType = JSON.parse(message.data).message.message_type
            if (eventType === "TEAM_TO_ALL" && messageType === "BENCHMARK_LEVERAGE_CHANGE") {
                updateBenchmarkLeverageRequest()
            }
        }

        client.onclose = function () {
            console.log('Game Connection Closed.');
        }
    }, [localStorage.getItem('access_token')])

    // close connection when change page
    useEffect(() => {
        if (clickedElemHeader && client)
            client.close()
    }, [clickedElemHeader])

    // get benchmark
    useEffect(() => {
        if (idGame)
            getBenchmarkPortfolioRequest(idGame)
    }, [updateBenchmarkLeverage, idGame])

    // table
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: 'Settings',
            children: [
                {
                    title: 'Benchmark Type',
                    dataIndex: 'benchmarkType',
                    key: 'benchmarkType',
                },
                {
                    title: 'Max Leverage',
                    dataIndex: 'maxLeverage',
                    key: 'maxLeverage',
                }
            ],
        },
        {
            title: "Leverage",
            dataIndex: "leverage"
        }
    ]

    const [columnsState, setColumnsState] = useState(null)
    const [dataState, setDataState] = useState(null)
    useEffect(() => {
        var data = []
        var auxData = {}
        var auxElem = {}
        if (benchmarkTypePortfolioTable !== null && maxLeveragePortfolioTable !== null && contentBenchmarkPortfolio && contentBenchmarkPortfolio.length > 0) {
            for (var j = 0; j < contentBenchmarkPortfolio[0].benchmarks.length; j++) {
                columns.push({
                    "title": <div className="instr_head">{contentBenchmarkPortfolio[0].benchmarks[j].instrument_name}</div>,
                    "dataIndex": "instrument" + j
                })
            }

            var element = {}
            for (var i = 0; i < contentBenchmarkPortfolio.length; i++) {
                for (var k = 0; k < contentBenchmarkPortfolio[i].benchmarks.length; k++) {
                    element = {}
                    element[`instrument${k}`] = contentBenchmarkPortfolio[i].benchmarks[k].weight !== null ? contentBenchmarkPortfolio[i].benchmarks[k].weight : "0"
                    auxElem = Object.assign(auxElem, element)
                }

                auxData = {
                    "name": contentBenchmarkPortfolio[i].team_name,
                    "leverage": contentBenchmarkPortfolio[i].benchmarks.length === 0 ? "-" : contentBenchmarkPortfolio[i].benchmarks[0].leverage,
                    "benchmarkType": benchmarkTypePortfolioTable,
                    "maxLeverage": maxLeveragePortfolioTable
                }

                let row = Object.assign(auxData, auxElem)
                data.push(row)
            }
        }
        setDataState(data)
        setColumnsState(columns)
    }, [contentBenchmarkPortfolio])

    return (
        <Layout>
            <SideBarGame selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem} />
            <Content>
                <div className="general_container benchmark_portfolio">
                    <Table className="benchmark_table" columns={columnsState} dataSource={dataState} pagination={false} scroll={{ x: 'fit-content' }} />
                </div>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    contentBenchmarkPortfolio: state.trainer.contentBenchmarkPortfolio,
    benchmarkTypePortfolioTable: state.trainer.benchmarkTypePortfolioTable,
    maxLeveragePortfolioTable: state.trainer.maxLeveragePortfolioTable,
    selectedMenuItem: state.trainer.selectedMenuItem,
    clickedElemHeader: state.trainer.clickedElemHeader,
    updateBenchmarkLeverage: state.trainer.updateBenchmarkLeverage
})

const mapDispatchToProps = dispatch => ({
    getBenchmarkPortfolioRequest: payload => dispatch(getBenchmarkPortfolioRequest(payload)),
    setSelectedMenuItem: payload => dispatch(setSelectedMenuItem(payload)),
    updateBenchmarkLeverageRequest: payload => dispatch(updateBenchmarkLeverageRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkPortfolio)
