import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import '../../App.scss'
import './ScenarioStages.scss'
import Arrow from '../Arrow/Arrow'
import { connect } from 'react-redux'
import { getInstrumentsReturnsChartRequest } from '../../redux/trainer/trainer.actions'

const InstrumentsReturns = ({ getInstrumentsReturnsChartRequest, responseGetInstrumentsReturnsChart, periodNumber }) => {

    const idGame = localStorage.getItem("game_id")
    useEffect(() => {
        if (periodNumber)
            getInstrumentsReturnsChartRequest({ sessionId: idGame, periodNumber: periodNumber })
    }, [periodNumber, idGame])

    // options for line chart
    const options = {
        legend: {
            display: false,
        },
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    fontSize: 11,
                }
            }],
            xAxes: [{
                ticks: {
                    callback: function (tick, index, ticks) {
                        if (tick % 10 === 0)
                            return tick.toLocaleString();

                        return null;
                    },
                    fontSize: 11,
                }
            }]
        }
    }

    var lines = []
    var labelsData
    if (responseGetInstrumentsReturnsChart) {
        labelsData = responseGetInstrumentsReturnsChart.labels

        if (responseGetInstrumentsReturnsChart.datasets.length > 0) {
            responseGetInstrumentsReturnsChart.datasets.map((d, index) => {
                var finalData = []
                finalData.push(
                    {
                        label: d.data.name,
                        fill: false,
                        backgroundColor: d.data.color,
                        borderColor: d.data.color,
                        borderWidth: 2,
                        data: d.data.points,
                        hidden: (!d.data.visible),
                        lineTension: 0,
                    }
                )

                // data for line chart
                var currState = {}
                currState = {
                    labels: labelsData,
                    datasets: finalData
                }
                lines.push(<>
                    <div className={d.data.performance >= 0 ? "row_arrow margin_top_class" : "row_arrow margin_bottom_class"}>
                        <h5 className="title_class">{d.data.name}</h5>
                        <Arrow dataPerformance={d.data.performance} />
                    </div>
                    <Line data={currState} options={options}></Line>
                </>
                )
                return d
            })
        }
    }

    return (
        <div className="general_container instruments_return">
            <h4 className="subtitle-stage">Instruments returns</h4>
            {lines}
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetInstrumentsReturnsChart: state.trainer.responseGetInstrumentsReturnsChart
})

const mapDispatchToProps = dispatch => ({
    getInstrumentsReturnsChartRequest: payload => dispatch(getInstrumentsReturnsChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentsReturns)