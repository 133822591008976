import React, { useEffect, useState } from 'react'
import './TeamPortfolio.scss'
import { Row, Col, Input, Progress, Button } from 'antd'
import { connect } from 'react-redux'
import { saveIndividualBenchmarkRequest, saveBenchmarkRequest } from '../../redux/team/team.actions'
import Spinner from '../Spinner/Spinner'

const TeamPortfolio = ({ responseBenchmark, saveIndividualBenchmarkRequest, messageSaveIndividualBenchmark, saveBenchmarkRequest, messageSaveBenchmark, session, globalCurrentPeriod, globalCurrentStage, progressBar, isLoadingSubmit, report }) => {

    // set benchmark type + leverage
    const [benchmarkType, setBenchmarkType] = useState(null)
    const [leverage, setLeverage] = useState(null)
    const [maxLeverage, setMaxLeverage] = useState(null)

    useEffect(() => {
        if (session) {
            setBenchmarkType(session.benchmark_type)
        }
    }, [session])

    useEffect(() => {
        if (responseBenchmark) {
            setMaxLeverage(responseBenchmark.max_leverage)
            if (responseBenchmark.instruments.length > 0)
                setLeverage(responseBenchmark.instruments[0].leverage)
        }
    }, [responseBenchmark, session])

    // build table row by row
    var header = []
    var data = []
    var secondData = []
    const [headerState, setHeaderState] = useState(null)
    const [dataState, setDataState] = useState(null)
    const [secondDataState, setSecondDataState] = useState(null)
    const [visibleBtn, setVisibleBtn] = useState(false)
    const [showLeverage, setShowLeverage] = useState(false)
    const [editLeverage, setEditLeverage] = useState(false)

    useEffect(() => {
        setVisibleBtn(false)
        setEditLeverage(false)

        if (responseBenchmark) {
            if (responseBenchmark.instruments.length > 0) {
                if (responseBenchmark.instruments[0].periods.length > 0) {
                    for (var i = 0; i < responseBenchmark.instruments[0].periods.length; i++) {
                        header.push("Period " + responseBenchmark.instruments[0].periods[i].period_number)
                    }
                }
            }
            setHeaderState(header)

            if (responseBenchmark.instruments.length > 0) {
                var row = []
                for (var c = 0; c < responseBenchmark.instruments.length; c++) {
                    row = []
                    row.push(responseBenchmark.instruments[c].instrument_name)
                    row.push(responseBenchmark.instruments[c].benchmark)
                    for (var j = 0; j < responseBenchmark.instruments[c].periods.length; j++) {
                        row.push(responseBenchmark.instruments[c].periods[j].weight)
                    }
                    data.push(row)
                }
            }

            if (responseBenchmark.final_value) {
                var rowFinalValue = []
                rowFinalValue.push("Value (M)")
                rowFinalValue.push(responseBenchmark.final_value.benchmark)
                for (var k = 0; k < responseBenchmark.final_value.periods.length; k++) {
                    rowFinalValue.push(responseBenchmark.final_value.periods[k].weight)
                }
                secondData.push(rowFinalValue)
            }

            if (responseBenchmark.return) {
                var rowReturn = []
                rowReturn.push("Return (%)")
                rowReturn.push(responseBenchmark.return.benchmark)
                for (var l = 0; l < responseBenchmark.return.periods.length; l++) {
                    if (responseBenchmark.return.periods[l].weight !== 0) {
                        var arr = (responseBenchmark.return.periods[l].weight).split(/%|v/)
                        var first = parseFloat(arr[0])
                        var second = parseFloat(arr[2])
                        rowReturn.push(<div><span className={first < second ? "red_class" : first > second ? "green_class" : null}>{first}%</span> v <span>{second}%</span></div>)
                    } else {
                        rowReturn.push(responseBenchmark.return.periods[l].weight)
                    }
                }
                secondData.push(rowReturn)
            }

            if (responseBenchmark.rank) {
                var rowRank = []
                rowRank.push("Rank")
                rowRank.push(responseBenchmark.rank.benchmark)
                for (var m = 0; m < responseBenchmark.rank.periods.length; m++) {
                    rowRank.push(responseBenchmark.rank.periods[m].weight)
                }
                secondData.push(rowRank)
            }
        }

        setDataState(data)
        setSecondDataState(secondData)

        // show/hide submit button
        if (dataState && dataState.length > 0 && globalCurrentStage !== null && globalCurrentPeriod !== null && benchmarkType !== null) {
            dataState.map((d) => {
                d.map((r, indexD) => {
                    if ((globalCurrentPeriod === 0 && globalCurrentStage === 2 && indexD === 1 && (benchmarkType === "INDIVIDUAL" || maxLeverage > 0)) || (globalCurrentPeriod >= 1 && globalCurrentStage === 1 && (indexD === (globalCurrentStage + 1)))) {
                        setVisibleBtn(true)
                    }
                    if ((globalCurrentPeriod === 0 && globalCurrentStage === 2 && indexD === 1 && maxLeverage > 0)) {
                        setEditLeverage(true)
                    }
                    if ((maxLeverage > 0 && (globalCurrentPeriod > 0 || globalCurrentPeriod === -1)) || (globalCurrentPeriod === 0 && globalCurrentStage === 2 && indexD === 1 && maxLeverage > 0)) {
                        setShowLeverage(true)
                    }
                    return r
                })
                return d
            })
        }
    }, [globalCurrentStage, globalCurrentPeriod, responseBenchmark])

    // change input
    const handleChange = (e, index, indexD) => {
        var val = e.target.value
        var newData = dataState.concat([])
        newData[index][indexD] = val
        setDataState(newData)
    }

    const handleChangeLeverage = (e) => {
        setLeverage(parseInt(e.target.value))
    }

    // build body for save individual benchmark
    var benchmarks = []
    if (dataState && dataState.length > 0) {
        dataState.map((d) => {
            if (responseBenchmark && responseBenchmark.instruments.length > 0) {
                for (var i = 0; i < responseBenchmark.instruments.length; i++) {
                    if (responseBenchmark.instruments[i].instrument_name === d[0]) {
                        benchmarks.push({
                            "instrument_id": responseBenchmark.instruments[i].instrument_id,
                            "weight": parseInt(d[1])
                        })
                    }
                }
            }
            return d
        })
    }

    var body = {
        "benchmarks": benchmarks,
        "leverage": maxLeverage > 0 ? leverage : 0
    }

    // body portfolio allocation
    var benchmarksAllocation = []
    if (dataState && dataState.length > 0 && globalCurrentStage !== null && globalCurrentPeriod !== null && benchmarkType !== null) {
        dataState.map((d) => {
            d.map((r, indexD) => {
                if (globalCurrentPeriod >= 1 && globalCurrentStage === 1 && (indexD === (globalCurrentPeriod + 1))) {
                    if (responseBenchmark && responseBenchmark.instruments.length > 0)
                        for (var i = 0; i < responseBenchmark.instruments.length; i++)
                            if (responseBenchmark.instruments[i].instrument_name === d[0]) {
                                benchmarksAllocation.push({
                                    "instrument_id": responseBenchmark.instruments[i].instrument_id,
                                    "weight": parseInt(d[indexD])
                                })
                            }
                }
                return r
            })
            return d
        })
    }

    var bodyAllocation = {
        "benchmarks": benchmarksAllocation
    }

    // submit individual benchmark vs submit benchmark
    const idSession = localStorage.getItem("game_id")
    const submitIndividualBenchmark = () => {
        if (globalCurrentPeriod === 0 && globalCurrentStage === 2)
            saveIndividualBenchmarkRequest({ sessionId: idSession, body: body })
        else
            saveBenchmarkRequest({ sessionId: idSession, periodNumber: globalCurrentPeriod, body: bodyAllocation })
    }

    return (
        <div className="team_portfolio">
            <Row style={{ flexWrap: "nowrap" }}>
                <Col className="header_style">Instruments</Col>
                <Col className="header_style">
                    Weights
                    <Row style={{ flexWrap: "nowrap" }}>
                        <Col className="period_class">Benchmark</Col>
                        {
                            (headerState && headerState.length > 0) &&
                            headerState.map((h, index) => {
                                return <Col key={index} className="period_class">{h}</Col>
                            })
                        }
                    </Row>
                </Col>
            </Row>
            {
                (dataState && dataState.length > 0 && globalCurrentStage !== null && globalCurrentPeriod !== null && benchmarkType !== null) &&
                dataState.map((d, index) => {
                    return <Row className="row_data_class" index={index}>
                        {
                            d.map((r, indexD) => {
                                return <Col className="col_data_class">
                                    {
                                        (indexD === 0) ?
                                            <span className="instrument_name">{r}</span>
                                            :
                                            (
                                                ((globalCurrentPeriod === 0 && globalCurrentStage === 2 && indexD === 1 && benchmarkType === "INDIVIDUAL" && report === false) || (report === false && globalCurrentPeriod >= 1 && globalCurrentStage === 1 && (indexD === (globalCurrentPeriod + 1)))) ?
                                                    <Input
                                                        index={indexD}
                                                        key={indexD}
                                                        className="input_class editable_input"
                                                        type="number"
                                                        min="-100"
                                                        max="100"
                                                        value={(d[indexD])}
                                                        onChange={(e) => handleChange(e, index, indexD)}
                                                    />
                                                    :
                                                    <Input
                                                        index={indexD}
                                                        key={indexD}
                                                        className="input_class"
                                                        type="number"
                                                        value={r}
                                                        readOnly
                                                    />
                                            )
                                    }
                                </Col>
                            })
                        }
                    </Row>
                })
            }
            {
                (showLeverage && report === false) &&

                <Row className="leverage_row">
                    <div className="leverage_label">Leverage:</div>
                    {
                        editLeverage ?
                            <Input
                                className="leverage_input editable_input"
                                type="number"
                                min="1"
                                value={leverage}
                                onChange={(e) => handleChangeLeverage(e)}
                            />
                            :
                            <Input
                                className="leverage_input"
                                type="number"
                                value={leverage}
                                readOnly
                            />
                    }

                </Row>
            }
            {
                messageSaveBenchmark &&
                <Row className="msg_row">
                    <div className={messageSaveBenchmark === "Benchmark was successfully saved!" ? "success_msg" : "fail_msg"}>{messageSaveBenchmark}</div>
                </Row>
            }
            {
                messageSaveIndividualBenchmark &&
                <Row className="msg_row">
                    <div className={messageSaveIndividualBenchmark === "Individual benchmark was successfully saved!" ? "success_msg" : "fail_msg"}>{messageSaveIndividualBenchmark}</div>
                </Row>
            }
            {
                (visibleBtn && report === false) &&
                <>
                    <Row className="btn_row" >
                        {
                            isLoadingSubmit ? <Spinner /> : <></>
                        }
                    </Row>
                    <Row className="btn_row">
                        <Button disabled={isLoadingSubmit ? true : false} onClick={() => submitIndividualBenchmark()}>Submit</Button>
                    </Row>

                    <Row>
                        {
                            progressBar !== null &&
                            <Progress percent={progressBar} />
                        }
                    </Row>
                </>
            }
            <Row className="last_row">
                {
                    (secondDataState && secondDataState.length > 0) &&
                    secondDataState.map((d, index) => {
                        return <Row className="row_data_class" index={index}>
                            {
                                d.map((r, index) => {
                                    return <Col className="col_data_class">{r}</Col>
                                })
                            }
                        </Row>
                    })
                }
            </Row>
        </div>
    )
}

const mapStateToProps = state => ({
    messageSaveIndividualBenchmark: state.team.messageSaveIndividualBenchmark,
    messageSaveBenchmark: state.team.messageSaveBenchmark,
    globalCurrentPeriod: state.team.globalCurrentPeriod,
    globalCurrentStage: state.team.globalCurrentStage,
    progressBar: state.team.progressBar,
    isLoadingSubmit: state.team.isLoadingSubmit
})

const mapDispatchToProps = dispatch => ({
    saveIndividualBenchmarkRequest: payload => dispatch(saveIndividualBenchmarkRequest(payload)),
    saveBenchmarkRequest: payload => dispatch(saveBenchmarkRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamPortfolio)