import React, { useEffect, useState } from 'react'
import '../../App.scss'
import './ScenarioStages.scss'

const Description = ({ description }) => {
    const [descriptionState, setDescriptionState] = useState(null)

    useEffect(() => {
        if (description)
            setDescriptionState(description)
    }, [description])

    return (
        <>
            {
                descriptionState ?
                    <div className="general_container stage_container">

                        <div>
                            <h4 className="subtitle-stage">Description</h4>
                            <div dangerouslySetInnerHTML={{ __html: descriptionState }}></div>
                        </div>
                    </div>
                    :
                    <div className="general_container stage_container_no_content">
                        <h4 className="subtitle-stage-msg">This scenario has no description</h4>
                    </div>
            }
        </>
    )
}

export default Description