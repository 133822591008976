import React from 'react'
import './FinalStage.scss'

const EndGame = () => {
    return (
        <div className="end_screen">
            <div className="text_class">
                <h2 className="blue_text">Congratulations!</h2>
                <h3>You have completed the session. </h3>
                <h3>You will be redirected to the main dashboard soon.</h3>
            </div>
        </div>
    )
}

export default EndGame