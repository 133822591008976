import React from 'react'
import LoginForm from '../components/LoginForm/LoginForm'
import './Pages.scss'
import '../App.scss'

const Login = () => {
    return (
        <div className="general_container">
            <div className="login_div">
                <LoginForm />
            </div>
        </div>
    )
}

export default Login