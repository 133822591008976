import React from 'react'
import '../../App.scss'
import './ScenarioStages.scss'
import { Collapse, Row, Col } from 'antd'
const { Panel } = Collapse

const BenchmarkAndLeverage = ({ instrumentInformation }) => {

    return (
        <div className="general_container benchmark_stage_container">
            <h4 className="subtitle-stage">Benchmark Portfolio</h4>
            {
                (instrumentInformation.length > 0) &&
                <Collapse defaultActiveKey={['0']} expandIconPosition="right">
                    {
                        instrumentInformation.map((i, index) => {
                            return <Panel
                                key={index}
                                header={[
                                    <Row>
                                        <Col className="collapse_subtitle" span={12}>{i.instrument.name}</Col>
                                        <Col className="collapse_subtitle" span={12}>{i.benchmark_weight}</Col>
                                    </Row>
                                ]}
                            >
                                <>
                                    <Row className="info_row">
                                        <Col className="info-subtitle" span={12}>Asset class</Col>
                                        <Col span={12}>{i.instrument.asset_class.name}</Col>
                                    </Row>
                                    <Row className="info_row">
                                        <Col className="info-subtitle" span={12}>Subasset class</Col>
                                        <Col span={12}>{i.instrument.sub_asset_class.name}</Col>
                                    </Row>
                                    <Row className="info_row">
                                        <Col className="info-subtitle" span={12}>Geography</Col>
                                        <Col span={12}>{i.instrument.geography}</Col>
                                    </Row>
                                    <Row className="info_row">
                                        <Col className="info-subtitle" span={12}>Description</Col>
                                        <Col span={12}>{i.instrument.description}</Col>
                                    </Row>
                                </>
                            </Panel>
                        })
                    }
                </Collapse>
            }

        </div>
    )
}

export default BenchmarkAndLeverage