import React, { useEffect } from 'react'
import 'antd/dist/antd.css'
import './App.scss'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import HeaderComponent from './components/Header/Header'
import Login from './pages/Login'
import TeamSessions from './pages/TeamSessions'
import Page404 from './pages/Page404'
import TrainerSessions from './pages/TrainerSessions'
import TrainerGame from './pages/TrainerGame'
import TeamGame from './pages/TeamGame'
import TrainerClasses from './pages/TrainerClasses'
import TrainerScenarios from './pages/TrainerScenarios'
import TeamClasses from './pages/TeamClasses'
import Allocations from './pages/Allocations'
import BenchmarkPortfolio from './pages/BenchmarkPortfolio'
import { connect } from 'react-redux'
import { setGameStatus, updateActiveUserTrainer } from './redux/trainer/trainer.actions'
import ReportTrainer from './pages/ReportTrainer'
import ReportTeam from './pages/ReportTeam'

function App({ setGameStatus, updateActiveUserTrainer }) {

  useEffect(() => {
    if (window.location.pathname !== '/' && !localStorage.getItem('access_token'))
      window.location.assign(`/`)
  }, [localStorage.getItem('access_token')])

  useEffect(() => {
    var client
    if (localStorage.getItem('access_token')) {
      var persona
      if (localStorage.getItem('username')) {
        persona = localStorage.getItem('username')
      } else {
        persona = "Trainer"
      }
      client = new W3CWebSocket(`wss://api.asset-mgmt.traderion.com/ws/all/?persona=${persona}&token=${localStorage.getItem('access_token')}`)

      client.onopen = () => {
        console.log('WebSocket Client Connected')
      };

      client.onmessage = (message) => {
        var eventType = JSON.parse(message.data).event_type
        var messageType = JSON.parse(message.data).message.message_type

        if (eventType === "SYSTEM_TO_ALL" && messageType === "TEAM_STATUS_CHANGE") {
          var member = JSON.parse(message.data).message.persona
          var teamId = JSON.parse(message.data).message.team_id
          var status = JSON.parse(message.data).message.is_active
          updateActiveUserTrainer({ member: member, team: teamId, status: status })
        }

        if (eventType === "TRAINER_TO_ALL" && messageType === "START_SESSION") {
          var teamList = JSON.parse(message.data).message.teams
          var idGame = JSON.parse(message.data).message.session_id
          setGameStatus("ONGOING")
          teamList.map((t) => {
            if (localStorage.getItem('team') === t.username) {
              localStorage.setItem("game_id", idGame)
              window.location.assign(`/team/session/${idGame}`)
            }
            return t
          })
        }
      };

      client.onclose = function () {
        console.log('Connection Closed.')
      };
    }

  }, [localStorage.getItem('access_token')])

  // user role
  const role = localStorage.getItem("role")

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Traderion Asset Management</title>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link href='https://fonts.googleapis.com/css?family=Poppins:wght@100;300;400;700;900&display=swap' rel='stylesheet' type='text/css'></link>
      </Helmet>

      <HeaderComponent />
      <Switch>
        <Route exact path="/" component={Login} />
        {/* team */}
        {role === "Teams" && <Route path="/team/session/:id" component={TeamGame} />}
        {role === "Teams" && <Route path="/team/sessions" component={TeamSessions} />}
        {role === "Teams" && <Route path="/team/classes/:idClass" component={TeamClasses} />}
        {role === "Teams" && <Route path="/team/report/:id" component={ReportTeam} />}
        {/* trainer */}
        {role === "Trainers" && <Route path="/trainer/session/:id" component={TrainerGame} />}
        {role === "Trainers" && <Route path="/trainer/sessions" component={TrainerSessions} />}
        {role === "Trainers" && <Route path="/trainer/classes/:idClass" component={TrainerClasses} />}
        {role === "Trainers" && <Route path="/trainer/scenarios" component={TrainerScenarios} />}
        {role === "Trainers" && <Route path="/trainer/allocations" component={Allocations} />}
        {role === "Trainers" && <Route path="/trainer/benchmark-portfolio" component={BenchmarkPortfolio} />}
        {role === "Trainers" && <Route path="/trainer/report/:id" component={ReportTrainer} />}
        {/* general */}
        <Route path="/404" component={Page404} />
        <Redirect to="/404" />
      </Switch>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  setGameStatus: payload => dispatch(setGameStatus(payload)),
  updateActiveUserTrainer: payload => dispatch(updateActiveUserTrainer(payload))
})

export default connect(null, mapDispatchToProps)(App)
