export const GET_TEAM_GAMES_REQUEST = "GET_TEAM_GAMES_REQUEST"
export const GET_TEAM_GAMES_SUCCESS = "GET_TEAM_GAMES_SUCCESS"
export const GET_TEAM_GAMES_FAILURE = "GET_TEAM_GAMES_FAILURE"

export const GET_TEAM_BENCHMARK_ALLOCATION_REQUEST = "GET_TEAM_BENCHMARK_ALLOCATION_REQUEST"
export const GET_TEAM_BENCHMARK_ALLOCATION_SUCCESS = "GET_TEAM_BENCHMARK_ALLOCATION_SUCCESS"
export const GET_TEAM_BENCHMARK_ALLOCATION_FAILURE = "GET_TEAM_BENCHMARK_ALLOCATION_FAILURE"

export const SAVE_INDIVIDUAL_BENCHMARK_REQUEST = "SAVE_INDIVIDUAL_BENCHMARK_REQUEST"
export const SAVE_INDIVIDUAL_BENCHMARK_SUCCESS = "SAVE_INDIVIDUAL_BENCHMARK_SUCCESS"
export const SAVE_INDIVIDUAL_BENCHMARK_FAILURE = "SAVE_INDIVIDUAL_BENCHMARK_FAILURE"

export const SAVE_BENCHMARK_REQUEST = "SAVE_BENCHMARK_REQUEST"
export const SAVE_BENCHMARK_SUCCESS = "SAVE_BENCHMARK_SUCCESS"
export const SAVE_BENCHMARK_FAILURE = "SAVE_BENCHMARK_FAILURE"

export const SET_ACTIVE_MENU_FOR_TEAM = "SET_ACTIVE_MENU_FOR_TEAM"

export const GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST = "GET_SHARPE_RATIO_COMPUTE_CHART_REQUEST"
export const GET_SHARPE_RATIO_COMPUTE_CHART_SUCCESS = "GET_SHARPE_RATIO_COMPUTE_CHART_SUCCESS"
export const GET_SHARPE_RATIO_COMPUTE_CHART_FAILURE = "GET_SHARPE_RATIO_COMPUTE_CHART_FAILURE"

export const GET_TEAM_CLASSES_REQUEST = "GET_TEAM_CLASSES_REQUEST"
export const GET_TEAM_CLASSES_SUCCESS = "GET_TEAM_CLASSES_SUCCESS"
export const GET_TEAM_CLASSES_FAILURE = "GET_TEAM_CLASSES_FAILURE"

export const SET_GLOBAL_CURRENT_STAGE = "SET_GLOBAL_CURRENT_STAGE"
export const SET_GLOBAL_CURRENT_PERIOD = "SET_GLOBAL_CURRENT_PERIOD"