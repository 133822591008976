import React from 'react'
import './InstrumentsGameTeam.scss'
import Layout, { Content } from 'antd/lib/layout/layout'
import { Menu } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { connect } from 'react-redux'
import { setCurrentActiveInstrument } from '../../redux/trainer/trainer.actions'

const InstrumentsGameTeam = ({ instruments, setCurrentActiveInstrument, currentInstrumentForTeam }) => {

    // show elements/pages by item key
    const instrumentsSwitch = (key) => {
        if (instruments) {
            if (instruments.scenario.instrument_information.length > 0) {
                for (var i = 0; i < instruments.scenario.instrument_information.length; i++) {
                    if (key === (instruments.scenario.instrument_information[i].instrument.id).toString()) {
                        return <div className="slider_content_instruments">
                            <h2>{instruments.scenario.instrument_information[i].instrument.name}</h2>
                            <h4>{instruments.scenario.instrument_information[i].instrument.asset_class.name}</h4>
                            <h4>{instruments.scenario.instrument_information[i].instrument.sub_asset_class.name}</h4>
                            <h4>{instruments.scenario.instrument_information[i].instrument.geography}</h4>
                            <p>{instruments.scenario.instrument_information[i].instrument.description}</p>
                        </div>
                    }
                }
            }
        }
    }

    if (currentInstrumentForTeam)
        return (
            <Layout>
                <Sider className="sider_instruments_teams">
                    <Menu className="menu-div" selectedKeys={currentInstrumentForTeam} mode="inline" onClick={(e) =>
                        setCurrentActiveInstrument(e.key)}>
                        {
                            (instruments && instruments.scenario.instrument_information.length > 0) &&
                            instruments.scenario.instrument_information.map((i) => {
                                return <Menu.Item key={i.instrument.id}>{i.instrument.name}</Menu.Item>
                            })
                        }
                    </Menu>
                </Sider>
                <Content>
                    {instrumentsSwitch(currentInstrumentForTeam)}
                </Content>
            </Layout>
        )
    return null
}

const mapStateToProps = state => ({
    currentInstrumentForTeam: state.trainer.currentInstrumentForTeam
})

const mapDispatchToProps = dispatch => ({
    setCurrentActiveInstrument: payload => dispatch(setCurrentActiveInstrument(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentsGameTeam)