import React, { useEffect, useState } from 'react'
import './CreateSessionModal.scss'
import { Modal, Row, Col, Select, Input, Switch, Button, InputNumber } from 'antd'
import { Option } from 'antd/lib/mentions'
import { connect } from 'react-redux'
import { getScenariosByTypeRequest, getClassesRequest, createSessionRequest, getTrainerGamesRequest } from '../../redux/trainer/trainer.actions'
import Spinner from '../Spinner/Spinner'

const CreateSessionModal = ({ setShowModal, showModal, getScenariosByTypeRequest, scenariosNameByProfile, getClassesRequest, classes, createSessionRequest, messageCreateSession, idCreatedSession, getTrainerGamesRequest, pageSize, isLoadingCreateSession }) => {

  // class select
  const [classValue, setClassValue] = useState(null)

  useEffect(() => {
    getClassesRequest()
  }, [])

  useEffect(() => {
    if (classes.length > 0) {
      setClassValue(classes[0].id)
    }
  }, [classes])

  const handleChangeClass = (value) => {
    setClassValue(value)
  }

  // selects scenario profile + scenario name
  const [profile, setProfile] = useState("AM")
  const [scenarioId, setScenarioId] = useState(null)

  useEffect(() => {
    getScenariosByTypeRequest(profile)
  }, [profile])

  useEffect(() => {
    if (scenariosNameByProfile.length > 0) {
      setScenarioId(scenariosNameByProfile[0].id)
    }
  }, [scenariosNameByProfile])

  const handleChangeScenarioType = (value) => {
    setProfile(value)
  }

  const handleChangeScenarioName = (value) => {
    setScenarioId(value)
  }

  // portfolio starts with
  const [money, setMoney] = useState(null)
  const onChangeMoney = (money) => {
    setMoney(money)
  }

  // allow leverage
  const [allowLeverage, setAllowLeverage] = useState(false)
  const [valueLeverage, setValueLeverage] = useState(1)

  const onChangeAllowLeverage = (checked) => {
    setAllowLeverage(checked)
  }

  const onChangeLeverage = (e) => {
    if (allowLeverage) {
      setValueLeverage(e.target.value)
    } else {
      setValueLeverage(0)
    }
  }

  // change Benchmark
  const [benchmarkType, setBenchmarkType] = useState("INDIVIDUAL")
  const [globalBenchmark, setGlobalBenchmark] = useState(false)
  const [benchmarks, setBenchmarks] = useState(null)
  const [instrumentsState, setInstrumentsState] = useState(null)
  const [total, setTotal] = useState(0)

  var initialBenchmark = []
  useEffect(() => {
    var instruments = []
    if (scenariosNameByProfile.length > 0) {
      scenariosNameByProfile.map((s) => {
        if (s.id === scenarioId) {
          s.instruments.map((i) => {
            initialBenchmark.push(
              {
                "instrument_id": i.instrument.id,
                "weight": parseFloat(i.benchmark_weight)
              }
            )

            instruments.push(
              {
                "instrument_id": i.instrument.id,
                "instrument_name": i.instrument.name,
                "weight": parseFloat(i.benchmark_weight)
              }
            )
            return i
          })
        }
        return s
      })
      setBenchmarks(initialBenchmark)
      setInstrumentsState(instruments)
    }
  }, [scenariosNameByProfile, showModal, scenarioId, globalBenchmark])

  const onChangeBenchmark = (checked) => {
    setGlobalBenchmark(checked)
    if (checked === true) {
      setBenchmarkType("GLOBAL")
    } else {
      setBenchmarkType("INDIVIDUAL")
    }
  }

  var sum = 0
  useEffect(() => {
    if (instrumentsState && instrumentsState.length > 0) {
      instrumentsState.map(i => {
        sum = sum + Math.abs(parseFloat(i.weight))
        setTotal(sum)
        return i
      })
    }
  }, [instrumentsState, scenarioId])

  const handleChange = (e, index) => {
    var val = e.target.value
    var newInstruments = instrumentsState.concat([])
    newInstruments[index].weight = val
    setInstrumentsState(newInstruments)

    var benchmarkS = []
    instrumentsState.map((i) => {
      benchmarkS.push(
        {
          "instrument_id": i.instrument_id,
          "weight": parseFloat(i.weight)
        }
      )
      return i
    })
    setBenchmarks(benchmarkS)
  }

  // create session 
  const createNewSession = () => {
    const body = {
      "scenario_id": scenarioId,
      "class_id": classValue,
      "portfolio_start_money": parseFloat(money),
      "max_leverage": parseInt(valueLeverage),
      "benchmark_type": benchmarkType,
      "benchmarks": benchmarks
    }
    createSessionRequest(body)
    var params = {
      "pageNumber": 1,
      "pageSize": pageSize,
    }
    setTimeout(() => {
      getTrainerGamesRequest({ paramList: params })
    }, 500)
  }

  // message create session
  const [msg, setMsg] = useState(null)
  useEffect(() => {
    if (messageCreateSession)
      setMsg(messageCreateSession)
  }, [messageCreateSession])

  // modal
  const handleCancel = () => {
    if (classes.length > 0) {
      setClassValue(classes[0].id)
    }
    setProfile("AM")
    if (scenariosNameByProfile.length > 0) {
      setScenarioId(scenariosNameByProfile[0].id)
    }
    setMoney(null)
    setAllowLeverage(false)
    setValueLeverage(1)
    setBenchmarkType("INDIVIDUAL")
    setGlobalBenchmark(false)
    setBenchmarks(null)
    setInstrumentsState(null)
    setTotal(0)
    setMsg(null)
    setShowModal(false)
  }

  // redirect to game
  const startGame = (id) => {
    localStorage.setItem("game_id", id)
    window.location.assign(`/trainer/session/${id}`)
  }

  return (
    <Modal
      destroyOnClose={true}
      title="Create a new session"
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      className="create_session_modal"
    >
      <>
        <Row>
          <Col span={12} md={12} sm={24} xs={24} className="col_style">
            {/* select class */}
            <h4 className="subtitle">Select class</h4>
            {
              (classes.length > 0 && classValue) &&
              <Select defaultValue={classValue} onChange={handleChangeClass}>
                {

                  classes.map((c) => {
                    return <Option value={c.id}>{c.name}</Option>
                  })
                }
              </Select>
            }

            {/* select profile */}
            <h4 className="subtitle">Scenario type</h4>
            <Select defaultValue="AM" onChange={handleChangeScenarioType}>
              <Option value="AM">Asset Management</Option>
              <Option value="HF">Hedge Fund</Option>
              <Option value="YC">Yield Curve</Option>
            </Select>

            {/* input money */}
            <h4 className="subtitle">Parameters</h4>
            <h4 className="label_style">Portfolios start with</h4>
            <InputNumber
              className="input_number"
              formatter={money => ` ${money}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={money => money.replace(/\$\s?|(,*)/g, '')}
              value={money}
              onChange={onChangeMoney}
            />

            {/* leverage */}
            <div className="group">
              <h4 className="label_style">Allow Leverage</h4>
              <Switch onChange={onChangeAllowLeverage} />
            </div>
            {
              allowLeverage ?
                <Input
                  type="number"
                  min="1"
                  value={valueLeverage}
                  onChange={onChangeLeverage}
                />
                :
                <Input
                  type="number"
                  readOnly
                />
            }

            {/* choose benchmark */}
            <div className="group">
              <h4 className="label_style">Individual Benchmark</h4>
              <Switch onChange={onChangeBenchmark} />
              <h4 className="label_style" style={{ marginLeft: "1%" }}>Global Benchmark</h4>
            </div>
          </Col>
          <Col span={12} md={12} sm={24} xs={24} className="col_style">

            {/* select scenario name */}
            <h4 className="subtitle">Scenario name</h4>
            {
              (scenariosNameByProfile.length > 0 && scenarioId) &&
              <Select value={scenarioId} onChange={handleChangeScenarioName}>
                {
                  scenariosNameByProfile.map((s) => {
                    return <Option value={s.id}>{s.scenario_name}</Option>
                  })
                }
              </Select>
            }

            {/* global benchmark if exists */}
            <div className={globalBenchmark ? "show_global_benchmark" : "hide_global_benchmark"}>
              <h4 className="subtitle">Global benchmark</h4>
              <Row>
                <Col span={12}>
                  <h4 className="subtitle">Instrument</h4>
                </Col>
                <Col span={12}>
                  <h4 className="subtitle">Weight</h4>
                </Col>
              </Row>
              {
                (instrumentsState && instrumentsState.length > 0) && instrumentsState.map((i, index) => {
                  return <Row key={index} index={index}>
                    <Col span={12}><h4 className="label_style" >{i.instrument_name}</h4></Col>
                    <Col span={12}>
                      <Input
                        index={index}
                        key={index}
                        type="number"
                        min="-100" max="100"
                        value={instrumentsState[index].weight}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Col>
                  </Row>
                })
              }
              <Row className="total_row">
                <Col span={12}>
                  <h4 className="total_label">Total</h4>
                </Col>
                <Col span={12}>
                  <Input
                    readOnly
                    type="number"
                    defaultValue={total}
                    value={total}
                    className={total !== 100 ? "warning_class" : null}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="btn_row">
          {
            msg &&
            <span className={msg === "Session was successfully created!" ? "success_class" : "warning_class"}>{msg}</span>
          }
          {
            isLoadingCreateSession ? <Spinner /> : <></>
          }
        </div>
        <div className="btn_row">
          <Button className={msg === "Session was successfully created!" ? "hide_btn" : null} disabled={isLoadingCreateSession ? true : false} onClick={(e) => createNewSession(e)}>Create</Button>
          {(msg === "Session was successfully created!" && idCreatedSession) &&
            <Button className="join_btn" onClick={() => startGame(idCreatedSession)}>Join</Button>
          }
        </div>
      </>
    </Modal >
  )
}

const mapStateToProps = state => ({
  scenariosNameByProfile: state.trainer.scenariosNameByProfile,
  classes: state.trainer.classes,
  messageCreateSession: state.trainer.messageCreateSession,
  idCreatedSession: state.trainer.idCreatedSession,
  pageSize: state.trainer.pageSize,
  isLoadingCreateSession: state.trainer.isLoadingCreateSession
})

const mapDispatchToProps = dispatch => ({
  getScenariosByTypeRequest: payload => dispatch(getScenariosByTypeRequest(payload)),
  getClassesRequest: payload => dispatch(getClassesRequest(payload)),
  createSessionRequest: payload => dispatch(createSessionRequest(payload)),
  getTrainerGamesRequest: payload => dispatch(getTrainerGamesRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSessionModal)