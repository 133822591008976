import React from 'react'
import './ScenarioStages.scss'

const Final = () => {
    return (
        <div className="welcome_screen">
            <div className="text_class">
                <h2>The End</h2>
            </div>
        </div>
    )
}

export default Final