import React from 'react'
import './ScenarioStages.scss'

const WelcomeScreen = () => {
    return (
        <div className="welcome_screen">
            <div className="text_class">
                <h2 className="blue_text">Hello!</h2>
                <h3>Welcome to <span className="blue_text">Traderion Asset Manager Simulator</span>!</h3>
                <h3>Please stand by, your trainer will begin the session soon.</h3>
            </div>
        </div>
    )
}

export default WelcomeScreen