import React, { useEffect } from 'react'
import './SideBarGame.scss'
import Sider from 'antd/lib/layout/Sider'
import { Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { BarsOutlined, DashboardOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getSessionRequest } from '../../redux/trainer/trainer.actions'

const SideBarGame = ({ getSessionRequest, getSessionResponse, selectedMenuItem, setSelectedMenuItem }) => {

    // get session to populate sidebar 
    useEffect(() => {
        var gameId = localStorage.getItem("game_id")
        if (gameId)
            getSessionRequest(gameId)
    }, [])

    // build submenu depending on the number of periods and elements from a period + cases from periods
    var noPfPeriods
    if (getSessionResponse)
        noPfPeriods = getSessionResponse.scenario.periods.length
    const period = ["Macro:Background", "Portfolios: Allocations", "Portfolios: Compare", "Macro: Review", "Instruments: Returns", "Portfolios: Values", "Portfolios: Performance"]
    const final = ["Daily values", "Daily returns", "Overall return", "Annual Performance", "Sharpe Ratio (compute)", "Sharpe Ratio (compare)"]

    var periods = []
    for (var i = 1; i <= noPfPeriods; i++) {
        periods.push(
            <SubMenu title={"Period " + i}>
                {
                    period.map((p, index) => {
                        return <Menu.Item className="submenu-item" key={"period_" + i + index}>{p}</Menu.Item>
                    })
                }
            </SubMenu>
        )
    }

    var finals = []
    finals.push(
        <SubMenu title="Final">
            {
                final.map((f, index) => {
                    return <Menu.Item className="submenu-item" key={"final_" + index}>{f}</Menu.Item>
                })
            }
        </SubMenu>
    )

    return (
        <Sider className="sider_game">
            {
                selectedMenuItem &&
                <Menu mode="inline" selectedKeys={selectedMenuItem} onClick={(e) => setSelectedMenuItem(e.key)}>
                    <Menu.Item key={`/trainer/scenarios`} icon={<DashboardOutlined />}>
                        <Link to={`/trainer/scenarios`} className="nav-text">Dashboard</Link>
                    </Menu.Item>
                    <SubMenu title="Stages" icon={<BarsOutlined />} >
                        <SubMenu className={(selectedMenuItem === "0" || selectedMenuItem === "1" || selectedMenuItem === "2") ? "selected_class" : "unselected_class"} title="Introduction">
                            <Menu.Item className="submenu-item" key="0">Welcome</Menu.Item>
                            <Menu.Item className="submenu-item" key="1">Description</Menu.Item>
                            <Menu.Item className="submenu-item" key="2">Benchmark and leverage</Menu.Item>
                        </SubMenu>
                        {periods}
                        {finals}
                    </SubMenu>
                </Menu>
            }
        </Sider >
    )
}

const mapStateToProps = state => ({
    getSessionResponse: state.trainer.getSessionResponse
})

const mapDispatchToProps = dispatch => ({
    getSessionRequest: payload => dispatch(getSessionRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBarGame)