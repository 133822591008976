import React from 'react'
import './AllocationModal.scss'
import { Modal, Table } from 'antd'

const AllocationModal = ({ setShowModal, showModal, modalContent }) => {

    // modal cencel
    const handleCancel = () => {
        setShowModal(false)
    }

    // table columns and data
    const columns = [
        {
            title: "Instruments",
            dataIndex: "instrument_name"
        }
    ]

    const data = []
    var auxData = {}
    var auxElem = {}
    var auxData2 = {}
    var auxElem2 = {}
    var auxData3 = {}
    var auxElem3 = {}
    var auxData4 = {}
    var auxElem4 = {}
    var title = ""
    var children = [{
        "title": "Benchmark",
        "dataIndex": "benchmark"

    }]

    if (modalContent) {
        title = modalContent.team_name
        if (modalContent.instruments[0].periods.length > 0) {
            for (var i = 0; i < modalContent.instruments[0].periods.length; i++) {
                children.push({
                    "title": "Period" + modalContent.instruments[0].periods[i].period_number,
                    "dataIndex": "period" + i
                })
            }

            columns.push({
                title: 'Weights',
                children: children
            })
        }

        if (modalContent.instruments.length > 0) {
            for (var k = 0; k < modalContent.instruments.length; k++) {
                var element = {}
                for (var l = 0; l < modalContent.instruments[k].periods.length; l++) {
                    element = {}
                    element[`period${l}`] = modalContent.instruments[k].periods[l].weight
                    auxElem = Object.assign(auxElem, element)
                }
                auxData = {
                    "instrument_name": modalContent.instruments[k].instrument_name,
                    "benchmark": modalContent.instruments[k].benchmark
                }

                let row = Object.assign(auxData, auxElem)
                data.push(row)
            }

            // push Value(M)
            var element2 = {}
            for (var j = 0; j < modalContent.final_value.periods.length; j++) {
                element2 = {}
                element2[`period${j}`] = modalContent.final_value.periods[j].weight
                auxElem2 = Object.assign(auxElem2, element2)
            }
            auxData2 = {
                "instrument_name": "Value (M)",
                "benchmark": modalContent.final_value.benchmark
            }

            let newRow = Object.assign(auxData2, auxElem2)
            data.push(newRow)

            // push Return (%) 
            var element3 = {}
            for (var m = 0; m < modalContent.return.periods.length; m++) {
                element3 = {}
                if (modalContent.return.periods[m].weight !== 0) {
                    var arr = (modalContent.return.periods[m].weight).split(/%|v/)
                    var first = parseFloat(arr[0])
                    var second = parseFloat(arr[2])
                    element3[`period${m}`] = <div><span className={first < second ? "red_class" : first > second ? "green_class" : null}>{first}%</span> v <span>{second}%</span></div>
                } else {
                    element3[`period${m}`] = modalContent.return.periods[m].weight
                }
                auxElem3 = Object.assign(auxElem3, element3)
            }
            auxData3 = {
                "instrument_name": "Return (%) ",
                "benchmark": modalContent.return.benchmark
            }

            let newRow3 = Object.assign(auxData3, auxElem3)
            data.push(newRow3)

            // push Rank (/)
            var element4 = {}
            for (var n = 0; n < modalContent.rank.periods.length; n++) {
                element4 = {}
                element4[`period${n}`] = modalContent.rank.periods[n].weight
                auxElem4 = Object.assign(auxElem4, element4)
            }
            auxData4 = {
                "instrument_name": "Rank",
                "benchmark": modalContent.rank.benchmark
            }

            let newRow4 = Object.assign(auxData4, auxElem4)
            data.push(newRow4)

        }
    }

    return (
        <Modal
            title={title}
            visible={showModal}
            onCancel={handleCancel}
            footer={null}
            className="allocation_modal"
            mask={false}
        >
            <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 'fit-content' }} />
        </Modal>
    )
}

export default AllocationModal