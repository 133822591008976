export const GET_TRAINER_GAMES_REQUEST = "GET_TRAINER_GAMES_REQUEST"
export const GET_TRAINER_GAMES_SUCCESS = "GET_TRAINER_GAMES_SUCCESS"
export const GET_TRAINER_GAMES_FAILURE = "GET_TRAINER_GAMES_FAILURE"

export const GET_SCENARIOS_REQUEST = "GET_SCENARIOS_REQUEST"
export const GET_SCENARIOS_SUCCESS = "GET_SCENARIOS_SUCCESS"
export const GET_SCENARIOS_FAILURE = "GET_SCENARIOS_FAILURE"

export const GET_CLASSES_REQUEST = "GET_CLASSES_REQUEST"
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS"
export const GET_CLASSES_FAILURE = "GET_CLASSES_FAILURE"

export const GET_TRAINER_CLASSES_REQUEST = "GET_TRAINER_CLASSES_REQUEST"
export const GET_TRAINER_CLASSES_SUCCESS = "GET_TRAINER_CLASSES_SUCCESS"
export const GET_TRAINER_CLASSES_FAILURE = "GET_TRAINER_CLASSES_FAILURE"

export const GET_CLASS_TEAMS_REQUEST = "GET_CLASS_TEAMS_REQUEST"
export const GET_CLASS_TEAMS_SUCCESS = "GET_CLASS_TEAMS_SUCCESS"
export const GET_CLASS_TEAMS_FAILURE = "GET_CLASS_TEAMS_FAILURE"

export const SET_FAVOURITE_SCENARIO_REQUEST = "SET_FAVOURITE_SCENARIO_REQUEST"
export const SET_FAVOURITE_SCENARIO_SUCCESS = "SET_FAVOURITE_SCENARIO_SUCCESS"
export const SET_FAVOURITE_SCENARIO_FAILURE = "SET_FAVOURITE_SCENARIO_FAILURE"

export const GET_SCENARIOS_BY_TYPE_REQUEST = "GET_SCENARIOS_BY_TYPE_REQUEST"
export const GET_SCENARIOS_BY_TYPE_SUCCESS = "GET_SCENARIOS_BY_TYPE_SUCCESS"
export const GET_SCENARIOS_BY_TYPE_FAILURE = "GET_SCENARIOS_BY_TYPE_FAILURE"

export const CREATE_SESSION_REQUEST = "CREATE_SESSION_REQUEST"
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS"
export const CREATE_SESSION_FAILURE = "CREATE_SESSION_FAILURE"

export const GET_SCENARIO_PREVIEW_REQUEST = "GET_SCENARIO_PREVIEW_REQUEST"
export const GET_SCENARIO_PREVIEW_SUCCESS = "GET_SCENARIO_PREVIEW_SUCCESS"
export const GET_SCENARIO_PREVIEW_FAILURE = "GET_SCENARIO_PREVIEW_FAILURE"

export const START_SESSION_REQUEST = "START_SESSION_REQUEST"
export const START_SESSION_SUCCESS = "START_SESSION_SUCCESS"
export const START_SESSION_FAILURE = "START_SESSION_FAILURE"

export const GET_SESSION_REQUEST = "GET_SESSION_REQUEST"
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS"
export const GET_SESSION_FAILURE = "GET_SESSION_FAILURE"

export const GET_BENCHMARK_PORTFOLIO_REQUEST = "GET_BENCHMARK_PORTFOLIO_REQUEST"
export const GET_BENCHMARK_PORTFOLIO_SUCCESS = "GET_BENCHMARK_PORTFOLIO_SUCCESS"
export const GET_BENCHMARK_PORTFOLIO_FAILURE = "GET_BENCHMARK_PORTFOLIO_FAILURE"

export const NEXT_STAGE = "NEXT_STAGE"
export const PREV_STAGE = "PREV_STAGE"
export const SELECT_MENU_ITEM = "SELECT_MENU_ITEM"
export const CURRENT_STAGE = "CURRENT_STAGE"

export const SET_CURRENT_ACTIVE_INSTRUMENT = "SET_CURRENT_ACTIVE_INSTRUMENT"

export const GET_ALLOCATION_REQUEST = "GET_ALLOCATION_REQUEST"
export const GET_ALLOCATION_SUCCESS = "GET_ALLOCATION_SUCCESS"
export const GET_ALLOCATION_FAILURE = "GET_ALLOCATION_FAILURE"

export const NEXT_STAGE_SESSION_REQUEST = "NEXT_STAGE_SESSION_REQUEST"
export const NEXT_STAGE_SESSION_SUCCESS = "NEXT_STAGE_SESSION_SUCCESS"
export const NEXT_STAGE_SESSION_FAILURE = "NEXT_STAGE_SESSION_FAILURE"

export const GET_PORTFOLIO_COMPARE_CHART_REQUEST = "GET_PORTFOLIO_COMPARE_CHART_REQUEST"
export const GET_PORTFOLIO_COMPARE_CHART_SUCCESS = "GET_PORTFOLIO_COMPARE_CHART_SUCCESS"
export const GET_PORTFOLIO_COMPARE_CHART_FAILURE = "GET_PORTFOLIO_COMPARE_CHART_FAILURE"

export const GET_INSTRUMENTS_RETURNS_CHART_REQUEST = "GET_INSTRUMENTS_RETURNS_CHART_REQUEST"
export const GET_INSTRUMENTS_RETURNS_CHART_SUCCESS = "GET_INSTRUMENTS_RETURNS_CHART_SUCCESS"
export const GET_INSTRUMENTS_RETURNS_CHART_FAILURE = "GET_INSTRUMENTS_RETURNS_CHART_FAILURE"

export const GET_PORTFOLIO_VALUES_CHART_REQUEST = "GET_PORTFOLIO_VALUES_CHART_REQUEST"
export const GET_PORTFOLIO_VALUES_CHART_SUCCESS = "GET_PORTFOLIO_VALUES_CHART_SUCCESS"
export const GET_PORTFOLIO_VALUES_CHART_FAILURE = "GET_PORTFOLIO_VALUES_CHART_FAILURE"

export const GET_PORTFOLIO_PERFORMANCE_CHART_REQUEST = "GET_PORTFOLIO_PERFORMANCE_CHART_REQUEST"
export const GET_PORTFOLIO_PERFORMANCE_CHART_SUCCESS = "GET_PORTFOLIO_PERFORMANCE_CHART_SUCCESS"
export const GET_PORTFOLIO_PERFORMANCE_CHART_FAILURE = "GET_PORTFOLIO_PERFORMANCE_CHART_FAILURE"

export const GET_DAILY_VALUES_CHART_REQUEST = "GET_DAILY_VALUES_CHART_REQUEST"
export const GET_DAILY_VALUES_CHART_SUCCESS = "GET_DAILY_VALUES_CHART_SUCCESS"
export const GET_DAILY_VALUES_CHART_FAILURE = "GET_DAILY_VALUES_CHART_FAILURE"

export const GET_DAILY_RETURNS_CHART_REQUEST = "GET_DAILY_RETURNS_CHART_REQUEST"
export const GET_DAILY_RETURNS_CHART_SUCCESS = "GET_DAILY_RETURNS_CHART_SUCCESS"
export const GET_DAILY_RETURNS_CHART_FAILURE = "GET_DAILY_RETURNS_CHART_FAILURE"

export const GET_OVERALL_RETURNS_CHART_REQUEST = "GET_OVERALL_RETURNS_CHART_REQUEST"
export const GET_OVERALL_RETURNS_CHART_SUCCESS = "GET_OVERALL_RETURNS_CHART_SUCCESS"
export const GET_OVERALL_RETURNS_CHART_FAILURE = "GET_OVERALL_RETURNS_CHART_FAILURE"

export const GET_ANNUAL_PERFORMANCE_CHART_REQUEST = "GET_ANNUAL_PERFORMANCE_CHART_REQUEST"
export const GET_ANNUAL_PERFORMANCE_CHART_SUCCESS = "GET_ANNUAL_PERFORMANCE_CHART_SUCCESS"
export const GET_ANNUAL_PERFORMANCE_CHART_FAILURE = "GET_ANNUAL_PERFORMANCE_CHART_FAILURE"

export const GET_SHARPE_RATIO_COMPARE_CHART_REQUEST = "GET_SHARPE_RATIO_COMPARE_CHART_REQUEST"
export const GET_SHARPE_RATIO_COMPARE_CHART_SUCCESS = "GET_SHARPE_RATIO_COMPARE_CHART_SUCCESS"
export const GET_SHARPE_RATIO_COMPARE_CHART_FAILURE = "GET_SHARPE_RATIO_COMPARE_CHART_FAILURE"

export const PAUSE_SESSION_REQUEST = "PAUSE_SESSION_REQUEST"
export const PAUSE_SESSION_SUCCESS = "PAUSE_SESSION_SUCCESS"
export const PAUSE_SESSION_FAILURE = "PAUSE_SESSION_FAILURE"

export const STOP_SESSION_REQUEST = "STOP_SESSION_REQUEST"
export const STOP_SESSION_SUCCESS = "STOP_SESSION_SUCCESS"
export const STOP_SESSION_FAILURE = "STOP_SESSION_FAILURE"

export const SET_GAME_STATUS = "SET_GAME_STATUS"

export const UPDATE_ACTIVE_USER_TRAINER = "UPDATE_ACTIVE_USERS_LIST_TRAINER"

export const GET_TEAMS_SESSION_REQUEST = "GET_TEAMS_SESSION_REQUEST"
export const GET_TEAMS_SESSION_SUCCESS = "GET_TEAMS_SESSION_SUCCESS"
export const GET_TEAMS_SESSION_FAILURE = "GET_TEAMS_SESSION_FAILURE"

export const UPDATE_ALLOCATIONS_TABLE = "UPDATE_ALLOCATIONS_TABLE"
export const UPDATE_BENCHMARK_LEVERAGE = "UPDATE_BENCHMARK_LEVERAGE"

export const CLICKED_ELEMENT_FROM_HEADER = "CLICKED_ELEMENT_FROM_HEADER"

export const GET_PORTFOLIO_COMPARE_CHART_PREVIEW_REQUEST = "GET_PORTFOLIO_COMPARE_CHART_PREVIEW_REQUEST"
export const GET_PORTFOLIO_COMPARE_CHART_PREVIEW_SUCCESS = "GET_PORTFOLIO_COMPARE_CHART_PREVIEW_SUCCESS"
export const GET_PORTFOLIO_COMPARE_CHART_PREVIEW_FAILURE = "GET_PORTFOLIO_COMPARE_CHART_PREVIEW_FAILURE"

export const GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_REQUEST = "GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_REQUEST"
export const GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_SUCCESS = "GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_SUCCESS"
export const GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_FAILURE = "GET_INSTRUMENTS_RETURNS_CHART_PREVIEW_FAILURE"

export const GET_PORTFOLIO_VALUES_CHART_PREVIEW_REQUEST = "GET_PORTFOLIO_VALUES_CHART_PREVIEW_REQUEST"
export const GET_PORTFOLIO_VALUES_CHART_PREVIEW_SUCCESS = "GET_PORTFOLIO_VALUES_CHART_PREVIEW_SUCCESS"
export const GET_PORTFOLIO_VALUES_CHART_PREVIEW_FAILURE = "GET_PORTFOLIO_VALUES_CHART_PREVIEW_FAILURE"

export const GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_REQUEST = "GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_REQUEST"
export const GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_SUCCESS = "GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_SUCCESS"
export const GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_FAILURE = "GET_PORTFOLIO_PERFORMANCE_CHART_PREVIEW_FAILURE"

export const SET_USER_STATUS = "SET_USER_STATUS"
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS"

export const INITIAL_STATES_REPORT = "INITIAL_STATES_REPORT"