import React, { useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import '../../../App.scss'
import '../ScenarioStages.scss'
import { connect } from 'react-redux'
import { getPortfolioPerformanceChartPreviewRequest } from '../../../redux/trainer/trainer.actions'

const PortfoliosPerformance = ({ getPortfolioPerformanceChartPreviewRequest, responseGetPortfolioPerformanceChartPreview, idScenarioPreview }) => {

    useEffect(() => {
        if (idScenarioPreview)
            getPortfolioPerformanceChartPreviewRequest(idScenarioPreview)
    }, [idScenarioPreview])

    var finalData = []
    if (responseGetPortfolioPerformanceChartPreview && responseGetPortfolioPerformanceChartPreview.datasets.length > 0)
        responseGetPortfolioPerformanceChartPreview.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible)
                }
            )
            return finalData
        })

    var state
    if (responseGetPortfolioPerformanceChartPreview)
        state = {
            labels: responseGetPortfolioPerformanceChartPreview.labels,
            datasets: finalData
        }

    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 11,
                boxWidth: 20,
                boxHeight: 2
            }
        },
        scales: {
            yAxes: [{
                fontSize: 11,
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        return value + "%"
                    }
                }
            }],
            xAxes: [{
                ticks: {
                    fontSize: 11
                }
            }]
        }
    }

    return (
        <div className="general_container stage_container">
            <h4 className="subtitle-stage">Portfolio: Performance</h4>
            <Bar data={state} options={options} />
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetPortfolioPerformanceChartPreview: state.trainer.responseGetPortfolioPerformanceChartPreview,
    idScenarioPreview: state.trainer.idScenarioPreview,
})

const mapDispatchToProps = dispatch => ({
    getPortfolioPerformanceChartPreviewRequest: payload => dispatch(getPortfolioPerformanceChartPreviewRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PortfoliosPerformance)