import React from 'react'
import '../App.scss'
import error from '../images/404.png'

const Page404 = () => {
    return (
        <>
            <div className="general_container" style={{ textAlign: "center" }}>
                <img style={{ width: "70%", height: "auto", marginTop: "auto", marginBottom: "auto" }} src={error} alt="not-found"></img>
            </div>
        </>
    )
}

export default Page404