import React, { useEffect, useState } from 'react'
import { Modal, Button, Input } from 'antd'
import { connect } from 'react-redux'
import { getTeamRequest, addPersonRequest } from '../../redux/account/account.actions'
import './CustomUsersModal.scss'

const CustomModal = ({ visible, getTeamRequest, name, personas, addPersonRequest, currentSession }) => {

  useEffect(() => {
    getTeamRequest()
  }, [])

  const [namePerson, setName] = useState("")

  const addPerson = () => {
    addPersonRequest({
      name: namePerson
    })
  }

  const setUser = (index) => {
    localStorage.setItem('username', personas[index].name)
    if (currentSession !== null) {
      localStorage.setItem("game_id", currentSession)
      window.location.assign(`/team/session/${currentSession}`)
    }
    else
      window.location.assign(`/team/sessions`)
  }

  return (
    <div>
      <Modal
        title="Select your name or add it in the below list"
        visible={visible}
        className="custom_modal"

        footer={[
          <div className="input_style">
            <Input value={namePerson} onChange={event => setName(event.target.value)}></Input>
            <Button onClick={() => addPerson()}>ADD</Button>
          </div>
        ]}
      >
        <>
          <h1>{name}</h1>
          <div className="content">
            {
              personas.map((p, index) => {
                return <div className="name_div" key={index} index={index} >
                  <label onClick={() => setUser(index)}>{p.name}</label>
                </div>
              })
            }
          </div>
        </>
      </Modal >
    </div >
  );
}

const mapDispatchToProps = dispatch => ({
  getTeamRequest: payload => dispatch(getTeamRequest(payload)),
  addPersonRequest: payload => dispatch(addPersonRequest(payload))
})

const mapStateToProps = (state) => ({
  name: state.account.name,
  personas: state.account.personas,
  currentSession: state.account.currentSession
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal)