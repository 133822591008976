import React, { useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import '../../App.scss'
import './FinalStage.scss'
import { connect } from 'react-redux'
import { getAnnualPerformanceChartRequest } from '../../redux/trainer/trainer.actions'

const AnnualPerformance = ({ getAnnualPerformanceChartRequest, responseGetAnnualPerformanceChart }) => {

    const idGame = localStorage.getItem("game_id")
    useEffect(() => {
        getAnnualPerformanceChartRequest(idGame)
    }, [idGame])

    var finalData = []
    if (responseGetAnnualPerformanceChart && responseGetAnnualPerformanceChart.datasets.length > 0)
        responseGetAnnualPerformanceChart.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible)
                }
            )
            return finalData
        })

    var state
    if (responseGetAnnualPerformanceChart)
        state = {
            labels: responseGetAnnualPerformanceChart.labels,
            datasets: finalData
        }

    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 11,
                boxWidth: 20,
                boxHeight: 2
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    fontSize: 11,
                    stepSize: 10,
                    callback: function (value) {
                        return value + "%"
                    }
                }
            }],
            xAxes: [{
                ticks: {
                    fontSize: 11
                }
            }]
        },
        tooltips: {
            enabled: true,
            callbacks: {
                title: function () {
                    return ""
                },
                label: function (tooltipItems, data) {
                    return tooltipItems.yLabel.toFixed(2) + "%";
                }
            }
        }
    }

    return (
        <div className="general_container final_stage">
            <h4 className="subtitle-stage">Annual Performance</h4>
            <Bar data={state} options={options} />
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetAnnualPerformanceChart: state.trainer.responseGetAnnualPerformanceChart
})

const mapDispatchToProps = dispatch => ({
    getAnnualPerformanceChartRequest: payload => dispatch(getAnnualPerformanceChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnnualPerformance)