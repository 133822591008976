import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import '../../App.scss'
import './FinalStage.scss'
import { Button, Input, Row } from 'antd'
import { connect } from 'react-redux'
import { getAnnualPerformanceChartRequest } from '../../redux/trainer/trainer.actions'
import { getSharpeRatioComputeChartRequest } from '../../redux/team/team.actions'

const SharpeRatioCompute = ({ getAnnualPerformanceChartRequest, responseGetAnnualPerformanceChart, getSharpeRatioComputeChartRequest, responseGetSharpeRatioComputeChart, sharpeRatio, report }) => {

    var role = localStorage.getItem("role")
    const idGame = localStorage.getItem("game_id")

    // for trainer   
    useEffect(() => {
        if (role && role === "Trainers")
            getAnnualPerformanceChartRequest(idGame)
    }, [idGame])

    var finalData = []
    if (responseGetAnnualPerformanceChart && responseGetAnnualPerformanceChart.datasets.length > 0)
        responseGetAnnualPerformanceChart.datasets.map(d => {
            finalData.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible)
                }
            )
            return finalData
        })

    var state
    if (responseGetAnnualPerformanceChart)
        state = {
            labels: responseGetAnnualPerformanceChart.labels,
            datasets: finalData
        }

    // for team
    useEffect(() => {
        if (role && role === "Teams")
            getSharpeRatioComputeChartRequest(idGame)
    }, [idGame])

    var finalDataTeam = []
    if (responseGetSharpeRatioComputeChart && responseGetSharpeRatioComputeChart.datasets.length > 0)
        responseGetSharpeRatioComputeChart.datasets.map(d => {
            finalDataTeam.push(
                {
                    label: d.data.name,
                    fill: false,
                    backgroundColor: d.data.color,
                    borderColor: d.data.color,
                    borderWidth: 2,
                    data: d.data.points,
                    hidden: (!d.data.visible)
                }
            )
            return finalDataTeam
        })

    var stateTeam
    if (responseGetSharpeRatioComputeChart) {
        stateTeam = {
            labels: responseGetSharpeRatioComputeChart.labels,
            datasets: finalDataTeam
        }
    }

    const [inputValue, setInputValue] = useState(null)
    const [message, setMessage] = useState(null)

    const handleChange = (e) => {
        setInputValue(e.target.value)
    }

    const checkSharpeRatio = () => {
        if (sharpeRatio && inputValue) {
            if (sharpeRatio.toFixed(2) === inputValue) {
                setMessage(<>
                    <label className="green_txt">Fantastic! Well done!</label>
                    &nbsp;
                    <label>Your sharpe ratio is {inputValue}.</label>
                </>)
            } else {
                setMessage(
                    <label className="red_txt">Your sharpe ratio isn't correct!</label>
                )
            }
        }
    }

    // options
    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 11,
                boxWidth: 20,
                boxHeight: 2
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                    callback: function (value) {
                        return value + "%"
                    }
                }
            }],
            xAxes: [{
                barPercentage: 0.5
            }]
        },
        tooltips: {
            enabled: true,
            callbacks: {
                title: function () {
                    return ""
                },
                label: function (tooltipItems, data) {
                    return tooltipItems.yLabel.toFixed(2) + "%";
                }
            }
        }
    }

    return (
        <div className="general_container final_stage">
            <h4 className="subtitle-stage">Sharpe Ratio (Compute)</h4>
            {
                (role && role === "Trainers") &&
                <Bar data={state} options={options} />
            }
            {
                (role && role === "Teams") &&
                <>
                    <Bar data={stateTeam} options={options} />
                    {
                        report === false ?
                            <Row className="row_sharpe_ratio">
                                <label>Your Sharpe ratio is</label>
                                <Input
                                    className="input_sharpe_ratio"
                                    value={inputValue}
                                    onChange={(e) => handleChange(e)}
                                />
                                <Button className="btn_sharpe_ratio" onClick={() => checkSharpeRatio()}>Submit</Button>
                            </Row> :
                            <></>
                    }
                    {
                        message &&
                        <Row className="row_message">
                            {message}
                        </Row>
                    }

                </>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    responseGetAnnualPerformanceChart: state.trainer.responseGetAnnualPerformanceChart,
    responseGetSharpeRatioComputeChart: state.team.responseGetSharpeRatioComputeChart,
    sharpeRatio: state.team.sharpeRatio
})

const mapDispatchToProps = dispatch => ({
    getAnnualPerformanceChartRequest: payload => dispatch(getAnnualPerformanceChartRequest(payload)),
    getSharpeRatioComputeChartRequest: payload => dispatch(getSharpeRatioComputeChartRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SharpeRatioCompute)