import React, { useEffect, useState } from 'react'
import './ReportBenchmarkPortfolio.scss'
import Layout, { Content } from 'antd/lib/layout/layout'
import { Table } from 'antd'
import { connect } from 'react-redux'
import { getBenchmarkPortfolioRequest } from '../../redux/trainer/trainer.actions'

const ReportBenchmarkPortfolio = ({ getBenchmarkPortfolioRequest, contentBenchmarkPortfolio, benchmarkTypePortfolioTable, maxLeveragePortfolioTable }) => {
    const idGame = localStorage.getItem("game_id")

    // get benchmark
    useEffect(() => {
        if (idGame)
            getBenchmarkPortfolioRequest(idGame)
    }, [idGame])

    // table
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: 'Settings',
            children: [
                {
                    title: 'Benchmark Type',
                    dataIndex: 'benchmarkType',
                    key: 'benchmarkType',
                },
                {
                    title: 'Max Leverage',
                    dataIndex: 'maxLeverage',
                    key: 'maxLeverage',
                }
            ],
        },
        {
            title: "Leverage",
            dataIndex: "leverage"
        }
    ]

    const [columnsState, setColumnsState] = useState(null)
    const [dataState, setDataState] = useState(null)
    useEffect(() => {
        var data = []
        var auxData = {}
        var auxElem = {}
        if (benchmarkTypePortfolioTable && maxLeveragePortfolioTable && contentBenchmarkPortfolio && contentBenchmarkPortfolio.length > 0) {
            for (var j = 0; j < contentBenchmarkPortfolio[0].benchmarks.length; j++) {
                columns.push({
                    "title": <div className="instr_head">{contentBenchmarkPortfolio[0].benchmarks[j].instrument_name}</div>,
                    "dataIndex": "instrument" + j
                })
            }

            var element = {}
            for (var i = 0; i < contentBenchmarkPortfolio.length; i++) {
                for (var k = 0; k < contentBenchmarkPortfolio[i].benchmarks.length; k++) {
                    element = {}
                    element[`instrument${k}`] = contentBenchmarkPortfolio[i].benchmarks[k].weight !== null ? contentBenchmarkPortfolio[i].benchmarks[k].weight : "0"
                    auxElem = Object.assign(auxElem, element)
                }

                auxData = {
                    "name": contentBenchmarkPortfolio[i].team_name,
                    "leverage": contentBenchmarkPortfolio[i].benchmarks.length === 0 ? "-" : contentBenchmarkPortfolio[i].benchmarks[0].leverage,
                    "benchmarkType": benchmarkTypePortfolioTable,
                    "maxLeverage": maxLeveragePortfolioTable
                }

                let row = Object.assign(auxData, auxElem)
                data.push(row)
            }
        }
        setDataState(data)
        setColumnsState(columns)
    }, [contentBenchmarkPortfolio])

    return (
        <Layout>
            <Content>
                <div className="benchmark_portfolio_report">
                    <Table className="benchmark_table" columns={columnsState} dataSource={dataState} pagination={false} scroll={{ x: 'fit-content' }} />
                </div>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    contentBenchmarkPortfolio: state.trainer.contentBenchmarkPortfolio,
    benchmarkTypePortfolioTable: state.trainer.benchmarkTypePortfolioTable,
    maxLeveragePortfolioTable: state.trainer.maxLeveragePortfolioTable
})

const mapDispatchToProps = dispatch => ({
    getBenchmarkPortfolioRequest: payload => dispatch(getBenchmarkPortfolioRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportBenchmarkPortfolio)