import React, { useState, useEffect } from 'react'
import '../App.scss'
import './Pages.scss'
import Layout, { Content } from 'antd/lib/layout/layout'
import SideBar from '../components/SideBar/SideBar'
import { Button, Table, Pagination } from 'antd'
import { FileSearchOutlined, StarFilled } from '@ant-design/icons'
import { Option } from 'antd/lib/mentions'
import SearchBar from '../components/SearchBar/SearchBar'
import ScenarioPreviewModal from '../components/ScenarioPreviewModal/ScenarioPreviewModal'
import { connect } from 'react-redux'
import { getScenariosRequest, setFavouriteScenarioRequest } from '../redux/trainer/trainer.actions'
import Spinner from '../components/Spinner/Spinner'

const TrainerScenarios = ({ getScenariosRequest, scenariosCount, scenariosResults, scenariosPageSize, scenariosNumberOfPages, setFavouriteScenarioRequest, isLoading }) => {

    // get data for table +  filters states
    const [currentPage, setCurrentPage] = useState(1)
    const [query, setQuery] = useState(null)
    const [periodType, setPeriodType] = useState(null)
    const [profile, setProfile] = useState(null)
    const [nrPeriods, setNrPeriods] = useState(null)
    const [idList, setIdList] = useState(null)
    const [change, setChange] = useState(false)

    const [changeFav, setChangeFav] = useState(false)

    var paramList = {
        "pageNumber": currentPage,
        "pageSize": scenariosPageSize,
        "query": query,
        "profile": profile,
        "period_type": periodType,
        "nr_periods": nrPeriods,
    }

    const [paramState, setParamState] = useState(paramList)
    const [instrState, setInstrState] = useState({})
    var finalInstrumentsList = {}
    var finalParamList = {}

    useEffect(() => {
        getScenariosRequest({ paramList: paramState })
    }, [currentPage, query, periodType, nrPeriods, profile, changeFav, change])

    // favourite
    const clickStar = (id) => {
        setFavouriteScenarioRequest(id)
        setTimeout(() => {
            setChangeFav(!changeFav)
        }, 500)
    }

    // open scenario preview modal
    const [idScenario, setIdScenario] = useState(null)
    const openScenarioPreview = (scenarioId) => {
        setIdScenario(scenarioId)
        setShowModal(true)
    }

    // table columns and data
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Profile",
            dataIndex: "profile",
            sorter: (a, b) => a.profile.localeCompare(b.profile),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Period",
            dataIndex: "period"
        },
        {
            title: "Instruments",
            dataIndex: "instruments"
        },
        {
            title: '',
            key: 'star',
            dataIndex: 'star',
            render: (text, record) => (
                <Button className="star_btn" onClick={() => clickStar(record.id)} icon={<StarFilled className={(record.fav === true) ? "fav_star" : "initial_star"} />} ></Button >
            ),
        },
        {
            title: '',
            key: 'key',
            dataIndex: 'key',
            render: (text, record) => (
                <Button className="preview_btn" onClick={() => openScenarioPreview(record.id)} icon={<FileSearchOutlined />}>
                    Preview
                </Button>
            ),
        }
    ];

    var data = []
    var profiles = []
    var periodTypes = []
    var noOfPeriods = []
    var instrumentsList = []

    if (scenariosResults && scenariosResults.content && scenariosResults.content.length > 0) {
        profiles = scenariosResults.distinct_profiles
        periodTypes = scenariosResults.distinct_period_types
        noOfPeriods = scenariosResults.distinct_nr_periods
        instrumentsList = scenariosResults.distinct_instruments
        data = []
        for (var i = 0; i < scenariosResults.content.length; i++) {
            data.push({
                "id": scenariosResults.content[i].id,
                "name": scenariosResults.content[i].scenario_name,
                "profile": scenariosResults.content[i].profile,
                "period": scenariosResults.content[i].period_type,
                "instruments": scenariosResults.content[i].instruments.map(i => { return <Button className="instrument_btn">{i.name}</Button> }),
                "fav": scenariosResults.content[i].is_favourite
            })
        }
    }

    // on change page
    const onChangePage = (page) => {
        paramList["pageNumber"] = page
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setCurrentPage(page)
    }

    // search
    const onSearch = (value) => {
        paramList["query"] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setQuery(value)
        setCurrentPage(1)
    }

    //profile select
    const profileList = []
    profiles.map((p) => {
        profileList.push(<Option key={p}>{p}</Option>)
        return profileList
    })

    const onChangeProfile = (value) => {
        paramList["profile"] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setProfile(value)
        setCurrentPage(1)
    }

    // period select
    const periodList = []
    periodTypes.map((p) => {
        periodList.push(<Option key={p}>{p}</Option>)
        return periodList
    })

    const onChangePeriod = (value) => {
        paramList["period_type"] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setPeriodType(value)
        setCurrentPage(1)
    }

    // number of periods select
    const noOfPeriodsList = []
    noOfPeriods.map((p) => {
        noOfPeriodsList.push(<Option key={p}>{p}</Option>)
        return noOfPeriodsList
    })

    const onChangeNoOfPeriods = (value) => {
        paramList['nr_periods'] = value
        paramList["pageNumber"] = 1
        finalParamList = Object.assign(paramList, instrState)
        setParamState(finalParamList)
        setNrPeriods(value)
        setCurrentPage(1)
    }

    // instruments select
    const selectList = []
    instrumentsList.map((i) => {
        selectList.push(<Option key={i.id}>{i.name}</Option>)
        return selectList
    })

    const handleChange = (value) => {
        setIdList(value)
    }

    useEffect(() => {
        if (idList) {
            idList.map((i, index) => {
                finalInstrumentsList["instrument_" + (index + 1)] = parseInt(i)
                return finalInstrumentsList
            })
            paramList["pageNumber"] = 1
            finalParamList = Object.assign(paramList, finalInstrumentsList)
            setInstrState(finalInstrumentsList)
            setParamState(finalParamList)
            setCurrentPage(1)
            setChange(!change)
        }
    }, [idList])

    // preview modal
    const [showModal, setShowModal] = useState(false)

    if (scenariosResults)
        return (
            <Layout>
                <ScenarioPreviewModal showModal={showModal} setShowModal={setShowModal} idScenario={idScenario} />
                <SideBar />
                <Content>
                    <div className="general_container trainer_scenarios">
                        <SearchBar selectList={selectList} visible={false} onSearch={onSearch} handleChange={handleChange} profileList={profileList} onChangeProfile={onChangeProfile} periodList={periodList} onChangePeriod={onChangePeriod} noOfPeriodsList={noOfPeriodsList} onChangeNoOfPeriods={onChangeNoOfPeriods} />
                        <Table columns={columns} dataSource={data} locale={{ emptyText: (isLoading ? <Spinner /> : <span>There are no scenarios matching your current search criteria. Please consider different keywords.</span>) }} pagination={false} scroll={{ x: 'fit-content' }} />
                        {
                            scenariosNumberOfPages > 1 &&
                            <Pagination
                                defaultPageSize={scenariosPageSize}
                                total={scenariosCount}
                                onChange={(page) => onChangePage(page)}
                                showSizeChanger={false}
                            />
                        }
                    </div>
                </Content>
            </Layout>
        )
    return null
}

const mapStateToProps = state => ({
    scenariosCount: state.trainer.scenariosCount,
    scenariosResults: state.trainer.scenariosResults,
    scenariosPageSize: state.trainer.scenariosPageSize,
    scenariosNumberOfPages: state.trainer.scenariosNumberOfPages,
    isLoading: state.trainer.isLoading
})

const mapDispatchToProps = dispatch => ({
    getScenariosRequest: payload => dispatch(getScenariosRequest(payload)),
    setFavouriteScenarioRequest: payload => dispatch(setFavouriteScenarioRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TrainerScenarios)