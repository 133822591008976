import React from 'react'
import './StageModal.scss'
import { Modal } from 'antd'

const StageModal = ({ setShowModal, showModal, componentsSwitch }) => {

    const handleCancel = () => {
        setShowModal(false)
    }

    return (
        <Modal
            title=""
            visible={showModal}
            onCancel={handleCancel}
            footer={null}
            className="stage_modal"
        >
            <div>
                {componentsSwitch}
            </div>
        </Modal>
    )
}

export default StageModal