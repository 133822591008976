import React, { useEffect } from 'react'
import './SideBarGameTeam.scss'
import Sider from 'antd/lib/layout/Sider'
import { Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { DashboardOutlined, BarsOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getSessionRequest } from '../../redux/trainer/trainer.actions'
import { setActiveMenuForTeam } from '../../redux/team/team.actions'

const SideBarGameTeam = ({ getSessionRequest, getSessionResponse, setActiveMenuForTeam, activeMenuItemForTeam, periodMenu, stageMenu }) => {

    // get session to populate sidebar 
    const idGame = localStorage.getItem("game_id")

    useEffect(() => {
        getSessionRequest(idGame)
    }, [idGame])

    // build submenu depending on the number of periods and elements from a period + cases from periods
    var noPfPeriods
    if (getSessionResponse)
        noPfPeriods = getSessionResponse.scenario.periods.length
    const period = ["Macro:Background", "Portfolios: Allocations", "Portfolios: Compare", "Macro: Review", "Instruments: Returns", "Portfolios: Values", "Portfolios: Performance"]
    const final = ["Daily values", "Daily returns", "Overall return", "Annual Performance", "Sharpe Ratio (compute)", "Sharpe Ratio (compare)"]

    var periods = []
    for (var i = 1; i <= noPfPeriods; i++) {
        if (periodMenu !== null && stageMenu !== null)
            periods.push(
                <SubMenu className={(i > periodMenu && periodMenu !== -1) ? "hide_item" : "show_submenu"} title={"Period " + i}>
                    {
                        period.map((p, index) => {
                            return <Menu.Item className={((periodMenu === i && index > stageMenu) || (i > periodMenu && periodMenu !== -1)) ? "hide_item" : "show_item submenu-item"} key={"period_" + i + index}>{p}</Menu.Item>
                        })
                    }
                </SubMenu>
            )
    }

    var finals = []
    if (periodMenu !== null && stageMenu !== null)
        finals.push(
            <SubMenu className={(periodMenu !== -1) ? "hide_item" : "show_submenu"} title="Final">
                {
                    final.map((f, index) => {
                        return <Menu.Item className={(periodMenu === -1 && index > stageMenu) ? "hide_item" : "show_item submenu-item"} key={"final_" + index}>{f}</Menu.Item>
                    })
                }
            </SubMenu>
        )

    return (
        <Sider className="sider_game_team">
            <Menu mode="inline" selectedKeys={activeMenuItemForTeam} onClick={(e) => setActiveMenuForTeam(e.key)}>
                <Menu.Item key={`/team/sessions`} icon={<DashboardOutlined />}>
                    <Link to={`/team/sessions`} className="nav-text">Dashboard</Link>
                </Menu.Item>
                <SubMenu title="Stages" icon={<BarsOutlined />} >
                    <SubMenu className={(activeMenuItemForTeam === "0" || activeMenuItemForTeam === "1" || activeMenuItemForTeam === "2") ? "selected_class" : "unselected_class"} title="Introduction">
                        <Menu.Item className="submenu-item show_item" key="0">Welcome</Menu.Item>
                        <Menu.Item className={(periodMenu === 0 && stageMenu === 0) ? "hide_item" : "show_item submenu-item"} key="1">Description</Menu.Item>
                        <Menu.Item className={(periodMenu === 0 && (stageMenu === 0 || stageMenu === 1)) ? "hide_item" : "show_item submenu-item"} key="2">Benchmark and leverage</Menu.Item>
                    </SubMenu>
                    {periods}
                    {finals}
                </SubMenu>
            </Menu>
        </Sider >
    )
}

const mapStateToProps = state => ({
    getSessionResponse: state.trainer.getSessionResponse,
    activeMenuItemForTeam: state.team.activeMenuItemForTeam
})

const mapDispatchToProps = dispatch => ({
    getSessionRequest: payload => dispatch(getSessionRequest(payload)),
    setActiveMenuForTeam: payload => dispatch(setActiveMenuForTeam(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBarGameTeam)