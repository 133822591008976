import React, { useEffect, useState } from 'react'
import './ScenarioPreviewModal.scss'
import { Modal, Menu, Button } from 'antd'
import Layout, { Content } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import SubMenu from 'antd/lib/menu/SubMenu'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getScenarioPreviewRequest } from '../../redux/trainer/trainer.actions'
import MacroBackground from '../stages/MacroBackground'
import PortfoliosValues from '../stages/preview/PortfoliosValues'
import PortfoliosAllocation from '../stages/PortfoliosAllocation'
import PortfoliosCompare from '../stages/preview/PortfoliosCompare'
import MacroReview from '../stages/MacroReview'
import InstrumentsReturns from '../stages/preview/InstrumentsReturn'
import PortfoliosPerformance from '../stages/preview/PortfoliosPerformance'
import BenchmarkAndLeverage from '../stages/BenchmarkAndLeverage'
import Description from '../stages/Description'

const ScenarioPreviewModal = ({ setShowModal, showModal, idScenario, getScenarioPreviewRequest, idScenarioPreview, nameScenarioPreview, descriptionScenarioPreview, profileScenarioPreview, periodTypeScenarioPreview, instrumentInformationScenarioPreview, periodsScenarioPreview }) => {

  // get scenario preview
  useEffect(() => {
    if (idScenario)
      getScenarioPreviewRequest(idScenario)
  }, [idScenario])

  // build submenu depending on the number of periods and elements from a period + cases from periods
  var noOfPeriods
  if (periodsScenarioPreview && periodsScenarioPreview.length > 0)
    noOfPeriods = periodsScenarioPreview.length
  const period = ["Macro:Background", "Portfolios: Allocations", "Portfolios: Compare", "Macro: Review", "Instruments: Returns", "Portfolios: Values", "Portfolios: Performance"]
  var periods = []
  for (var i = 1; i <= noOfPeriods; i++) {
    periods.push(
      <SubMenu title={"Period " + i}>
        {
          period.map((p, index) => {
            return <Menu.Item key={"period_" + i + index}>{p}</Menu.Item>
          })
        }
      </SubMenu>
    )
  }

  // show elements/pages by item key
  const [selectedMenuItem, setSelectedMenuItem] = useState('1')
  const componentsSwitch = (key) => {
    if (key === '1') {
      return <Description description={descriptionScenarioPreview} />
    } else if (key === '2') {
      return <BenchmarkAndLeverage instrumentInformation={instrumentInformationScenarioPreview} />
    } else if (key === 'final_0') {
      return <div>Portfolios: Daily values (line chart)</div>
    } else if (key === 'final_1') {
      return <div>Portfolios: Daily returns (line chart)</div>
    } else if (key === 'final_2') {
      return <div>Portfolios: Overall return (horizontal bar chart)</div>
    } else if (key === 'final_3') {
      return <div>Portfolios: Annual Performance (horizontal bar chart)</div>
    } else if (key === 'final_4') {
      return <div>Portfolios: Sharpe Ratio</div>
    }
    else {
      for (var i = 1; i <= noOfPeriods; i++) {
        for (var j = 0; j < period.length; j++) {
          switch (key) {
            case `period_${i}${j}`:
              switch (j) {
                case 0:
                  return <MacroBackground periodNumber={i} periods={periodsScenarioPreview} />
                case 1:
                  return <PortfoliosAllocation />
                case 2:
                  return <PortfoliosCompare />
                case 3:
                  return <MacroReview periodNumber={i} periods={periodsScenarioPreview} />
                case 4:
                  return <InstrumentsReturns />
                case 5:
                  return <PortfoliosValues />
                case 6:
                  return <PortfoliosPerformance />
                default:
                  break;
              }
              break;
            default:
              break;
          }
        }
      }
    }
  }

  // next prev buttons
  var keysMenu = ["1", "2"]
  for (var m = 1; m <= noOfPeriods; m++) {
    for (var j = 0; j < period.length; j++) {
      keysMenu.push(`period_${m}${j}`)
    }
  }
  // TO DO: final stages
  // keysMenu.push('final_0')
  // keysMenu.push('final_1')
  // keysMenu.push('final_2')
  // keysMenu.push('final_3')
  // keysMenu.push('final_4')

  const nextStage = () => {
    for (var i = 0; i < keysMenu.length; i++) {
      if (selectedMenuItem === keysMenu[i] && i < keysMenu.length - 1) {
        setSelectedMenuItem(keysMenu[i + 1])
      }
    }
  }

  const prevStage = () => {
    for (var i = 0; i < keysMenu.length; i++) {
      if (selectedMenuItem === keysMenu[i] && i >= 1) {
        setSelectedMenuItem(keysMenu[i - 1])
      }
    }
  }

  // modal
  const handleCancel = () => {
    setShowModal(false)
    setSelectedMenuItem('1')
  }

  return (
    <Modal
      destroyOnClose={true}
      title={[
        <div className="modal_title">
          <h2>Scenario preview</h2>
          <h2 className="scenario_name">
            {
              nameScenarioPreview && nameScenarioPreview
            }
          </h2>
        </div>
      ]}
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
      className="scenario_preview_modal"
    >
      <Layout>
        <Sider className="sider_preview">
          <div>
            <Button className="btn-control" onClick={() => prevStage()}>Prev <span className="left_span"><LeftOutlined /></span></Button>
            <Button className="btn-control" onClick={() => nextStage()}><span className="right_span"><RightOutlined /></span>Next</Button>
          </div>
          <Menu className="menu-div" selectedKeys={selectedMenuItem} mode="inline" onClick={(e) =>
            setSelectedMenuItem(e.key)}>
            <SubMenu className={(selectedMenuItem === "0" || selectedMenuItem === "1" || selectedMenuItem === "2") ? "selected_class" : "unselected_class"} title="Introduction">
              <Menu.Item key="1">Description</Menu.Item>
              <Menu.Item key="2">Benchmark and leverage</Menu.Item>
            </SubMenu>
            {periods}
            {/* TO DO: final stages */}
            {/* <SubMenu title="Final">
              <Menu.Item key="final_0">Portfolios: Daily values</Menu.Item>
              <Menu.Item key="final_1">Portfolios: Daily returns</Menu.Item>
              <Menu.Item key="final_2">Portfolios: Overall return</Menu.Item>
              <Menu.Item key="final_3">Portfolios: Annual Performance</Menu.Item>
              <Menu.Item key="final_4">Portfolios: Sharpe Ratio</Menu.Item>
            </SubMenu> */}
          </Menu>
        </Sider>
        <Content>
          <div className="mobile-controls">
            <Button className="btn-control" onClick={() => prevStage()}>Prev <span className="left_span"><LeftOutlined /></span></Button>
            <Button className="btn-control" onClick={() => nextStage()}><span className="right_span"><RightOutlined /></span>Next</Button>
          </div>
          {componentsSwitch(selectedMenuItem)}
        </Content>
      </Layout>
    </Modal >
  )
}

const mapStateToProps = (state) => ({
  idScenarioPreview: state.trainer.idScenarioPreview,
  nameScenarioPreview: state.trainer.nameScenarioPreview,
  descriptionScenarioPreview: state.trainer.descriptionScenarioPreview,
  profileScenarioPreview: state.trainer.profileScenarioPreview,
  periodTypeScenarioPreview: state.trainer.periodTypeScenarioPreview,
  instrumentInformationScenarioPreview: state.trainer.instrumentInformationScenarioPreview,
  periodsScenarioPreview: state.trainer.periodsScenarioPreview
})

const mapDispatchToProps = dispatch => ({
  getScenarioPreviewRequest: payload => dispatch(getScenarioPreviewRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioPreviewModal)