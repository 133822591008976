import React, { useEffect, useState } from 'react'
import '../../App.scss'
import './ScenarioStages.scss'

const MacroBackground = ({ periodNumber, periods }) => {
    const [description, setDescription] = useState(null)

    useEffect(() => {
        if (periods.length > 0)
            periods.map((p, index) => {
                if (periodNumber === (index + 1)) {
                    setDescription(p.macroeconomic_background.general_description)
                }
                return p
            })
    }, [periodNumber, periods])

    return (
        <div className="general_container stage_container">
            <h4 className="subtitle-stage">Macro Background</h4>
            {
                description &&
                <div>
                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>

            }
        </div>
    )
}

export default MacroBackground