import React, { useEffect, useState } from 'react'
import '../App.scss'
import './Pages.scss'
import { Button, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { getSessionRequest, nextStage, prevStage, setSelectedMenuItem, getInitialStatesReport } from '../redux/trainer/trainer.actions'
import Layout, { Content } from 'antd/lib/layout/layout'
import SideBarGame from '../components/SideBarGame/SideBarGame'
import MacroBackground from '../components/stages/MacroBackground'
import MacroReview from '../components/stages/MacroReview'
import Description from '../components/stages/Description'
import InstrumentsReturns from '../components/stages/InstrumentsReturn'
import PortfoliosPerformance from '../components/stages/PortfoliosPerformance'
import PortfoliosValues from '../components/stages/PortfoliosValues'
import PortfoliosCompare from '../components/stages/PortfoliosCompare'
import WelcomeScreen from '../components/stages/WelcomeScreen'
import StageModal from '../components/StageModal/StageModal'
import Final from '../components/stages/Final'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import DailyValues from '../components/finalStages/DailyValues'
import DailyReturns from '../components/finalStages/DailyReturns'
import OverallReturn from '../components/finalStages/OverallReturn'
import AnnualPerformance from '../components/finalStages/AnnualPerformance'
import SharpeRatioCompare from '../components/finalStages/SharpeRatioCompare'
import SharpeRatioCompute from '../components/finalStages/SharpeRatioCompute'
import { useStopwatch } from 'react-timer-hook'
import ReportAllocation from '../components/ReportAllocation/ReportAllocation'
import ReportBenchmarkPortfolio from '../components/ReportBenchmarkPortfolio/ReportBenchmarkPortfolio'

const ReportTrainer = ({ getSessionRequest, getSessionResponse, playingTime, nextStage, prevStage, selectedMenuItem, nextMenuItem, setSelectedMenuItem, nextMenuStage, currentMenuStage, getInitialStatesReport }) => {

    const idGame = localStorage.getItem("game_id")

    // get session
    useEffect(() => {
        if (idGame)
            getSessionRequest(idGame)
    }, [idGame])

    useEffect(() => {
        if (getSessionResponse) {
            getInitialStatesReport()
        }
    }, [getSessionResponse])

    const period = ["Macro:Background", "Portfolios: Allocations", "Portfolios: Compare", "Macro: Review", "Instruments: Returns", "Portfolios: Values", "Portfolios: Performance"]
    var noOfPeriods = null
    var periodsScenario = []
    var instrumentsScenario = []
    var description = null
    if (getSessionResponse) {
        periodsScenario = getSessionResponse.scenario.periods
        instrumentsScenario = getSessionResponse.scenario.instrument_information
        noOfPeriods = getSessionResponse.scenario.periods.length
        description = getSessionResponse.scenario.description
    }

    // show elements/pages by item key
    const componentsSwitch = (key) => {
        if (key === '0') {
            return <WelcomeScreen />
        } else if (key === '1') {
            return <Description description={description} />
        } else if (key === '2') {
            return <ReportBenchmarkPortfolio />
        } else if (key === 'final_0') {
            return <DailyValues />
        } else if (key === 'final_1') {
            return <DailyReturns />
        } else if (key === 'final_2') {
            return <OverallReturn />
        } else if (key === 'final_3') {
            return <AnnualPerformance />
        } else if (key === 'final_4') {
            return <SharpeRatioCompute />
        } else if (key === 'final_5') {
            return <SharpeRatioCompare />
        } else {
            for (var i = 1; i <= noOfPeriods; i++) {
                for (var j = 0; j < period.length; j++) {
                    switch (key) {
                        case `0`:
                            return <WelcomeScreen />
                        case `period_${i}${j}`:
                            switch (j) {
                                case 0:
                                    return <MacroBackground periodNumber={i} periods={periodsScenario} />
                                case 1:
                                    return <ReportAllocation currentPeriod={i} />
                                case 2:
                                    return <PortfoliosCompare periodNumber={i} />
                                case 3:
                                    return <MacroReview periodNumber={i} periods={periodsScenario} />
                                case 4:
                                    return <InstrumentsReturns periodNumber={i} />
                                case 5:
                                    return <PortfoliosValues periodNumber={i} />
                                case 6:
                                    return <PortfoliosPerformance periodNumber={i} />
                                default:
                                    break;
                            }
                            break;
                        case `empty`:
                            return <Final />
                        default:
                            break;
                    }
                }
            }
        }
    }

    // view stage modal
    const [showModal, setShowModal] = useState(false)
    const [viewItem, setViewItem] = useState(selectedMenuItem)

    const {
        seconds,
        minutes,
        hours,
        reset
    } = useStopwatch({ autoStart: false })

    const stopwatchOffset = new Date()
    useEffect(() => {
        if (playingTime !== null) {
            var offset = stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + playingTime)
            reset(offset, false)
        }
    }, [playingTime, getSessionResponse])


    return (
        <Layout>
            <StageModal showModal={showModal} setShowModal={setShowModal} componentsSwitch={componentsSwitch(viewItem)} />
            <SideBarGame selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem} />
            <Content>
                <div className="general_container report_trainer_container">
                    <Row>
                        <Col span={24} className="first_col_style">
                            <Row className="row_class">
                                <h5 className="subtitle_game">Game</h5>
                                <Row className="title_row">
                                    <Col className="title" span={2} sm={4} xs={5}>Status</Col>
                                    <Col className="title" span={2} sm={3} xs={5}>Time</Col>
                                </Row>
                                <Row>
                                    <Col className="session_status" span={2} sm={4} xs={5}>Completed</Col>
                                    <Col span={2} sm={3} xs={5}>
                                        <div>
                                            <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>

                            <Row className="row_class_game">
                                <Col className="col_game_stage" span={12} sm={12} xs={24}>
                                    <h5 className="subtitle_game">Currently playing</h5>
                                    <h5 className="game_stage">{currentMenuStage}</h5>
                                    <div className="page_class" onClick={() => {
                                        setShowModal(true)
                                        setViewItem(selectedMenuItem)
                                    }
                                    }>
                                        {componentsSwitch(selectedMenuItem)}
                                    </div>
                                </Col>
                                <Col className="col_game_stage" span={12} sm={12} xs={24}>
                                    <h5 className="subtitle_game">Play next</h5>
                                    <h5 className="game_stage">{nextMenuStage}</h5>
                                    <div className={selectedMenuItem === "empty" ? "hide_class" : "page_class"} onClick={() => {
                                        setShowModal(true)
                                        setViewItem(nextMenuItem)
                                    }
                                    }>
                                        {componentsSwitch(nextMenuItem)}
                                    </div>
                                </Col>
                                <Row className="bottom_row">
                                    <Button disabled={selectedMenuItem === "0" ? true : false} className="btn" onClick={() => prevStage()} icon={<ArrowLeftOutlined />}></Button>
                                    <Button disabled={selectedMenuItem === "empty" ? true : false} className="btn" onClick={() => nextStage()} icon={<ArrowRightOutlined />}></Button>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout >
    )
}

const mapStateToProps = state => ({
    getSessionResponse: state.trainer.getSessionResponse,
    playingTime: state.trainer.playingTime,
    selectedMenuItem: state.trainer.selectedMenuItem,
    nextMenuItem: state.trainer.nextMenuItem,
    currentMenuStage: state.trainer.currentMenuStage,
    nextMenuStage: state.trainer.nextMenuStage
})

const mapDispatchToProps = dispatch => ({
    getSessionRequest: payload => dispatch(getSessionRequest(payload)),
    nextStage: () => dispatch(nextStage()),
    prevStage: () => dispatch(prevStage()),
    setSelectedMenuItem: payload => dispatch(setSelectedMenuItem(payload)),
    getInitialStatesReport: payload => dispatch(getInitialStatesReport(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportTrainer)